import { Button } from '@components/Button'
import { PaymentProduct } from '@services/payment_product'
import { useTranslation } from 'i18n'
import React, { ReactElement, useState } from 'react'
import PriceBox from './PriceBox'

const PriceSelectionVariantC: React.FC<{
    recommendedPlan: PaymentProduct
    isPromoCodeEnabled: boolean
    priceVariant?: string
    onChangeSelectedPlan?: (plan: number) => void
    selectedPlan?: number
    tierBelowPlan: PaymentProduct
    tierAbovePlan: PaymentProduct
    currency: string
    savePercentage: number
    subscriptionWithoutTrial: boolean
}> = ({
    recommendedPlan,
    isPromoCodeEnabled,
    priceVariant,
    onChangeSelectedPlan,
    selectedPlan,
    tierBelowPlan,
    tierAbovePlan,
    currency,
    savePercentage,
    subscriptionWithoutTrial,
}): ReactElement => {
    const { t } = useTranslation()
    const [showPriceOptions, setShowPriceOptions] = useState(false)

    const expandPriceOptions = () => setShowPriceOptions(true)

    return (
        <div className="flex flex-col my-8">
            <PriceBox
                priceTier="recommended"
                product={recommendedPlan}
                currency={currency}
                selectedPlan={selectedPlan}
                onChangeSelectedPlan={() => onChangeSelectedPlan(recommendedPlan.productIntervalCount)}
                priceVariant={priceVariant}
                subscriptionWithoutTrial={subscriptionWithoutTrial}
            />
            {isPromoCodeEnabled && (
                <span className="text-dark3">
                    {t('survey:program.variant-a.plan.promo_code')}{' '}
                    <button className="cursor-pointer text-boost2 font-bold">
                        {t('survey:program.variant-a.plan.click_here')}
                    </button>
                </span>
            )}

            {!showPriceOptions ? (
                <div className="w-full flex justify-center mt-12">
                    <button
                        className="flex items-center text-glide2 font-extrabold text-center"
                        onClick={expandPriceOptions}
                    >
                        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M12.7102 1.28994C12.6172 1.19621 12.5066 1.12182 12.3848 1.07105C12.2629 1.02028 12.1322 0.994141 12.0002 0.994141C11.8682 0.994141 11.7375 1.02028 11.6156 1.07105C11.4937 1.12182 11.3831 1.19621 11.2902 1.28994L7.00019 5.58994L2.71019 1.28994C2.52188 1.10164 2.26649 0.995848 2.00019 0.995848C1.73388 0.995848 1.47849 1.10164 1.29019 1.28994C1.10188 1.47824 0.996094 1.73364 0.996094 1.99994C0.996094 2.26624 1.10188 2.52164 1.29019 2.70994L6.29019 7.70994C6.38315 7.80367 6.49375 7.87806 6.61561 7.92883C6.73747 7.9796 6.86817 8.00574 7.00019 8.00574C7.1322 8.00574 7.2629 7.9796 7.38476 7.92883C7.50662 7.87806 7.61722 7.80367 7.71019 7.70994L12.7102 2.70994C12.8039 2.61698 12.8783 2.50638 12.9291 2.38452C12.9798 2.26266 13.006 2.13195 13.006 1.99994C13.006 1.86793 12.9798 1.73722 12.9291 1.61536C12.8783 1.4935 12.8039 1.3829 12.7102 1.28994Z"
                                fill="#33CC99"
                            />
                        </svg>
                        <span className="ml-3">{t('survey:program.variant-a.plan.other_plan_options')}</span>
                    </button>
                </div>
            ) : (
                <>
                    <div className="w-full flex justify-center mt-8">
                        <span className="uppercase text-xs text-dark4 font-semibold">
                            {t('survey:program.variant-a.plan.other_plan_options')}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-4 mt-6">
                        {tierBelowPlan && (
                            <PriceBox
                                priceTier="below"
                                product={tierBelowPlan}
                                currency={currency}
                                selectedPlan={selectedPlan}
                                onChangeSelectedPlan={() => onChangeSelectedPlan(tierBelowPlan.productIntervalCount)}
                                priceVariant={priceVariant}
                                subscriptionWithoutTrial={subscriptionWithoutTrial}
                            />
                        )}
                        {tierAbovePlan && (
                            <PriceBox
                                priceTier="above"
                                savePercentage={savePercentage}
                                product={tierAbovePlan}
                                currency={currency}
                                selectedPlan={selectedPlan}
                                onChangeSelectedPlan={() => onChangeSelectedPlan(tierAbovePlan.productIntervalCount)}
                                priceVariant={priceVariant}
                                subscriptionWithoutTrial={subscriptionWithoutTrial}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default PriceSelectionVariantC
