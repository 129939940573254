import classNames from 'classnames'
import React from 'react'
import { ReactElement } from 'react'
import { CheckIcon } from './CheckIcon'

const CheckCircle: React.FC<{ checked?: boolean }> = ({ checked = false }): ReactElement => {
    const checkClassName = classNames(
        'rounded-full flex justify-center items-center font-bold text-xs p-0.5 text-white transition-colors duration-200 w-5 md:w-7 h-5 md:h-7',
        checked ? 'bg-boost2' : 'border-2 border-dark4',
    )
    return <div className={checkClassName}>{checked && <CheckIcon className="w-5 md:w-7 h-5 md:h-7" />}</div>
}

export default CheckCircle
