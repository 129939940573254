import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'i18n'
import { useQuestion, useSurvey } from '@contexts/survey'
import { Step } from '@components/Step'
import assets from './assets'
import { Clock, Eat, Stopwatch, Water, Steps, Journal } from '@components/Icons/YourPlanIcons'
import { useUser } from '@contexts/auth'

const YourPlanStep: React.FC = (): ReactElement => {
    const [question] = useQuestion()
    const { t } = useTranslation()
    const user = useUser()

    return (
        <Step key={question.path} name={question.name}>
            <div>
                <div className="flex flex-col border rounded-xl border-dark5 mb-3">
                    <div className="flex items-center py-6 px-5 border-b border-dark5">
                        <div className="w-8 flex items-center justify-center">
                            <Stopwatch />
                        </div>
                        <div className="flex flex-col ml-5">
                            <span className="mb-1 font-bold text-dark1">{t('survey:your_plan.list.1.title')}</span>
                            <span className="text-dark3">{t('survey:your_plan.list.1.description')}</span>
                        </div>
                    </div>
                    <div className="flex items-center py-6 px-5 border-b border-dark5">
                        <div className="w-8 flex items-center justify-center">
                            <Clock />
                        </div>
                        <div className="flex flex-col ml-5">
                            <span className="mb-1 font-bold text-dark1">{t('survey:your_plan.list.2.title')}</span>
                            <span className="text-dark3">{t('survey:your_plan.list.2.description')}</span>
                        </div>
                    </div>
                    <div className="flex items-center py-6 px-5 border-b border-dark5">
                        <div className="w-8 flex items-center justify-center">
                            <Eat />
                        </div>
                        <div className="flex flex-col ml-5">
                            <span className="mb-1 font-bold text-dark1">{t('survey:your_plan.list.3.title')}</span>
                            <span className="text-dark3">{t('survey:your_plan.list.3.description')}</span>
                        </div>
                    </div>
                    <div className="flex items-center py-6 px-5 border-b border-dark5">
                        <div className="w-8 flex items-center justify-center">
                            <Water />
                        </div>
                        <div className="flex flex-col ml-5">
                            <span className="mb-1 font-bold text-dark1">{t('survey:your_plan.list.4.title')}</span>
                            <span className="text-dark3">{t('survey:your_plan.list.4.description')}</span>
                        </div>
                    </div>
                    <div className="flex items-center py-6 px-5">
                        <div className="w-8 flex items-center justify-center">
                            <Steps />
                        </div>
                        <div className="flex flex-col ml-5">
                            <span className="mb-1 font-bold text-dark1">{t('survey:your_plan.list.5.title')}</span>
                            <span className="text-dark3">{t('survey:your_plan.list.5.description')}</span>
                        </div>
                    </div>
                </div>
                <span className="text-xs text-dark4 text-center flex justify-center">
                    {t('survey:your_plan.list_notice')}
                </span>
                <div className="my-10 sm:my-16 flex flex-col">
                    <div className="mb-6 flex flex-col items-center justify-center">
                        <h2 className="font-extrabold text-2xl text-dark1 text-center">
                            {t('survey:your_plan.what_to_expect.title')}
                        </h2>
                        <span className="text-dark3 mt-2 text-center">
                            {t('survey:your_plan.what_to_expect.subtitle')}
                        </span>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex mb-2">
                            <span className="w-1/2 text-dark1 font-bold">
                                {t('survey:your_plan.what_to_expect.list.1.title')}
                            </span>
                            <span className="w-1/2 text-dark1 font-bold">
                                {t('survey:your_plan.what_to_expect.list.2.title')}
                            </span>
                        </div>
                        <div className="flex items-center">
                            <div className="h-4 w-4 border-4 border-boost2 rounded-full ml-4" />
                            <div className="h-1 w-1/2 bg-boost4 opacity-30" />
                            <div className="h-4 w-4 border-4 border-boost2 rounded-full" />
                            <div className="h-1 w-1/2 bg-boost4 opacity-30" />
                        </div>
                        <div className="flex my-6">
                            <p className="shadow-standard p-4 text-dark3 rounded-xl mr-4">
                                {t('survey:your_plan.what_to_expect.list.1.description')}
                            </p>
                            <p className="shadow-standard p-4 text-dark3 rounded-xl">
                                {t('survey:your_plan.what_to_expect.list.2.description')}
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex mb-2">
                            <span className="w-1/2 text-dark1 font-bold">
                                {t('survey:your_plan.what_to_expect.list.3.title')}
                            </span>
                            <span className="w-1/2 text-dark1 font-bold">
                                {t('survey:your_plan.what_to_expect.list.4.title')}
                            </span>
                        </div>
                        <div className="flex items-center">
                            <div className="h-1 w-4 bg-boost4 opacity-30" />
                            <div className="h-4 w-4 border-4 border-boost2 rounded-full -mr-4 bg-white z-10" />
                            <div className="h-1 w-1/2 bg-boost4 opacity-30" />
                            <div className="h-4 w-4 border-4 border-boost2 rounded-full" />
                            <div className="h-1 w-1/2 bg-boost4 opacity-30" />
                        </div>
                        <div className="flex my-6">
                            <p className="shadow-standard p-4 text-dark3 rounded-xl mr-4">
                                {t('survey:your_plan.what_to_expect.list.3.description')}
                            </p>
                            <p className="shadow-standard p-4 text-dark3 rounded-xl">
                                {t('survey:your_plan.what_to_expect.list.4.description')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col mb-8 sm:mb-10">
                    <div className="mb-6 flex flex-col items-center justify-center">
                        <h2 className="font-extrabold text-2xl text-dark1 text-center">
                            {t('survey:your_plan.what_is_included.title')}
                        </h2>
                        <span className="text-dark3 mt-2 text-center">
                            {t('survey:your_plan.what_is_included.subtitle')}
                        </span>
                    </div>
                    <div className="flex flex-col space-y-4">
                        <div className="flex space-x-4">
                            <div className="flex flex-col">
                                <img src={assets.your_plan.eat} className="rounded-t-xl" />
                                <div className="shadow-standard rounded-b-xl min-h-72 xs:min-h-0">
                                    <span className="flex items-center justify-center p-4 font-bold text-sm text-center text-dark1">
                                        {t('survey:your_plan.what_is_included.list.1')}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <img src={assets.your_plan.sleep} className="rounded-t-xl" />
                                <div className="shadow-standard rounded-b-xl min-h-72 xs:min-h-0">
                                    <span className="flex items-center justify-center p-4 font-bold text-sm text-center text-dark1">
                                        {t('survey:your_plan.what_is_included.list.2')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-4">
                            <div className="flex flex-col">
                                <img src={assets.your_plan.exercise} className="rounded-t-xl" />
                                <div className="shadow-standard rounded-b-xl min-h-72 xs:min-h-0">
                                    <span className="flex items-center justify-center p-4 font-bold text-sm text-center text-dark1">
                                        {t('survey:your_plan.what_is_included.list.3')}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <img src={assets.your_plan.community} className="rounded-t-xl" />
                                <div className="shadow-standard rounded-b-xl min-h-72 xs:min-h-0">
                                    <span className="flex items-center justify-center p-4 font-bold text-sm text-center text-dark1">
                                        {t('survey:your_plan.what_is_included.list.4')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <a className="flex items-center justify-center" href="https://fastic.com/intermittent-fasting-studies/">
                    <Journal />
                    <span className="ml-2 text-boost2 font-bold text-center">{t('survey:your_plan.source')}</span>
                </a>
            </div>
        </Step>
    )
}

export default YourPlanStep
