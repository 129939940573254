import { useUser } from '@contexts/auth'
import { PaymentProduct } from '@services/payment_product'
import React from 'react'
import PriceBreakdownControl from './Control'
import PriceBreakdownVariantA from './VariantA'
import { PromoProps } from '@pages/gift'

const PriceBreakdown: React.FC<{
    recommendedPlan: PaymentProduct
    isPromoCodeEnabled?: boolean
    weightToLoose?: number
    onChangeSelectedPlan?: (plan: number) => void
    selectedPlan?: number
    subscriptionWithoutTrial?: boolean
    promo?: PromoProps
}> = ({
    recommendedPlan,
    isPromoCodeEnabled,
    weightToLoose,
    onChangeSelectedPlan,
    selectedPlan,
    subscriptionWithoutTrial,
    promo,
}) => {
    const user = useUser()
    const posUITestVariant = user.amplitudeVariants['pos-ui-optimisation']?.value
    const posPriceTestVariant = user.amplitudeVariants['pos-price-selection']?.value

    if (posUITestVariant === 'control' || posUITestVariant === undefined) {
        return (
            <PriceBreakdownControl
                recommendedPlan={recommendedPlan}
                subscriptionWithoutTrial={subscriptionWithoutTrial}
                promo={promo}
            />
        )
    } else {
        return (
            <PriceBreakdownVariantA
                recommendedPlan={recommendedPlan}
                isPromoCodeEnabled={isPromoCodeEnabled}
                priceVariant={posPriceTestVariant}
                weightToLoose={weightToLoose}
                onChangeSelectedPlan={onChangeSelectedPlan}
                selectedPlan={selectedPlan}
                subscriptionWithoutTrial={subscriptionWithoutTrial}
            />
        )
    }
}

export default PriceBreakdown
