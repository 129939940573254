import React from 'react'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { FakeProgressBar } from '../components/ProgressBar'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'

export const ProcessingStep: React.FC = () => {
    const [question, , dispatch] = useQuestion()
    const { t } = useTranslation()

    return (
        <Step key={question.path} name={question.name}>
            <div className="mb-24">
                <div className="flex items-center justify-center">
                    <span>{t('survey:processing.loader')}</span>
                    <div className="ml-3">
                        <LoadingSpinner size="sm" />
                    </div>
                </div>
                <FakeProgressBar duration={5} onEnd={() => dispatch({ type: 'QUESTION_COMPLETED' })} />
            </div>
            <Chart />
        </Step>
    )
}

const Chart: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div data-v-4f03dcdc className="w-full text-center">
            <svg
                className="w-full max-w-md mx-auto"
                width="480"
                height="281"
                viewBox="0 0 480 281"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0)">
                    <path d="M210 109L210 27" stroke="#F0F0F0" />
                    <path
                        d="M456 280C469.255 280 480 269.255 480 256V113.197C435.617 113.197 382.332 112.535 328.56 117.665C284.77 121.843 250.162 135.071 205.634 113.198C123.682 82.7411 89.5 8.5 0 2V256C0 269.255 10.7452 280 24 280H456Z"
                        fill="#EBFAF5"
                    />
                    <path opacity="0.2" d="M319 112V192M428.02 112V192" stroke="#29AB87" />
                    <g data-v-4f03dcdc>
                        <text
                            data-v-4f03dcdc
                            x={210}
                            y={15}
                            textAnchor="middle"
                            fill="#516686"
                            className="text-base font-bold"
                        >
                            {t('survey:processing.target_weight')}
                        </text>
                    </g>
                    <g data-v-4f03dcdc>
                        <text
                            data-v-4f03dcdc
                            x={375}
                            y={220}
                            textAnchor="middle"
                            fill="#29AB87"
                            className="text-base font-bold"
                        >
                            {t('survey:processing.fastic_users')}
                        </text>
                    </g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M210 115.246C208.555 114.6 207.1 113.918 205.634 113.198C171.007 100.329 144.908 79.6432 119.562 59.5535C84.9203 32.0969 51.6836 5.75356 0 2V256C0 269.255 10.7452 280 24 280H210V115.246Z"
                        fill="#FFF3F1"
                    />
                    <path d="M2.44385 112.681H473.765" stroke="#FA8072" strokeLinecap="round" strokeDasharray="4 9.5" />
                    <path
                        d="M-8.12207 2.74121C80.0525 2.74121 105.879 62.2442 185.511 104.721C240.401 134.001 282.805 121.877 328.364 117.681C382.821 112.664 438.98 112.011 488.122 112.011"
                        stroke="url(#paint0_linear)"
                        strokeWidth="5"
                        strokeLinecap="round"
                    />
                    <path
                        d="M135.961 80.5206C140.627 80.5206 144.409 76.745 144.409 72.0877C144.409 67.4303 140.627 63.6548 135.961 63.6548C131.295 63.6548 127.513 67.4303 127.513 72.0877C127.513 76.745 131.295 80.5206 135.961 80.5206Z"
                        fill="#FA8072"
                    />
                    <path
                        d="M52.3062 22.8555C56.9721 22.8555 60.7546 19.08 60.7546 14.4226C60.7546 9.76528 56.9721 5.98975 52.3062 5.98975C47.6404 5.98975 43.8579 9.76528 43.8579 14.4226C43.8579 19.08 47.6404 22.8555 52.3062 22.8555Z"
                        fill="#FA8072"
                    />
                    <path
                        opacity="0.2"
                        d="M209.543 136.315C221.205 136.315 230.66 126.861 230.66 115.198C230.66 103.536 221.205 94.0813 209.543 94.0813C197.881 94.0813 188.426 103.536 188.426 115.198C188.426 126.861 197.881 136.315 209.543 136.315Z"
                        fill="#FA8072"
                    />
                    <path
                        d="M209.87 123.13C214.536 123.13 218.318 119.354 218.318 114.697C218.318 110.039 214.536 106.264 209.87 106.264C205.204 106.264 201.421 110.039 201.421 114.697C201.421 119.354 205.204 123.13 209.87 123.13Z"
                        fill="#FA8072"
                    />
                    <path
                        opacity="0.2"
                        d="M318.376 139.315C330.038 139.315 339.492 129.861 339.492 118.198C339.492 106.536 330.038 97.0813 318.376 97.0813C306.713 97.0813 297.259 106.536 297.259 118.198C297.259 129.861 306.713 139.315 318.376 139.315Z"
                        fill="#059669"
                    />
                    <path
                        d="M318.702 126.13C323.368 126.13 327.151 122.354 327.151 117.697C327.151 113.039 323.368 109.264 318.702 109.264C314.036 109.264 310.254 113.039 310.254 117.697C310.254 122.354 314.036 126.13 318.702 126.13Z"
                        fill="#33CC99"
                    />
                    <path
                        opacity="0.2"
                        d="M427.208 134.315C438.871 134.315 448.325 124.861 448.325 113.198C448.325 101.536 438.871 92.0813 427.208 92.0813C415.546 92.0813 406.091 101.536 406.091 113.198C406.091 124.861 415.546 134.315 427.208 134.315Z"
                        fill="#059669"
                    />
                    <path
                        d="M427.535 121.13C432.201 121.13 435.983 117.354 435.983 112.697C435.983 108.039 432.201 104.264 427.535 104.264C422.869 104.264 419.086 108.039 419.086 112.697C419.086 117.354 422.869 121.13 427.535 121.13Z"
                        fill="#33CC99"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear"
                        x1="102.634"
                        y1="45.9981"
                        x2="346.929"
                        y2="156.537"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FA8072" />
                        <stop offset="1" stopColor="#33CC99" />
                    </linearGradient>
                    <clipPath id="clip0">
                        <rect width="480" height="281" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    )
}
