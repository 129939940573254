/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import Marquee from 'react-fast-marquee'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'

const experts = [
    {
        key: 'sarah_wander',
        imageUrl: assets.developed_by.sarah_wander,
        imageSize: 88,
        positionDown: 40,
    },
    {
        key: 'reiner_wettcke',
        imageUrl: assets.developed_by.reiner_wettcke,
        imageSize: 100,
        positionDown: 5,
    },
    {
        key: 'ilona_bürgel',
        imageUrl: assets.developed_by.ilona_bürgel,
        imageSize: 88,
        positionDown: 30,
    },
    {
        key: 'alice_fontecilla',
        imageUrl: assets.developed_by.alice_fontecilla,
        imageSize: 88,
        positionDown: 55,
    },
    {
        key: 'conny_wettcke',
        imageUrl: assets.developed_by.conny_wettcke,
        imageSize: 88,
        positionDown: 70,
    },
    {
        key: 'mario_ost',
        imageUrl: assets.developed_by.mario_ost,
        imageSize: 112,
        positionDown: 0,
    },
    {
        key: 'janet_brill',
        imageUrl: assets.developed_by.janet_brill,
        imageSize: 88,
        positionDown: 50,
    },
]

export const DevelopedByStep: React.FC = () => {
    const [question] = useQuestion()
    const { t } = useTranslation()

    const getExpertContainerSizeByImageSize = imageSize => {
        switch (imageSize) {
            case 88:
                return { height: 178, width: 128 }
            case 100:
                return { height: 189, width: 136 }
            case 112:
                return { height: 200, width: 144 }
        }
    }

    return (
        <Step key={question.path} name={question.name} title={<div />}>
            <h2 className="text-center text-base xs:text-lg md:text-2xl leading-tight font-bold text-gray-800 mb-2 md:mb-4 px-2 xs:px-4">
                {t('survey:developed_by.title')}
            </h2>
            <div className="flex flex-row h-auto space-y-4 mt-6 xs:mt-10 xs:mb-4 w-auto overflow-hidden relative">
                <Marquee gradient={false} speed={50}>
                    <div className="flex flex-row pb-4 pr-5 space-x-5 h-auto animate-bannermove">
                        {experts.map(expert => (
                            <div
                                key={expert.key}
                                className="flex flex-col justify-center items-center text-center space-y-1.5 py-4 px-2 rounded-2xl shadow-standard w-32 h-44"
                                style={{
                                    width: getExpertContainerSizeByImageSize(expert.imageSize).width,
                                    height: getExpertContainerSizeByImageSize(expert.imageSize).height,
                                    marginTop: expert.positionDown,
                                }}
                            >
                                <img
                                    width={expert.imageSize}
                                    height={expert.imageSize}
                                    className="rounded-full"
                                    src={expert.imageUrl}
                                    alt={t(`survey:developed_by.developers.${expert.key}.name`)}
                                />
                                <span className="text-dark1 font-extrabold text-sm">
                                    {t(`survey:developed_by.developers.${expert.key}.name`)}
                                </span>
                                <span className="text-dark3 text-xs md:text-sm">
                                    {t(`survey:developed_by.developers.${expert.key}.role`)}
                                </span>
                            </div>
                        ))}
                    </div>
                </Marquee>
            </div>
            <div className="text-sm sm:text-base text-dark3 flex flex-col space-y-4">
                <p dangerouslySetInnerHTML={{ __html: t('survey:developed_by.experts_description') }} />
                <p dangerouslySetInnerHTML={{ __html: t('survey:developed_by.health_journey') }} />
            </div>
        </Step>
    )
}
