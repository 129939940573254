import Image from 'next/image'
import React from 'react'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'

export const StatsStep: React.FC = () => {
    const [, answers] = useQuestion()
    const { t } = useTranslation()
    const decade = answers['age'][0].split('-')[0]
    return (
        <div className="max-w-lg mx-auto px-4">
            <h2 className="text-center text-base xs:text-lg md:text-2xl leading-tight font-bold text-dark1 mb-2 md:mb-4 px-2 xs:px-4">
                {t('survey:stats.title')}
            </h2>
            <div className="my-10">
                <Image src={assets.story_3} alt="person showing a thumbs up" width={480} height={360} priority />
            </div>
            <h2 className="text-center text-base xs:text-lg md:text-2xl leading-tight font-bold text-dark1 mb-1 md:mb-4 px-2 xs:px-4 pt-4">
                {decade === '18'
                    ? t('survey:stats.subtitle_18', { context: answers['gender'][0], age: decade })
                    : t('survey:stats.subtitle', { context: answers['gender'][0], age: decade })}
            </h2>
        </div>
    )
}
