import { Button } from '@components/Button'
import { CheckIcon } from '@components/CheckIcon'
import Tooltip from '@components/Tooltip'
import { PaymentProduct } from '@services/payment_product'
import { useTranslation } from 'i18n'
import React, { ReactElement } from 'react'
import PriceBox from './PriceBox'

const PriceSelectionVariantB: React.FC<{
    recommendedPlan: PaymentProduct
    isPromoCodeEnabled: boolean
    priceVariant?: string
    onChangeSelectedPlan?: (plan: number) => void
    selectedPlan?: number
    tierBelowPlan: PaymentProduct
    tierAbovePlan: PaymentProduct
    currency: string
    savePercentage: number
    subscriptionWithoutTrial: boolean
}> = ({
    recommendedPlan,
    isPromoCodeEnabled,
    priceVariant,
    onChangeSelectedPlan,
    selectedPlan,
    tierBelowPlan,
    tierAbovePlan,
    currency,
    savePercentage,
    subscriptionWithoutTrial,
}): ReactElement => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col mb-8">
            <div>
                <h2 className="font-bold text-2xl text-center text-dark1">
                    {t('survey:program.variant-a.plan.commitment_to_yourself')}
                </h2>
            </div>
            <div className="flex flex-col mt-8 space-y-4">
                {!subscriptionWithoutTrial && (
                    <>
                        <div className="flex items-center justify-between p-6 bg-dark5-light rounded-lg">
                            <span className="text-dark1 text-lg font-bold">
                                {t('survey:program.variant-a.plan.trial', { days: recommendedPlan.trialPeriodDays })}
                            </span>
                            <div className="flex  items-center justify-between">
                                <div className="flex flex-col mr-4 items-end">
                                    <span className="text-dark1 text-lg font-bold">
                                        {currency}
                                        {recommendedPlan.trialPrice.toFixed(2)}
                                    </span>
                                    <span className="text-dark3">{t('survey:program.variant-a.plan.total_today')}</span>
                                </div>
                                <div className="w-6 h-6 rounded-full flex justify-center items-center text-white font-bold bg-dark5">
                                    {selectedPlan === recommendedPlan.productIntervalCount && (
                                        <CheckIcon className="w-5 h-5" />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="flex items-center justify-center">
                            <span className="uppercase font-semibold text-dark4 text-xs mr-2">
                                {t('survey:program.variant-a.plan.combine')}
                            </span>
                            <Tooltip text={t('survey:program.variant-a.plan.charged_at')}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.00017 0.333496C5.68162 0.333496 4.39269 0.724489 3.29636 1.45703C2.20004 2.18957 1.34555 3.23077 0.840969 4.44894C0.336385 5.66711 0.204362 7.00756 0.461597 8.30077C0.718832 9.59397 1.35377 10.7819 2.28612 11.7142C3.21847 12.6466 4.40636 13.2815 5.69956 13.5387C6.99277 13.796 8.33321 13.6639 9.55139 13.1594C10.7696 12.6548 11.8108 11.8003 12.5433 10.704C13.2758 9.60764 13.6668 8.31871 13.6668 7.00016C13.6668 6.12468 13.4944 5.25778 13.1594 4.44894C12.8243 3.6401 12.3333 2.90517 11.7142 2.28612C11.0952 1.66706 10.3602 1.176 9.55139 0.840966C8.74255 0.505935 7.87564 0.333496 7.00017 0.333496ZM7.66683 9.66683C7.66683 9.84364 7.59659 10.0132 7.47157 10.1382C7.34655 10.2633 7.17698 10.3335 7.00017 10.3335C6.82335 10.3335 6.65379 10.2633 6.52876 10.1382C6.40374 10.0132 6.3335 9.84364 6.3335 9.66683V6.66683C6.3335 6.49002 6.40374 6.32045 6.52876 6.19543C6.65379 6.0704 6.82335 6.00016 7.00017 6.00016C7.17698 6.00016 7.34655 6.0704 7.47157 6.19543C7.59659 6.32045 7.66683 6.49002 7.66683 6.66683V9.66683ZM7.00017 4.8335C6.83535 4.8335 6.67423 4.78462 6.53719 4.69305C6.40015 4.60149 6.29334 4.47134 6.23027 4.31907C6.16719 4.16679 6.15069 3.99924 6.18284 3.83759C6.215 3.67594 6.29437 3.52745 6.41091 3.41091C6.52745 3.29436 6.67594 3.215 6.83759 3.18284C6.99924 3.15069 7.1668 3.16719 7.31907 3.23026C7.47134 3.29334 7.60149 3.40015 7.69306 3.53719C7.78462 3.67423 7.8335 3.83535 7.8335 4.00016C7.8335 4.22118 7.7457 4.43314 7.58942 4.58942C7.43314 4.7457 7.22118 4.8335 7.00017 4.8335Z"
                                        fill="#96A3B6"
                                    />
                                </svg>
                            </Tooltip>
                        </div>
                    </>
                )}
                {tierBelowPlan && (
                    <PriceBox
                        priceTier="below"
                        product={tierBelowPlan}
                        currency={currency}
                        selectedPlan={selectedPlan}
                        onChangeSelectedPlan={() => onChangeSelectedPlan(tierBelowPlan.productIntervalCount)}
                        priceVariant={priceVariant}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />
                )}
                <div />
                <PriceBox
                    priceTier="recommended"
                    product={recommendedPlan}
                    currency={currency}
                    selectedPlan={selectedPlan}
                    onChangeSelectedPlan={() => onChangeSelectedPlan(recommendedPlan.productIntervalCount)}
                    priceVariant={priceVariant}
                    subscriptionWithoutTrial={subscriptionWithoutTrial}
                />
                {tierAbovePlan && (
                    <PriceBox
                        priceTier="above"
                        savePercentage={savePercentage}
                        product={tierAbovePlan}
                        currency={currency}
                        selectedPlan={selectedPlan}
                        onChangeSelectedPlan={() => onChangeSelectedPlan(tierAbovePlan.productIntervalCount)}
                        priceVariant={priceVariant}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />
                )}
            </div>

            {isPromoCodeEnabled && (
                <span className="text-dark3">
                    {t('survey:program.variant-a.plan.promo_code')}{' '}
                    <button className="cursor-pointer text-boost2 font-bold">
                        {t('survey:program.variant-a.plan.click_here')}
                    </button>
                </span>
            )}
        </div>
    )
}

export default PriceSelectionVariantB
