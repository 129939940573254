import React from 'react'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { calculateWeightGroup, convertKgToLbs } from '../utils/convert'

export const HealthyHabitsStepTitle: React.FC = () => {
    const [, answers] = useQuestion()
    const { t } = useTranslation()

    const decade = answers['age'][0].split('-')[0]
    const weightUnit = answers['measurement_system'] === 'metric' ? t('common:kg') : t('common:lbs')

    const weightGroup = calculateWeightGroup(answers['target_weight'] as number)
    const weightStart =
        answers['measurement_system'] === 'metric' ? weightGroup.start : convertKgToLbs(weightGroup.start)
    const weightEnd = answers['measurement_system'] === 'metric' ? weightGroup.end : convertKgToLbs(weightGroup.end)

    return (
        <h3 className="text-center text-xs xs:text-sm md:text-lg xs:leading-tight text-gray-500 mt-2 px-2 xs:px-4">
            {decade === '18'
                ? t('survey:healthy_habits.subtitle_18', {
                      context: answers['gender'][0],
                      target: `${weightStart}-${weightEnd}`,
                      metric: weightUnit,
                  })
                : t('survey:healthy_habits.subtitle', {
                      age: decade,
                      context: answers['gender'][0],
                      target: `${weightStart}-${weightEnd}`,
                      metric: weightUnit,
                  })}
        </h3>
    )
}
