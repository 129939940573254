import React from 'react'

const JourneyChart: React.FC<{ weight: number; targetWeight: number; weightUnit: string }> = ({
    weight,
    targetWeight,
    weightUnit,
}) => {
    const milestone = Math.max(weight - Math.floor((weight - targetWeight) / 2), targetWeight)

    return (
        <svg viewBox="0 0 461 198" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line
                x1="9"
                y1="1"
                x2="8.99999"
                y2="197"
                stroke="#C6C6C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            ></line>
            <line
                opacity="0.5"
                x1="157"
                y1="1"
                x2="157"
                y2="197"
                stroke="#C6C6C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            ></line>
            <line
                opacity="0.5"
                x1="305"
                y1="1"
                x2="305"
                y2="197"
                stroke="#C6C6C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            ></line>
            <line
                x1="453"
                y1="1"
                x2="453"
                y2="197"
                stroke="#C6C6C6"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="6 6"
            ></line>
            <line
                opacity="0.5"
                x1="11"
                y1="178"
                x2="451"
                y2="178"
                stroke="#d1e9d3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></line>
            <rect opacity="0.1" x="9" y="162" width="445" height="15" fill="#7df789"></rect>
            <line
                opacity="0.5"
                x1="11"
                y1="161"
                x2="451"
                y2="162"
                stroke="#d1e9d3"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></line>
            <path
                d="M10 72C56 72 91 111.288 157 117C237 123.923 250.954 143.821 304.5 162.5C369 185 409 162.5 453 162.5"
                stroke="url(#paint0_linear)"
                strokeWidth="7"
            ></path>
            <circle cx="9" cy="71" r="9" fill="#F79E1B"></circle>
            <circle cx="157" cy="116" r="9" fill="#C59675"></circle>
            <circle cx="452" cy="163" r="9" fill="#47a64a"></circle>
            <circle cx="304" cy="163" r="9" fill="#9eab92"></circle>
            <g>
                <rect width="82" y="18" height="36" rx="4" fill="#636366"></rect>
                <text
                    x="40"
                    y="43"
                    width="100"
                    textAnchor="middle"
                    strokeWidth="7"
                    fill="#fff"
                    className="text-xl font-bold"
                >
                    {weight} {weightUnit}
                </text>
            </g>
            <g>
                <rect
                    x="150"
                    y="61"
                    width="82"
                    height="36"
                    rx="4"
                    fill="white"
                    filter="url(#filter0_d)"
                    className="shadow-sm"
                ></rect>
                <text x="189" y="88" width="100" textAnchor="middle" fill="#636366" className="text-xl">
                    {milestone} {weightUnit}
                </text>
            </g>
            <g>
                <rect x="380" y="110" width="82" height="36" rx="4" fill="#636366"></rect>
                <text x="420" y="135" width="100" textAnchor="middle" fill="#fff" className="text-xl font-bold">
                    {targetWeight} {weightUnit}
                </text>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="230.75"
                    y1="72"
                    x2="230.75"
                    y2="201.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F79E1B"></stop>
                    <stop offset="1" stopColor="#7df789"></stop>
                </linearGradient>
                <filter
                    id="filter0_d"
                    x="150"
                    y="60"
                    width="88"
                    height="42"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    ></feColorMatrix>
                    <feOffset dy="4"></feOffset>
                    <feGaussianBlur stdDeviation="2"></feGaussianBlur>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"></feColorMatrix>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
            </defs>
        </svg>
    )
}

export default JourneyChart
