import React, { ReactElement, ReactNode } from 'react'

const Badge: React.FC<{ icon: ReactNode }> = ({ icon }): ReactElement => {
    return (
        <div className="h-8 w-8 bg-boost1 rounded-full border-4 border-white shadow-standard flex items-center justify-center z-10">
            {icon}
        </div>
    )
}

export default Badge
