import Cookies from 'js-cookie'
import { ApiError, IPData } from './api'
import { firebase } from './firebaseClient'
import * as Sentry from '@sentry/node'

interface FBPCookies {
    _fbc: string
    _fbp: string
}

export const getFBPCookies = (): FBPCookies => {
    const _fbc = Cookies.get('_fbc')
    const _fbp = Cookies.get('_fbp')

    return { _fbc, _fbp }
}

export const sendFacebookEvent = async (params: {
    email: string
    userID: string
    event: string
    userIPData: IPData
    _fbp: string
    _fbc: string
    source: string
    price?: number
}): Promise<any> => {
    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        const resp: { data?: any; error?: ApiError } = await fetch('/api/facebook/sendEvent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify(params),
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`FB Event Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return {}
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}
