import React, { useState } from 'react'
import { useTranslation } from 'i18n'
import Countdown from 'react-countdown'

const TrialCountdown: React.FC<{ countdownEnd: number; testVariant: string }> = ({ countdownEnd, testVariant }) => {
    const [countdownCmpleted, setCountdownCompleted] = useState(countdownEnd < Date.now())
    const { t } = useTranslation()

    const onCountdownCompleted = () => {
        setCountdownCompleted(true)
    }

    const countdownRenderer = ({ formatted }) => {
        return `${formatted.minutes}:${formatted.seconds}`
    }

    if (testVariant === 'control') {
        return (
            !countdownCmpleted && (
                <div className="rounded-xl border-2 border-yellow-400 bg-yellow-50 px-4 py-2 text-xs xs:text-sm md:text-lg text-left mt-4 md:mt-8 mb-4 md:mb-8">
                    <div className="font-black text-gray-900">
                        {t(`survey:program.${testVariant}.notification.title`)}
                    </div>
                    <div className="mt-3 font-black text-yellow-500">
                        {t(`survey:program.${testVariant}.notification.time_remaining`)}{' '}
                        <Countdown date={countdownEnd} renderer={countdownRenderer} onComplete={onCountdownCompleted} />
                    </div>
                    <div className="mt-2 text-gray-800">
                        {t(`survey:program.${testVariant}.notification.scroll_down`)}
                    </div>
                </div>
            )
        )
    } else {
        return (
            !countdownCmpleted && (
                <div className="rounded-2xl px-6 py-4 bg-rush5-light flex flex-col items-center justify-center mt-4 mb-10">
                    <span className="text-center text-base text-dark1 font-bold">
                        {t(`survey:program.${testVariant}.notification.title`)}
                    </span>
                    <span className="text-center text-base text-warn font-bold">
                        {t(`survey:program.${testVariant}.notification.subtitle`)}{' '}
                        <Countdown date={countdownEnd} renderer={countdownRenderer} onComplete={onCountdownCompleted} />{' '}
                        {t(`survey:program.${testVariant}.notification.minutes`)}.
                    </span>
                </div>
            )
        )
    }
}

export default TrialCountdown
