import React, { useState } from 'react'
import { useTranslation } from 'i18n'
import { getProductTrackingParams, PaymentProduct } from '@services/payment_product'
import { useUser } from '@contexts/auth'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'
import * as events from '@services/client/events'
import { PayPalButtons, FUNDING } from '@paypal/react-paypal-js'
import { trackAmplitudeEvent } from '@services/client/amplitude'
import { createPaypalSubscription } from '@services/client/paypal'
import { Modal } from '@components/Modal'
import { Button } from '@components/Button'
import PaymentBeneftis from './PaymentBeneftis'
import AppStoreRating from './AppStoreRating'
import SecurePaymentNote from './SecurePaymentNote'
import PaymentNote from './PaymentNote'
import PaymentInfo from './PaymentInfo'

const CheckoutForm: React.FC<{
    subscriptionWithoutTrial: boolean
    product: PaymentProduct
    onSuccess: (provider: string) => void
}> = ({ subscriptionWithoutTrial, product, onSuccess }) => {
    const user = useUser()
    const fbCookies = getFBPCookies()
    const fbEventParams = {
        email: user.email,
        userID: user.id,
        userIPData: user.ipData,
        _fbc: fbCookies._fbc,
        _fbp: fbCookies._fbp,
        source: '/survey/your_program',
        price: subscriptionWithoutTrial ? product.productPrice : product.productPrice + product.trialPrice,
    }

    const { t } = useTranslation()

    const [showWhyNowModal, setShowWhyNowModal] = useState(false)
    const currency = t(`common:currency.${product.currency}`)

    const getBillingCycles = (product, noTrial) => {
        if (noTrial) {
            return [
                {
                    pricing_scheme: {
                        fixed_price: {
                            currency_code: product.currency.toUpperCase(),
                            value: product.productPrice.toString(),
                        },
                    },
                    frequency: {
                        interval_unit: product.productInterval.toUpperCase(),
                        interval_count: product.productIntervalCount,
                    },
                    tenure_type: 'REGULAR',
                    sequence: 1,
                    total_cycles: 0, //If total_cycles === 0, this means there are infinite cycles
                },
            ]
        } else {
            return [
                {
                    pricing_scheme: {
                        fixed_price: {
                            currency_code: product.currency.toUpperCase(),
                            value: product.trialPrice.toString(),
                        },
                    },
                    frequency: {
                        interval_unit: 'DAY',
                        interval_count: product.trialPeriodDays,
                    },
                    tenure_type: 'TRIAL',
                    sequence: 1,
                    total_cycles: 1,
                },
                {
                    pricing_scheme: {
                        fixed_price: {
                            currency_code: product.currency.toUpperCase(),
                            value: product.productPrice.toString(),
                        },
                    },
                    frequency: {
                        interval_unit: product.productInterval.toUpperCase(),
                        interval_count: product.productIntervalCount,
                    },
                    tenure_type: 'REGULAR',
                    sequence: 2,
                    total_cycles: 0,
                },
            ]
        }
    }

    return (
        <div className="mt-0 mb-10">
            <div className="flex justify-between border-b border-gray-300 pb-2 items-center">
                <div className="text-base md:text-lg font-bold uppercase">
                    {t('survey:program.control.form.payment_method')}
                </div>
                {!subscriptionWithoutTrial && (
                    <div
                        onClick={() => {
                            events.trackAmplitudeAndGTAGEvent('checkout_why_now_opened')
                            setShowWhyNowModal(true)
                        }}
                        className="text-gray-500 text-xs xs:text-sm md:text-base cursor-pointer"
                    >
                        {t('survey:program.control.form.why_now')}
                    </div>
                )}
            </div>
            {!subscriptionWithoutTrial && (
                <div className="text-xs md:text-base text-center my-2">
                    {t('survey:program.control.form.price_trial', {
                        price: product.trialPrice.toFixed(2),
                        trialPeriod: product.trialPeriodDays,
                        currency,
                    })}
                </div>
            )}

            <div className="rounded-md max-w-3/4 mx-auto cursor-pointer mt-4 h-11 relative z-0">
                <PayPalButtons
                    style={{
                        height: 44,
                        layout: 'horizontal',
                        color: 'gold',
                        label: 'pay',
                        shape: 'rect',
                        tagline: false,
                    }}
                    fundingSource={FUNDING.PAYPAL}
                    createSubscription={async (data, actions) => {
                        return actions.subscription.create({
                            plan_id: product.paypalPlan,
                            plan: {
                                payment_preferences: {
                                    service_type: 'PREPAID',
                                    auto_bill_outstanding: true,
                                    setup_fee: {
                                        currency_code: product.currency.toUpperCase(),
                                        value: '0.0',
                                    },
                                    setup_fee_failure_action: 'CONTINUE',
                                    payment_failure_threshold: 1,
                                },
                                billing_cycles: getBillingCycles(product, subscriptionWithoutTrial),
                            },
                        })
                    }}
                    onClick={async () => {
                        events.trackAddPaymentInfo({ uid: user.id })
                        await sendFacebookEvent({ ...fbEventParams, event: 'AddPaymentInfo' })
                        trackAmplitudeEvent('payment_started', getProductTrackingParams('paypal', product))
                    }}
                    onCancel={() => {
                        events.trackAmplitudeAndGTAGEvent(
                            'payment_canceled',
                            getProductTrackingParams('paypal', product),
                        )
                    }}
                    onError={err => {
                        console.error(err)
                        events.trackAmplitudeAndGTAGEvent('payment_failed', {
                            ...getProductTrackingParams('paypal', product),
                            error: err,
                        })
                    }}
                    onApprove={async data => {
                        await createPaypalSubscription({ order: data })
                        onSuccess && onSuccess('paypal')
                    }}
                />
            </div>

            <PaymentBeneftis product={product} subscriptionWithoutTrial={subscriptionWithoutTrial} />

            <AppStoreRating />
            {!subscriptionWithoutTrial && <PaymentNote product={product} />}
            <SecurePaymentNote />
            <PaymentInfo />

            {showWhyNowModal && (
                <Modal>
                    <div className="mb-6 text-center text-gray-600">
                        <p className="mb-3">
                            {t('survey:program.control.why_now_modal.paragraph_1', {
                                trialPeriod: product.trialPeriodDays,
                            })}
                        </p>
                        <p>
                            {t('survey:program.control.why_now_modal.paragraph_2', {
                                trialPeriod: product.trialPeriodDays,
                            })}
                        </p>
                    </div>
                    <Button
                        primary
                        label={t('common:controls.got_it')}
                        onClick={() => setShowWhyNowModal(false)}
                        className="w-full"
                    />
                </Modal>
            )}
        </div>
    )
}

export default CheckoutForm
