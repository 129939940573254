import React from 'react'
import { useTranslation } from 'i18n'

const PaymentInfo: React.FC = () => {
    const { t } = useTranslation()

    return (
        <div className="mt-6 text-xs md:text-base">
            <div className="mb-4">
                <div className="font-bold">{t('survey:program.control.advantages.information_is_safe.title')}</div>
                <div className="leading-tight">{t('survey:program.control.advantages.information_is_safe.note')}</div>
            </div>
            <div className="mb-4">
                <div className="font-bold">{t('survey:program.control.advantages.secure_checkout.title')}</div>
                <div className="leading-tight">{t('survey:program.control.advantages.secure_checkout.note')}</div>
            </div>
            <div className="mb-4">
                <div className="font-bold">{t('survey:program.control.advantages.need_any_help.title')}</div>
                <div className="leading-tight">
                    {t('survey:program.control.advantages.need_any_help.contact_us')}{' '}
                    <a className="text-green-600 hover:underline" href="mailto:support@fastic.com">
                        {t('survey:program.control.advantages.need_any_help.here')}
                    </a>
                    .
                </div>
            </div>
        </div>
    )
}

export default PaymentInfo
