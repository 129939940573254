import React, { useEffect, useState } from 'react'

interface SliderProps {
    width: number
    interval: number
    children?: React.ReactNode
    onEnd?: () => void
}

export const Slider: React.FC<SliderProps> = ({ width, interval, onEnd, ...props }) => {
    const children = React.Children.toArray(props.children)
    const [currentSlide, setCurrentSlide] = useState(0)

    useEffect(() => {
        const timer = window.setInterval(() => {
            setCurrentSlide(currentSlide => {
                if (currentSlide === children.length - 1) {
                    window.clearInterval(timer)
                    setTimeout(() => {
                        onEnd && onEnd()
                    }, 100)
                    return currentSlide
                }
                return currentSlide + 1
            })
        }, interval)
        return () => {
            window.clearInterval(timer)
        }
    }, [])

    const totalWidth = width * children.length
    const translateX = -width * currentSlide

    return (
        <div className="relative block w-full z-10">
            <div className="relative overflow-hidden">
                <div
                    className="relative"
                    style={{
                        transform: `translate3d(${translateX}px, 0px, 0px)`,
                        transition: 'all .3s ease 0s',
                        width: `${totalWidth}px`,
                    }}
                >
                    {children.map((node, i) => (
                        <div key={`slide_${i}`} className="relative float-left" style={{ width: `${width}px` }}>
                            {node}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
