import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useCountUp } from 'react-countup'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { Step } from '../components/Step'
import { Choices } from '../components/Choices'
import { useQuestion } from '../contexts/survey'
import { Title } from '../components/Title'
import { StarIcon } from '../components/StarIcon'
import { Modal } from '../components/Modal'
import { useTransition, animated, config } from 'react-spring'
import { useTranslation } from '../i18n'
import { trackAmplitudeAndGTAGEvent } from '@services/client/events'
import Image from 'next/image'
import assets from './assets'
import { Button } from '@components/Button'

const loaders = [
    {
        key: 'body_parameters',
        durations: [
            { to: 88, duration: 5 },
            { to: 100, duration: 1 },
        ],
    },
    { key: 'meals_and_activity' },
    {
        key: 'medical_conditions',
        durations: [
            { to: 88, duration: 3 },
            { to: 100, duration: 1 },
        ],
    },
    { key: 'generating_personalized_plan' },
]

const comments = ['patricia_jones', 'maximilian_stritt', 'virginia_smith', 'edward_miller']

type ProgressQuestion = {
    name: string
    options: string[]
}

export const PreparePlanStep: React.FC = () => {
    const [question, , dispatch] = useQuestion()
    const { t } = useTranslation()

    const [progressStep, setProgressStep] = useState(0)
    const [showQuestion, setShowQuestion] = useState<ProgressQuestion>(null)

    const onCountUpEnd = () => {
        if (progressStep === 0) {
            trackAmplitudeAndGTAGEvent('prepare_plan_question_opened', {
                name: 'workout',
            })
            setShowQuestion({
                name: 'workout',
                options: ['never', '1-2', '3-4', '5-6', 'everyday'],
            })
        } else if (progressStep === 2) {
            trackAmplitudeAndGTAGEvent('prepare_plan_question_opened', {
                name: 'intermittent_fasting',
            })
            setShowQuestion({
                name: 'intermittent_fasting',
                options: ['yes', 'no', 'inconsistently'],
            })
        } else {
            setProgressStep(current => {
                return current + 1
            })
        }
    }

    useEffect(() => {
        if (progressStep >= loaders.length) {
            dispatch({ type: 'QUESTION_COMPLETED' })
            return
        }
        countUp.start()
    }, [progressStep])

    const countUp = useCountUp({
        start: 10,
        end: 100,
        startOnMount: false,
        duration: 5,
        onEnd: onCountUpEnd,
    })

    const onQuestionAnswered = (values: string[]) => {
        trackAmplitudeAndGTAGEvent('prepare_plan_question_answered', {
            name: showQuestion.name,
            answer: values,
        })
        setTimeout(() => {
            setShowQuestion(null)
            setProgressStep(current => {
                return current + 1
            })
        }, 300)
    }

    return (
        <Step key={question.path} name={question.name}>
            <div className="mt-4 md:mt-8 xs:mb-8 md:mb-16">
                {loaders.map((loader, index) => {
                    const isActive = progressStep === index
                    const isCompleted = progressStep > index
                    const progress = isCompleted ? 100 : isActive ? Number(countUp.countUp.toString()) : 0
                    return (
                        <ProgressBar
                            key={`progress_${loader.key}`}
                            title={t(`survey:prepare_plan.loaders.${loader.key}`)}
                            active={isActive}
                            completed={isCompleted}
                            progress={progress}
                        />
                    )
                })}
            </div>
            <Title>{t('survey:prepare_plan.comments.title')}</Title>
            <Comments />
            {showQuestion && (
                <QuestionModal name={showQuestion.name} options={showQuestion.options} onChange={onQuestionAnswered} />
            )}
        </Step>
    )
}

const ProgressBar: React.FC<{
    title: string
    active?: boolean
    completed?: boolean
    progress?: number
}> = ({ title, active, completed, progress = 0 }) => {
    return (
        <div className="transition-colors duration-300 text-base mb-3 xs:mb-4 md:mb-6">
            <div className="flex justify-between items-center">
                <div
                    className={classNames(
                        active && !completed && 'font-bold',
                        completed || active ? 'text-dark2' : 'text-dark3',
                    )}
                >
                    {title}
                </div>
                <div className="flex items-center">
                    <LoadingSpinner
                        size="sm"
                        className={classNames('transition-opacity duration-300', (!active || completed) && 'opacity-0')}
                    />
                    <span
                        className={classNames(
                            'ml-2 text-right',
                            active && 'font-bold',
                            completed || active ? 'text-dark2' : 'text-dark3',
                        )}
                    >
                        {progress.toFixed(0)}%
                    </span>
                </div>
            </div>
            <div className="w-full h-1 mt-2 mb-8 rounded-sm bg-light2 relative overflow-hidden">
                <div style={{ width: `${progress}%` }} className="bg-boost2 w-0 h-full"></div>
            </div>
        </div>
    )
}

const Comments: React.FC = () => {
    const [currentComment, setCurrentComment] = useState(0)
    const { t } = useTranslation()

    useEffect(() => {
        const timer = window.setInterval(() => {
            setCurrentComment(currentComment => {
                if (currentComment === comments.length - 1) {
                    return 0
                }
                return currentComment + 1
            })
        }, 5000)
        return () => {
            window.clearInterval(timer)
        }
    }, [])

    const transitions = useTransition(comments[currentComment], item => item, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: {
            ...config.molasses,
            duration: 1000,
        },
    })

    return (
        <div className="flex items-start relative mt-4 pb-2">
            {transitions.map(({ item, props, key }) => (
                <animated.div key={key} style={{ ...props }}>
                    <Comment
                        author={t(`survey:prepare_plan.comments.${item}.author`)}
                        text={t(`survey:prepare_plan.comments.${item}.text`)}
                        imageUrl={assets[item]}
                    />
                </animated.div>
            ))}
        </div>
    )
}

const Comment: React.FC<{ author: string; text: string; imageUrl: string }> = ({ author, text, imageUrl }) => {
    return (
        <div className="w-full absolute rounded-xl p-2 mt-2 md:mt-4 bg-boost6">
            <div className="flex flex-row items-center justify-between mb-3">
                <div className="flex flex-row items-center">
                    <div className="mt-1 ml-1 mr-3">
                        <Image src={imageUrl} alt={author} height={56} width={56} />
                    </div>
                    <span className="text-black text-base font-bold">{author}</span>
                </div>
                <div className="text-star flex justify-center space-x-1">
                    <StarIcon className="h-4 md:h-6" />
                    <StarIcon className="h-4 md:h-6" />
                    <StarIcon className="h-4 md:h-6" />
                    <StarIcon className="h-4 md:h-6" />
                    <StarIcon className="h-4 md:h-6" />
                </div>
            </div>
            <div className="bg-white p-2 rounded-lg">
                <p className="text-black text-sm">{text}</p>
            </div>
        </div>
    )
}

const QuestionModal: React.FC<{ name: string; options: string[]; onChange: (values: string[]) => void }> = ({
    name,
    options,
    onChange,
}) => {
    const [selected, setSelected] = useState([])
    const { t } = useTranslation()

    const onChoiceChange = (values: string[]) => {
        setSelected(values)
    }

    const onConfirm = () => {
        onChange && onChange(selected)
    }

    return (
        <Modal>
            <div className="text-center text-base xs:text-lg md:text-2xl leading-tight font-bold text-dark1 mb-6 px-2 xs:px-4">
                {t(`survey:prepare_plan.questions.${name}.title`)}
            </div>
            <Choices
                name={`prepare_plan.questions.${name}`}
                options={options}
                selected={selected}
                onChange={onChoiceChange}
            />
            <div className="mt-6 flex w-full items-center justify-center">
                <Button
                    primary
                    disabled={selected.length === 0}
                    label={t('common:controls.confirm')}
                    className="w-full md:w-80"
                    customBg={{ default: 'bg-boost2', hover: 'hover:bg-boost2-hover' }}
                    onClick={onConfirm}
                />
            </div>
        </Modal>
    )
}
