import React, { ReactElement } from 'react'
import { calculateWeeklyPrice, getPaymentProductsEitherSide, PaymentProduct } from '@services/payment_product'
import { useTranslation } from 'i18n'
import PriceSelectionVariantA from './PriceSelection/VariantA'
import PriceSelectionVariantB from './PriceSelection/VariantB'
import PriceSelectionVariantC from './PriceSelection/VariantC'
import PriceBox from './PriceSelection/PriceBox'
import { Button } from '@components/Button'

const PriceBreakdownVariantA: React.FC<{
    recommendedPlan: PaymentProduct
    isPromoCodeEnabled: boolean
    priceVariant?: string
    weightToLoose?: number
    onChangeSelectedPlan?: (plan: number) => void
    selectedPlan?: number
    subscriptionWithoutTrial: boolean
}> = ({
    recommendedPlan,
    isPromoCodeEnabled,
    priceVariant,
    weightToLoose,
    onChangeSelectedPlan,
    selectedPlan,
    subscriptionWithoutTrial,
}): ReactElement => {
    const { t } = useTranslation()
    const currency = t(`common:currency.${recommendedPlan.currency}`)

    const [tierBelowPlan, tierAbovePlan] = getPaymentProductsEitherSide(
        recommendedPlan.currency.toUpperCase(),
        recommendedPlan.trialPrice,
        weightToLoose,
    )

    const recomendedWeeklyPrice = calculateWeeklyPrice(recommendedPlan)
    const tierAboveWeeklyPrice = calculateWeeklyPrice(tierAbovePlan)
    const savePercentage = Math.round(100 - (tierAboveWeeklyPrice * 100) / recomendedWeeklyPrice)

    switch (priceVariant) {
        case 'variant-a': {
            return (
                <PriceSelectionVariantA
                    recommendedPlan={recommendedPlan}
                    isPromoCodeEnabled={isPromoCodeEnabled}
                    selectedPlan={selectedPlan}
                    onChangeSelectedPlan={onChangeSelectedPlan}
                    priceVariant={priceVariant}
                    savePercentage={savePercentage}
                    currency={currency}
                    tierAbovePlan={tierAbovePlan}
                    tierBelowPlan={tierBelowPlan}
                    subscriptionWithoutTrial={subscriptionWithoutTrial}
                />
            )
        }
        case 'variant-b': {
            return (
                <PriceSelectionVariantB
                    recommendedPlan={recommendedPlan}
                    isPromoCodeEnabled={isPromoCodeEnabled}
                    selectedPlan={selectedPlan}
                    onChangeSelectedPlan={onChangeSelectedPlan}
                    priceVariant={priceVariant}
                    savePercentage={savePercentage}
                    currency={currency}
                    tierAbovePlan={tierAbovePlan}
                    tierBelowPlan={tierBelowPlan}
                    subscriptionWithoutTrial={subscriptionWithoutTrial}
                />
            )
        }
        case 'variant-c': {
            return (
                <PriceSelectionVariantC
                    recommendedPlan={recommendedPlan}
                    isPromoCodeEnabled={isPromoCodeEnabled}
                    selectedPlan={selectedPlan}
                    onChangeSelectedPlan={onChangeSelectedPlan}
                    priceVariant={priceVariant}
                    savePercentage={savePercentage}
                    currency={currency}
                    tierAbovePlan={tierAbovePlan}
                    tierBelowPlan={tierBelowPlan}
                    subscriptionWithoutTrial={subscriptionWithoutTrial}
                />
            )
        }
        default: {
            return (
                <div className="flex flex-col my-8">
                    <PriceBox
                        priceTier="recommended"
                        product={recommendedPlan}
                        currency={currency}
                        selectedPlan={selectedPlan}
                        onChangeSelectedPlan={() => onChangeSelectedPlan(recommendedPlan.productIntervalCount)}
                        priceVariant={priceVariant}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />
                    {isPromoCodeEnabled && (
                        <span className="text-dark3">
                            {t('survey:program.variant-a.plan.promo_code')}{' '}
                            <button className="cursor-pointer text-boost2 font-bold">
                                {t('survey:program.variant-a.plan.click_here')}
                            </button>
                        </span>
                    )}
                </div>
            )
        }
    }
}

export default PriceBreakdownVariantA
