import { useState, useEffect } from 'react'

type WindowDimensions = { width: number; height: number }

const getWindowDimensions = (): WindowDimensions => {
    const { innerWidth: width, innerHeight: height } = window
    return {
        width,
        height,
    }
}

const useWindowDimensions = (): WindowDimensions => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const handleResize = () => {
        setWindowDimensions(getWindowDimensions())
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowDimensions
}

export default useWindowDimensions
