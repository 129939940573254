import Image from 'next/image'
import React from 'react'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'

const references = [
    {
        key: 'national_center',
        alt: 'National center logo',
        imageUrl: assets.trust_national_center,
        height: 59,
        width: 120,
    },
    {
        key: 'rochester_medical',
        alt: 'Rochester medical  logo',
        imageUrl: assets.trust_rochester_medical,
        height: 80,
        width: 99,
    },
]

export const TrustStep: React.FC = () => {
    const [question] = useQuestion()
    const { t } = useTranslation()

    return (
        <Step key={question.path} name={question.name}>
            <div className="mt-3 xs:mt-8 sm:mt-10">
                <Chart />
                <div className="mt-3 xs:mt-8">
                    {references.map(ref => (
                        <div key={ref.key} className="flex flex-row items-center mb-3 xs:mb-6">
                            <div className="min-w-32 flex items-center justify-center mr-4">
                                <Image
                                    src={ref.imageUrl}
                                    alt={ref.alt}
                                    height={ref.height}
                                    width={ref.width}
                                    priority
                                />
                            </div>
                            <p
                                className="text-dark2 text-sm"
                                dangerouslySetInnerHTML={{
                                    __html: t(`survey:trust.${ref.key}`, { interpolation: { escapeValue: false } }),
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Step>
    )
}

const Chart: React.FC = () => {
    const { t } = useTranslation()

    return (
        <svg
            width="480"
            height="261"
            viewBox="0 0 480 261"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-full max-w-md mx-auto h-56 xs:h-auto"
        >
            <g clipPath="url(#clip0)">
                <text x={80} y={20} textAnchor="middle" className="text-base font-extrabold" fill="#18243c">
                    {t('survey:trust.restrictive_diets')}
                </text>
                <text
                    x={-214}
                    y={18}
                    textAnchor="middle"
                    fill="#18243c"
                    className="font-bold text-xs uppercase tracking-widest transform -rotate-90"
                >
                    {t('common:weight')}
                </text>
                <text
                    x={50}
                    y={254}
                    textAnchor="middle"
                    fill="#18243c"
                    className="font-bold text-xs uppercase tracking-widest"
                >
                    {t('common:time')}
                </text>
                <text x={105} y={225} textAnchor="middle" fill="#FF6037" className="text-xs font-bold">
                    {t('survey:trust.first_diet')}
                </text>
                <text x={220} y={188} textAnchor="middle" fill="#FF6037" className="text-xs font-bold">
                    {t('survey:trust.second_diet')}
                </text>
                <text x={330} y={155} textAnchor="middle" fill="#FF6037" className="text-xs font-bold">
                    {t('survey:trust.third_diet')}
                </text>
                <text x={436} y={120} textAnchor="middle" fill="#FF6037" className="text-xs font-bold">
                    {t('survey:trust.fourth_diet')}
                </text>
                <path
                    opacity="0.3"
                    d="M12.532 38.4616C12.3606 38.2911 12.1281 38.1953 11.8856 38.1953C11.6432 38.1953 11.4107 38.2911 11.2392 38.4616L5.42072 44.249C5.3334 44.3329 5.26375 44.4333 5.21583 44.5442C5.16791 44.6552 5.14269 44.7745 5.14164 44.8953C5.14058 45.016 5.16371 45.1358 5.20969 45.2475C5.25566 45.3593 5.32354 45.4608 5.40939 45.5462C5.49523 45.6316 5.59732 45.6991 5.70968 45.7449C5.82204 45.7906 5.94243 45.8136 6.06383 45.8125C6.18523 45.8115 6.3052 45.7864 6.41675 45.7387C6.5283 45.6911 6.62918 45.6218 6.71352 45.5349L11.8856 40.3904L17.0578 45.5349C17.2302 45.7006 17.4611 45.7923 17.7009 45.7902C17.9406 45.7881 18.1699 45.6925 18.3394 45.5239C18.5089 45.3553 18.6051 45.1272 18.6072 44.8887C18.6093 44.6503 18.5171 44.4206 18.3506 44.249L12.532 38.4616ZM12.7999 181.882V39.1045H10.9714V181.882H12.7999ZM465.132 250.73C465.217 250.646 465.284 250.545 465.33 250.435C465.376 250.325 465.4 250.207 465.4 250.087C465.4 249.968 465.376 249.849 465.33 249.739C465.284 249.629 465.217 249.529 465.132 249.444L459.314 243.657C459.142 243.486 458.91 243.39 458.667 243.39C458.425 243.39 458.192 243.486 458.021 243.657C457.849 243.827 457.753 244.058 457.753 244.3C457.753 244.541 457.849 244.772 458.021 244.943L463.193 250.087L458.021 255.232C457.849 255.402 457.753 255.633 457.753 255.875C457.753 256.116 457.849 256.347 458.021 256.518C458.192 256.688 458.425 256.784 458.667 256.784C458.91 256.784 459.142 256.688 459.314 256.518L465.132 250.73ZM74.0856 250.997H464.486V249.178H74.0856V250.997Z"
                    fill="#96A3B6"
                />
                <path d="M12.8 102.763H453.486" stroke="#FA8072" strokeLinecap="round" strokeDasharray="4 9.5" />
                <path
                    d="M345.808 23.9429C383.812 17.2215 368.26 101.386 400.083 102.219H401.015C432.958 101.338 426.65 7.29584 455.314 8.64013V102.218L401.015 102.219C400.865 102.223 400.713 102.225 400.561 102.225H400.56L315.622 102.221L264.294 102.221L208.96 102.223L153.038 102.224L102.734 102.225C107.281 83.8773 114.179 70.205 126.796 68.9255C141.843 67.4003 148.644 82.7346 153.038 102.224L208.96 102.223C213.674 73.8968 219.677 47.864 236.302 46.4306C256.313 44.7057 259.351 75.1097 264.294 102.221L315.622 102.221C321.504 69.4176 322.584 28.0505 345.808 23.9429Z"
                    fill="#FFF3F1"
                />
                <path
                    d="M12.8 103.218C48.9966 103.218 39.8574 218.713 72.043 210.983C104.229 203.253 86.8572 73.662 126.796 69.5697C166.735 65.4773 148.571 181.427 181.549 177.334C214.526 173.242 199.771 50.0174 236.302 46.8345C272.833 43.6515 252.8 148.688 291.056 144.141C329.31 139.594 307.616 30.9344 345.809 24.1066C384 17.2787 368.104 103.225 400.561 103.225C433.019 103.225 426.514 7.27524 455.314 8.63935"
                    stroke="#FF6037"
                    strokeWidth="4"
                />
                <path
                    d="M455.314 8.63935C426.514 7.27524 433.019 103.225 400.561 103.225M291.056 144.141C329.31 139.594 307.616 30.9344 345.808 24.1066M181.549 177.334C214.526 173.242 199.771 50.0174 236.302 46.8345M72.043 210.983C104.229 203.253 86.8571 73.662 126.796 69.5697"
                    stroke="#96A3B6"
                    strokeWidth="4"
                />
                <path
                    d="M119.857 62.7491H134.486V77.2997H119.857V62.7491ZM5.57153 96.3972H20.2001V110.948H5.57153V96.3972ZM229.572 40.014H244.2V54.5645H229.572V40.014ZM341.114 17.2788H355.743V31.8293H341.114V17.2788ZM451.743 0.909424H466.372V15.4599H451.743V0.909424Z"
                    fill="#FF6037"
                    stroke="white"
                    strokeWidth="2"
                />
                <path
                    d="M69.9429 218.713C73.9825 218.713 77.2572 215.455 77.2572 211.437C77.2572 207.419 73.9825 204.162 69.9429 204.162C65.9034 204.162 62.6287 207.419 62.6287 211.437C62.6287 215.455 65.9034 218.713 69.9429 218.713Z"
                    fill="#96A3B6"
                    stroke="white"
                    strokeWidth="3"
                />
                <path
                    d="M181.486 185.064C185.525 185.064 188.8 181.807 188.8 177.789C188.8 173.771 185.525 170.514 181.486 170.514C177.446 170.514 174.171 173.771 174.171 177.789C174.171 181.807 177.446 185.064 181.486 185.064Z"
                    fill="#96A3B6"
                    stroke="white"
                    strokeWidth="3"
                />
                <path
                    d="M290.286 152.326C294.325 152.326 297.6 149.069 297.6 145.051C297.6 141.033 294.325 137.775 290.286 137.775C286.246 137.775 282.971 141.033 282.971 145.051C282.971 149.069 286.246 152.326 290.286 152.326Z"
                    fill="#96A3B6"
                    stroke="white"
                    strokeWidth="3"
                />
                <path
                    d="M400.914 110.493C404.954 110.493 408.229 107.236 408.229 103.218C408.229 99.1997 404.954 95.9425 400.914 95.9425C396.875 95.9425 393.6 99.1997 393.6 103.218C393.6 107.236 396.875 110.493 400.914 110.493Z"
                    fill="#96A3B6"
                    stroke="white"
                    strokeWidth="3"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="480" height="261" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
