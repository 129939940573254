import React from 'react'
import Image from 'next/image'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import useWindowDimensions from '../utils/useWindowDimensions'
import { Slider } from '@components/Slider'
import assets from './assets'

export const StorytellingStep: React.FC = () => {
    const [, , dispatch] = useQuestion()
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    const slidesWidth = Math.min(480, width - 32)

    const onSliderEnd = () => {
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    return (
        <div className="max-w-lg mx-auto px-4">
            <div className="mt-5 md:mt-7">
                <div className="flex flex-col content-center items-center text-center text-gray-600 text-sm xs:text-base md:text-lg font-bold">
                    <LoadingSpinner size={width > 320 ? 'lg' : 'sm'} />
                    <span
                        className="mt-2 md:mt-4"
                        dangerouslySetInnerHTML={{
                            __html: t('survey:storyteling.title', { interpolation: { escapeValue: false } }),
                        }}
                    ></span>
                </div>
                <div className="mt-12">
                    <Slider width={slidesWidth} interval={5000} onEnd={onSliderEnd}>
                        <Story imageUrl={assets.story_1} title={t('survey:storyteling.slider.1')} priorityImage />
                        <Story imageUrl={assets.story_2} title={t('survey:storyteling.slider.2')} />
                        <Story imageUrl={assets.story_3} title={t('survey:storyteling.slider.3')} />
                        <Story imageUrl={assets.story_4} title={t('survey:storyteling.slider.4')} />
                        <Story imageUrl={assets.story_5} title={t('survey:storyteling.slider.5')} />
                        <Story imageUrl={assets.story_6} title={t('survey:storyteling.slider.6')} />
                        <Story imageUrl={assets.story_7} title={t('survey:storyteling.slider.7')} />
                        <Story imageUrl={assets.story_8} title={t('survey:storyteling.slider.8')} />
                        <Story imageUrl={assets.story_9} title={t('survey:storyteling.slider.9')} />
                        <Story imageUrl={assets.story_10} title={t('survey:storyteling.slider.10')} />
                    </Slider>
                </div>
            </div>
        </div>
    )
}

type StoryProps = {
    imageUrl: string
    title: string
    priorityImage?: boolean
}

const Story: React.FC<StoryProps> = ({ imageUrl, title, priorityImage = false }) => {
    return (
        <div className="text-center">
            <Image
                src={imageUrl}
                alt={title}
                height={300}
                width={480}
                className="h-48 xs:h-60 md:h-72 w-auto mx-auto bloc"
                priority={priorityImage}
            />
            <span className="text-base xs:text-lg md:text-2xl text-gray-900 block font-bold px-8 mt-6">{title}</span>
        </div>
    )
}
