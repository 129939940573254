import React from 'react'
import { useTranslation } from '../i18n'
import { Title } from './Title'

export interface StepProps {
    name: string
    title?: React.ReactElement
    subtitle?: React.ReactElement
}

export const Step: React.FC<StepProps> = ({
    name,
    title: customTitle,
    subtitle: customSubtitle,
    children,
    ...props
}) => {
    const { t, i18n } = useTranslation()
    const title = !customTitle && i18n.exists(`survey:${name}.title`) ? t(`survey:${name}.title`) : null
    const subtitle =
        !customSubtitle && i18n.exists(`survey:${name}.subtitle`)
            ? t(`survey:${name}.subtitle`, { interpolation: { escapeValue: false } })
            : null

    return (
        <div className="max-w-lg mx-auto px-4" {...props}>
            {customTitle && customTitle}
            {title && !customTitle && <Title>{title}</Title>}
            {customSubtitle && customSubtitle}
            {subtitle && !customSubtitle && (
                <h3
                    className="text-center text-xs xs:text-sm md:text-lg xs:leading-tight text-dark3 mt-2 px-2 xs:px-4"
                    dangerouslySetInnerHTML={{ __html: subtitle }}
                />
            )}
            <div className="mt-5 md:mt-7">{children}</div>
        </div>
    )
}
