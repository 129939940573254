import React from 'react'
import { Button } from '@components/Button'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import Image from 'next/image'
import classNames from 'classnames'

export const HealthNoticeStep: React.FC = () => {
    const [question, , dispatch] = useQuestion()
    const { t } = useTranslation()

    const onContinue = () => {
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    return (
        <Step key={question.path} name={question.name}>
            <div className="flex flex-col items-center max-h-full">
                <div>
                    <Image
                        src="https://images.ctfassets.net/i6d954lr9wft/2lkCkT5bC3ErYqWhyVMtIm/67df47eaad50b73cc8528196749376a5/you_got_this.svg"
                        alt="Woman making a heart"
                        width={480}
                        height={280}
                        priority
                    />
                </div>
                <div className="flex flex-col space-y-4 text-base text-dark1 mt-4">
                    <p className="">{t('survey:health_notice.paragraph_1')}</p>
                    <p className="">{t('survey:health_notice.paragraph_2')}</p>
                    <p className="">{t('survey:health_notice.paragraph_3')}</p>
                    <p className="">{t('survey:health_notice.paragraph_4')}</p>
                </div>
                <Button
                    primary
                    label={t('common:controls.next')}
                    className="w-full sm:w-80 z-10 my-6 md:mt-10"
                    onClick={onContinue}
                />
            </div>
        </Step>
    )
}
