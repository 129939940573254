import React from 'react'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'

export const DietVsFasticStep: React.FC = () => {
    const [question] = useQuestion()
    const { t } = useTranslation()

    return (
        <Step key={question.path} name={question.name}>
            <div className="flex flex-col xs:mt-8 mt-10">
                <svg
                    width="480"
                    height="341"
                    viewBox="0 0 480 341"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full max-w-md mx-auto h-56 xs:h-auto"
                >
                    <path
                        d="M246.628 255.225C127.243 255.225 122.038 87.6091 21.5038 87.6091V326.436C21.5038 333.936 27.5843 340.017 35.0851 340.017H442.525C450.026 340.017 456.106 333.936 456.106 326.436V24.8253C351.779 80.0879 366.013 255.225 246.628 255.225Z"
                        fill="#FFF3F1"
                    />
                    <path
                        d="M442.525 340.017C450.026 340.017 456.106 333.937 456.106 326.436V233.735C385.922 233.735 295.725 298.203 209.264 233.735C137.839 193.443 102.089 83.4574 21.5038 83.4574V326.436C21.5038 333.937 27.5843 340.017 35.0851 340.017H442.525Z"
                        fill="#EBFAF5"
                    />
                    <path
                        d="M25.4168 87.1728C127.375 87.1728 129.198 254.724 250.274 254.724C371.349 254.724 343.843 89.3968 454.409 26.021"
                        stroke="#FA8072"
                        strokeWidth="5.65889"
                        strokeLinecap="round"
                    />
                    <path
                        d="M23.8516 84.7768H455.059"
                        stroke="#FA8072"
                        strokeWidth="1.13178"
                        strokeLinecap="round"
                        strokeDasharray="4.53 10.75"
                    />
                    <path
                        d="M25.5171 87.1729C102.738 87.1729 125.357 166.106 195.098 222.454C300.629 307.719 377.064 230.483 456.106 230.483"
                        stroke="#33CC99"
                        strokeWidth="5.65889"
                        strokeLinecap="round"
                    />
                    <path
                        opacity="0.2"
                        d="M456.106 254.166C469.233 254.166 479.874 243.545 479.874 230.443C479.874 217.342 469.233 206.721 456.106 206.721C442.98 206.721 432.339 217.342 432.339 230.443C432.339 243.545 442.98 254.166 456.106 254.166Z"
                        fill="#33CC99"
                    />
                    <path
                        d="M456.106 238.351C460.482 238.351 464.029 234.811 464.029 230.444C464.029 226.076 460.482 222.536 456.106 222.536C451.731 222.536 448.184 226.076 448.184 230.444C448.184 234.811 451.731 238.351 456.106 238.351Z"
                        fill="#33CC99"
                    />
                    <path
                        opacity="0.2"
                        d="M23.7673 110.703C36.8936 110.703 47.5346 100.082 47.5346 86.981C47.5346 73.8797 36.8936 63.2589 23.7673 63.2589C10.641 63.2589 0 73.8797 0 86.981C0 100.082 10.641 110.703 23.7673 110.703Z"
                        fill="#33CC99"
                    />
                    <path
                        d="M23.7673 94.8884C28.1428 94.8884 31.6898 91.3482 31.6898 86.981C31.6898 82.6139 28.1428 79.0737 23.7673 79.0737C19.3919 79.0737 15.8449 82.6139 15.8449 86.981C15.8449 91.3482 19.3919 94.8884 23.7673 94.8884Z"
                        fill="#33CC99"
                    />
                    <g>
                        <text x="380" y="15" textAnchor="middle" className="text-lg font-bold" fill="#FF6037">
                            {t(`survey:${question.name}.restrictive_diet`)}
                        </text>
                    </g>
                    <g>
                        <text x="50" y="15" textAnchor="middle" className="text-xl font-bold" fill="#18243C">
                            {t(`survey:${question.name}.chart_title`)}
                        </text>
                    </g>
                    <g>
                        <text x="410" y="190" textAnchor="middle" className="text-lg font-bold" fill="#29AB87">
                            Fastic
                        </text>
                    </g>
                </svg>
                <p
                    className="text-dark2 text-sm md:text-base xs:mt-8 md:mt-10 text-center"
                    dangerouslySetInnerHTML={{
                        __html: t(`survey:${question.name}.chart_note`, { interpolation: { escapeValue: false } }),
                    }}
                />
            </div>
        </Step>
    )
}
