import { Button } from '@components/Button'
import { PaymentProduct } from '@services/payment_product'
import { useTranslation } from 'i18n'
import React, { ReactElement } from 'react'
import PriceBox from './PriceBox'

const PriceSelectionVariantA: React.FC<{
    recommendedPlan: PaymentProduct
    isPromoCodeEnabled: boolean
    priceVariant?: string
    onChangeSelectedPlan?: (plan: number) => void
    selectedPlan?: number
    tierBelowPlan: PaymentProduct
    tierAbovePlan: PaymentProduct
    currency: string
    savePercentage: number
    subscriptionWithoutTrial: boolean
}> = ({
    recommendedPlan,
    isPromoCodeEnabled,
    priceVariant,
    onChangeSelectedPlan,
    selectedPlan,
    tierBelowPlan,
    tierAbovePlan,
    currency,
    savePercentage,
    subscriptionWithoutTrial,
}): ReactElement => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col my-8">
            <PriceBox
                priceTier="recommended"
                product={recommendedPlan}
                currency={currency}
                selectedPlan={selectedPlan}
                onChangeSelectedPlan={() => onChangeSelectedPlan(recommendedPlan.productIntervalCount)}
                priceVariant={priceVariant}
                subscriptionWithoutTrial={subscriptionWithoutTrial}
            />
            {isPromoCodeEnabled && (
                <span className="text-dark3">
                    {t('survey:program.variant-a.plan.promo_code')}{' '}
                    <button className="cursor-pointer text-boost2 font-bold">
                        {t('survey:program.variant-a.plan.click_here')}
                    </button>
                </span>
            )}

            <div className="flex flex-col pt-16">
                <div className="flex flex-col items-center justify-center space-y-2">
                    <h2 className="font-bold text-2xl text-dark1 text-center">
                        {t('survey:program.variant-a.plan.more_options')}
                    </h2>
                    <span className="text-dark3 text-center">
                        {t('survey:program.variant-a.plan.more_options_info')}
                    </span>
                </div>
                <div className="flex flex-col space-y-4 mt-6">
                    {tierBelowPlan && (
                        <PriceBox
                            priceTier="below"
                            product={tierBelowPlan}
                            currency={currency}
                            selectedPlan={selectedPlan}
                            onChangeSelectedPlan={() => onChangeSelectedPlan(tierBelowPlan.productIntervalCount)}
                            priceVariant={priceVariant}
                            subscriptionWithoutTrial={subscriptionWithoutTrial}
                        />
                    )}
                    {tierAbovePlan && (
                        <PriceBox
                            priceTier="above"
                            savePercentage={savePercentage}
                            product={tierAbovePlan}
                            currency={currency}
                            selectedPlan={selectedPlan}
                            onChangeSelectedPlan={() => onChangeSelectedPlan(tierAbovePlan.productIntervalCount)}
                            priceVariant={priceVariant}
                            subscriptionWithoutTrial={subscriptionWithoutTrial}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default PriceSelectionVariantA
