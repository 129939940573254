import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useUser } from '../contexts/auth'
import { useTranslation } from '../i18n'
import { trackAmplitudeEvent } from '../services/client/amplitude'
import * as events from '../services/client/events'
import { getJWT, getLoginToken } from '@services/client/api'
import { Title } from '@components/Title'
import QRCode from 'qrcode.react'
import { isAndroid, isIOS } from 'react-device-detect'
import assets from './assets'

export const DownloadStep: React.FC = () => {
    const user = useUser()
    const router = useRouter()
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const [loginToken, setLoginToken] = useState<string>('')
    const [downloadClicked, setDownloadClicked] = useState<boolean>(false)
    const [loginTokenFetched, setLoginTokenFetched] = useState<boolean>(false)
    const [userJWT, setUserJWT] = useState('')

    const fetchLoginToken = async () => {
        const loginTokenResp = await getLoginToken()

        if (loginTokenResp.token) {
            setLoginToken(loginTokenResp.token)
        }
        setLoginTokenFetched(true)
    }

    const fetchJWT = async () => {
        const JWT = await getJWT()
        if (typeof JWT === 'string') {
            setUserJWT(JWT)
        }
    }

    const isMobile = isAndroid || isIOS

    useEffect(() => {
        if (isMobile) {
            fetchLoginToken()
        } else {
            fetchJWT()
        }
    }, [])

    const downloadURL =
        process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
            ? `https://fastic-dev.onelink.me/BqxH?pid=web&c=web_install&deep_link_value=%2Flogin&af_dp=fastic-dev%3A%2F%2F&f_login_token=${loginToken}`
            : `https://fastic.onelink.me/KC3q?pid=web&c=web_install&af_dp=fastic%3A%2F%2F&deep_link_value=%2Flogin&f_login_token=${loginToken}`

    useEffect(() => {
        if (loginTokenFetched && downloadClicked) {
            setProcessing(false)
            router.push(downloadURL)
        }
    }, [loginTokenFetched, downloadClicked])

    const onDownloadClick = async () => {
        setProcessing(true)
        events.trackDownload({ uid: user.id })
        const fbCookies = getFBPCookies()
        const fbEventParams = {
            email: user.email,
            userID: user.id,
            event: 'ClickToDownload',
            userIPData: user.ipData,
            _fbc: fbCookies._fbc,
            _fbp: fbCookies._fbp,
            source: '/survey/download',
        }
        sendFacebookEvent(fbEventParams)
        trackAmplitudeEvent('download_clicked')
        setDownloadClicked(true)
    }
    const subscriptionWithoutTrial = user?.amplitudeVariants['no-trial-sub']?.value === 'no-trial-sub'

    return (
        <div className="max-w-lg mx-auto px-4 flex flex-col items-center">
            <Title>{subscriptionWithoutTrial ? t('survey:download.title') : t('survey:download.title_trial')}</Title>
            <p className="text-center text-xs xs:text-sm md:text-lg xs:leading-tight text-dark3 mt-2 px-2 xs:px-4">
                {t('survey:download.subtitle')}
            </p>
            <div className="flex items-center justify-center flex-col lg:flex-row">
                <img
                    src={assets.fastic_download[`${isIOS ? 'ios' : isAndroid ? 'android' : 'web'}`]}
                    height={569}
                    width={263}
                    className="mt-8 mb-4 lg:my-0 lg:mr-16"
                />
                <div className="max-w-sm flex flex-col mt-2">
                    <DownloadStepItem
                        index={1}
                        title={t(`survey:download.steps.1.title${isIOS ? '_ios' : isAndroid ? '_android' : ''}`)}
                        text={t(`survey:download.steps.1.text${isMobile ? '_mobile' : ''}`)}
                    >
                        <div className="flex justify-center gap-4 mt-4">
                            {!isMobile ? (
                                <>
                                    <div className="flex flex-col items-center justify-center px-6 py-4 bg-light3 rounded-2xl">
                                        <QRCode value={`${window.location.origin}/download?tk=${userJWT}`} size={96} />
                                        <img className="h-8 xs:h-10 md:h-12 mt-2" src="/app_store_badge_en.svg" />
                                    </div>
                                    <div className="flex flex-col items-center justify-center px-6 py-4 bg-light3 rounded-2xl">
                                        <QRCode value={`${window.location.origin}/download?tk=${userJWT}`} size={96} />
                                        <img className="h-8 xs:h-10 md:h-12 mt-2" src="/google_play_badge_en.svg" />
                                    </div>
                                </>
                            ) : (
                                <img
                                    className="h-8 xs:h-10 md:h-12 mt-2 cursor-pointer"
                                    src={`/${isIOS ? 'app_store' : 'google_play'}_badge_en.svg`}
                                    onClick={onDownloadClick}
                                />
                            )}
                        </div>
                    </DownloadStepItem>
                    <DownloadStepItem
                        index={2}
                        title={t(`survey:download.steps.2.title${isMobile ? '_mobile' : ''}`)}
                        text={t(`survey:download.steps.2.text${isMobile ? '_mobile' : ''}`)}
                    >
                        <div className="flex mt-4 relative">
                            <img className="w-full" src="/download_login_bg_image.svg"></img>
                            <div className="bg-light5 text-dark1 font-bold rounded-full py-1 px-3 absolute top-3 right-3">
                                {t('survey:download.sign_in')}
                            </div>
                        </div>
                    </DownloadStepItem>
                    <DownloadStepItem
                        index={3}
                        title={t(`survey:download.steps.3.title${isMobile ? '_mobile' : ''}`)}
                        text={t(`survey:download.steps.3.text${isMobile ? '_mobile' : ''}`)}
                    />
                </div>
            </div>
            <div className="flex flex-col mb-10 mt-4">
                <span className="text-xs text-dark2 text-center">{t('survey:download.address')}</span>
                <span className="text-xs text-dark2 text-center">
                    {t('survey:download.have_a_question')}{' '}
                    <a href="mailto:support@fastic.com" className="text-xs font-bold text-boost2 cursor-pointer">
                        {t('survey:download.here')}.
                    </a>
                </span>
            </div>
        </div>
    )
}

const DownloadStepItem: React.FC<
    React.PropsWithChildren<{
        index: number
        title: string
        text: string
    }>
> = ({ index, title, text, children }) => {
    return (
        <div className="w-full my-4">
            <div className="border border-gray-200 rounded-full flex flex-row items-center p-1.5 w-full">
                <div className="text-white rounded-full bg-boost3 h-8 w-8 flex items-center justify-center font-bold text-sm">
                    {index.toString()}
                </div>
                <div className="text-dark2 font-bold px-3 text-sm">{title}</div>
            </div>
            <div className="mt-2">
                <p className="text-dark3 text-sm leading-6">{text}</p>
                {children && children}
            </div>
        </div>
    )
}
