import React from 'react'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { FakeProgressBar } from '../components/ProgressBar'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'
import { Slider } from '@components/Slider'
import { Title } from '@components/Title'
import useWindowDimensions from '@utils/useWindowDimensions'

type meausrements = { metric: string; imperial: string }

type Testimonial = {
    imageUrl: meausrements
    text: string
    author: string
    weight?: meausrements
    weight2?: meausrements
}

const testimonials: { female: Testimonial[]; male: Testimonial[] } = {
    female: [
        {
            imageUrl: { metric: assets.trust_story_tabitha.metric, imperial: assets.trust_story_tabitha.imperial },
            text: 'survey:trust_review.review.female.review1.text',
            author: 'survey:trust_review.review.female.review1.author',
        },
        {
            imageUrl: { metric: assets.trust_story_henrica.metric, imperial: assets.trust_story_henrica.imperial },
            text: 'survey:trust_review.review.female.review2.text',
            author: 'survey:trust_review.review.female.review2.author',
            weight: {
                metric: '68 kg',
                imperial: '150 lbs',
            },
        },
    ],
    male: [
        {
            imageUrl: { metric: assets.trust_story_hilger.metric, imperial: assets.trust_story_hilger.imperial },
            text: 'survey:trust_review.review.male.review1.text',
            author: 'survey:trust_review.review.male.review1.author',
            weight: {
                metric: '26 kg',
                imperial: '57 lbs',
            },
        },
        {
            imageUrl: { metric: assets.trust_story_johannes.metric, imperial: assets.trust_story_johannes.imperial },
            text: 'survey:trust_review.review.male.review2.text',
            author: 'survey:trust_review.review.male.review2.author',
            weight: {
                metric: '160 kg',
                imperial: '352 lbs',
            },
            weight2: {
                metric: '110 kg',
                imperial: '242 lbs',
            },
        },
    ],
}

const CustomTitle = () => {
    const { t } = useTranslation()
    return (
        <Title>
            <div className="flex items-center justify-center">
                <h2>{t('survey:trust_review.tagline')}..</h2>
                <div className="ml-3">
                    <LoadingSpinner size="sm" />
                </div>
            </div>
        </Title>
    )
}

export const StickingToPlanStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    const slidesWidth = Math.min(480, width - 32)
    const testimonial = testimonials[answers['gender'][0]] as Testimonial[]

    const measurement = 'imperial'

    return (
        <Step key={question.path} name={question.name} title={<CustomTitle />}>
            <div className="mt-6 md:mt-10">
                <FakeProgressBar
                    duration={14}
                    delay={1.25}
                    start={0}
                    onEnd={() => dispatch({ type: 'QUESTION_COMPLETED' })}
                />
                <Slider width={slidesWidth} interval={7000}>
                    {testimonial.map(review => {
                        return (
                            <div
                                key={review.author}
                                className="mt-6 md:mt-10 mx-auto max-w-full w-72 flex flex-col items-center"
                            >
                                <img
                                    className="rounded-t-xl"
                                    src={review.imageUrl[`${measurement}`]}
                                    alt="Picture of the author"
                                    height={350}
                                    width={280}
                                />
                                <div className="flex flex-row bg-white rounded-b-xl p-3 text-sm sm:text-base 3xl:text-lg text-dark3 w-full filter drop-shadow-standard -mt-2 mb-4">
                                    <p>
                                        <b className="text-black">{t(review.author)}: </b>"
                                        {t(review.text, {
                                            weight: review.weight ? review.weight[`${measurement}`] : null,
                                            weight2: review.weight2 ? review?.weight2[`${measurement}`] : null,
                                        })}
                                        "
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </Step>
    )
}
