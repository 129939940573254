import { ApiError } from './api'
import { firebase } from './firebaseClient'
import * as Sentry from '@sentry/node'

type PaypalSubscription = {
    id: string
}

type CreatePaypalSubscriptionResult = {
    error?: ApiError
}

export const createPaypalSubscription = async (params: {
    order: Record<string, unknown>
}): Promise<CreatePaypalSubscriptionResult> => {
    try {
        const idToken = await firebase.auth().currentUser.getIdToken()
        const resp: { data?: PaypalSubscription; error?: ApiError } = await fetch('/api/paypal/createSubscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + idToken,
            },
            body: JSON.stringify({
                order: params.order,
            }),
        })
            .then(r => r.text())
            .then(body => {
                try {
                    return JSON.parse(body)
                } catch (e) {
                    return { error: { type: 'api_error', code: 'unknown', message: body } }
                }
            })
        if (resp.error) {
            Sentry.captureException(`PayPal createPaypalSubscription Error: ${JSON.stringify(resp.error)}`)
            return { error: resp.error }
        }
        return {}
    } catch (e: unknown) {
        Sentry.captureException(e)
        return {
            error: {
                type: 'api_error',
                code: 'unknown',
                message: e as string,
            },
        }
    }
}
