import React from 'react'
import { useTranslation } from 'i18n'
import { PaymentProduct } from '@services/payment_product'
import { CheckIcon } from '@components/CheckIcon'

const PaymentBeneftis: React.FC<{ product: PaymentProduct; subscriptionWithoutTrial: boolean }> = ({
    product,
    subscriptionWithoutTrial,
}) => {
    const { t } = useTranslation()
    const currency = t(`common:currency.${product.currency}`)

    return (
        <div className="mt-4 md:mt-6 pl-4 flex flex-col">
            {!subscriptionWithoutTrial && (
                <div className="relative mb-3">
                    <div className="absolute top-0 left-0 text-green-600">
                        <CheckIcon className="w-5 h-5" />
                    </div>
                    <div className="pl-7 text-gray-500 font-bold leading-tight text-xs md:text-base">
                        {t('survey:program.control.7_day_trial', {
                            trialPeriod: product.trialPeriodDays,
                            price: product.trialPrice.toFixed(2),
                            currency,
                        })}
                    </div>
                </div>
            )}
            <div className="relative mb-3">
                <div className="absolute top-0 left-0 text-green-600">
                    <CheckIcon className="w-5 h-5" />
                </div>
                <div className="pl-7 text-gray-500 font-bold leading-tight text-xs md:text-base">
                    {t('survey:program.control.email_receipt')}
                </div>
            </div>
        </div>
    )
}

export default PaymentBeneftis
