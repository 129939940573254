import React, { useState } from 'react'
import Head from 'next/head'
import cuid from 'cuid'
import { Button } from '../components/Button'
import { Step } from '../components/Step'
import { TextInput } from '../components/TextInput'
import { useUser } from '../contexts/auth'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { trackAmplitudeEvent } from '../services/client/amplitude'
import { updateUser, UpdateUser } from '../services/client/api'
import * as events from '../services/client/events'
import { validateEmail, validatePassword, validateName } from './validation'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'

export const RegistrationStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()
    const user = useUser()
    const [processing, setProcessing] = useState(false)

    const imgProps = {
        src:
            'https://images.ctfassets.net/i6d954lr9wft/2trb1gEr2wwDHh0qrsfszw/e50ffb4bc972c364c3887967a4a2fd49/thumbs.svg',
        alt: t('account:thumbs_up'),
        height: 108,
        width: 121,
    }

    const [form, setForm] = useState<{
        name?: string
        nameError?: string
        email?: string
        emailError?: string
        password?: string
        passwordError?: string
    }>({
        name: user.name,
        email: user.email,
    })

    const onNameChange = (value: string) => {
        setForm({
            ...form,
            name: value.trim(),
            nameError: null,
        })
    }

    const onEmailChange = (value: string) => {
        setForm({
            ...form,
            email: value.trim(),
            emailError: null,
        })
    }

    const onPasswordChange = (value: string) => {
        setForm({
            ...form,
            password: value.trim(),
            passwordError: null,
        })
    }

    const onContinue = async () => {
        setProcessing(true)
        const [nameError, emailError, passwordError] = [
            validateName(form.name),
            user.loginViaProvider ? null : validateEmail(form.email),
            user.loginViaProvider ? null : validatePassword(form.password),
        ]

        if (nameError || emailError || passwordError) {
            setForm({
                ...form,
                nameError,
                emailError,
                passwordError,
            })
            setProcessing(false)
        } else {
            let updateData: UpdateUser = {
                email: form.email,
                name: form.name,
                survey: { answers: answers },
            }
            if (!user.loginViaProvider) {
                updateData = { ...updateData, password: form.password }
            }
            const result = await updateUser(updateData)
            setProcessing(false)
            if (result.error) {
                setForm({
                    ...form,
                    emailError: result.error.message,
                })
            } else {
                events.trackCompleteRegistration({ uid: user.id })
                const fbCookies = getFBPCookies()
                const fbEventParams = {
                    email: user.email,
                    userID: user.id,
                    event: 'ClickToDownload',
                    userIPData: user.ipData,
                    _fbc: fbCookies._fbc,
                    _fbp: fbCookies._fbp,
                    source: '/survey/registration',
                }
                await sendFacebookEvent(fbEventParams)
                trackAmplitudeEvent('registration_completed')
                dispatch({ type: 'QUESTION_COMPLETED' })
            }
        }
    }

    return (
        <>
            <div className="flex justify-center mb-8">
                <img src={imgProps.src} alt={imgProps.alt} height={imgProps.height} width={imgProps.width} />
            </div>
            <Step key={question.path} name={question.name}>
                <TextInput
                    type="text"
                    label={t('survey:registration.your_name')}
                    value={form.name}
                    onChange={onNameChange}
                    error={form.nameError && t(form.nameError)}
                    disabled={processing}
                />
                {!user.loginViaProvider && (
                    <TextInput
                        type="email"
                        label={t('survey:signup.your_email')}
                        value={form.email}
                        error={form.emailError && t(form.emailError)}
                        onChange={onEmailChange}
                        disabled={processing}
                    />
                )}
                {!user.loginViaProvider && (
                    <TextInput
                        type="password"
                        label={t('survey:registration.your_password')}
                        value={form.password}
                        error={form.passwordError && t(form.passwordError)}
                        onChange={onPasswordChange}
                        disabled={processing}
                    />
                )}
                <div className="text-gray-500 text-xxs leading-snug md:text-xs w-full md:leading-normal text-center mt-2 mb-4 md:mt-4 px-4">
                    <p
                        className="mt-4"
                        dangerouslySetInnerHTML={{
                            __html: t('survey:registration.confirm_text', {
                                tos_url: 'https://fastic.com/terms/',
                                privacy_url: 'https://fastic.com/privacy-policy/',
                            }),
                        }}
                    ></p>
                </div>
                <div className="pb-12">
                    <Button
                        processing={processing}
                        primary
                        label={t('common:controls.next')}
                        className="w-full"
                        onClick={onContinue}
                    />
                </div>
            </Step>
        </>
    )
}
