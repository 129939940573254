import React from 'react'
import { CheckIcon } from '@components/CheckIcon'

const Checkmark: React.FC<React.PropsWithChildren<unknown>> = props => {
    return (
        <div className="flex items-center text-xxs leading-tight md:text-base">
            <div className="rounded-full block justify-center items-center font-bold mr-2 p-0.5 bg-green-600 text-white">
                <CheckIcon className="w-3 h-3" />
            </div>
            {props.children}
        </div>
    )
}

export default Checkmark
