import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'

export const WaterStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()

    const glasses: number = !isNaN(Number(answers[question.name])) ? (answers[question.name] as number) : -1
    return (
        <Step key={question.path} name={question.name}>
            <div className="grid grid-cols-3 sm:grid-cols-5 grid-flow-row gap-2 mt-12 place-items-center">
                {[...Array(10)].map((_, i) => (
                    <WaterGlass
                        key={`water_glass_${i}`}
                        active={i + 1 <= glasses}
                        style={{ animationDelay: `${i * 60}ms` }}
                        onClick={() => dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: i + 1 })}
                    />
                ))}
            </div>
        </Step>
    )
}

const WaterGlass: React.FC<{
    active?: boolean
    style?: CSSProperties
    onClick?: () => void
}> = ({ active = false, ...props }) => {
    const waterGlassClasses = classNames(
        'transition duration-300 fill-current',
        active ? 'text-pace3' : 'text-dark5 hover:text-dark-4',
    )

    return (
        <div className="w-14 h-14 mx-4.5 mb-4 flex relative cursor-pointer animate-fade-in" {...props}>
            <svg
                width="56"
                height="56"
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full"
            >
                <path
                    className={waterGlassClasses}
                    d="M41.4633 7.00002H14.5367C13.8781 6.99603 13.2262 7.13145 12.6238 7.39739C12.0213 7.66334 11.482 8.05377 11.0412 8.54304C10.6004 9.03231 10.2682 9.60934 10.0663 10.2362C9.86445 10.863 9.79755 11.5255 9.87 12.18L13.3 42.77C13.4903 44.4901 14.311 46.0786 15.6037 47.2291C16.8965 48.3796 18.5695 49.0105 20.3 49H35.7467C37.4772 49.0105 39.1502 48.3796 40.4429 47.2291C41.7356 46.0786 42.5563 44.4901 42.7467 42.77L46.0833 12.18C46.1553 11.5294 46.0897 10.8709 45.8906 10.2473C45.6915 9.62371 45.3634 9.04898 44.9277 8.56045C44.492 8.07193 43.9584 7.68053 43.3615 7.41169C42.7647 7.14286 42.1179 7.0026 41.4633 7.00002ZM41.4633 11.6667L40.3667 21.4667C38.4067 20.2534 34.4633 19.1334 28 22.7734C22.05 26.11 18.06 25.1067 15.9367 24.08L14.5367 11.6667H41.4633Z"
                />
            </svg>
        </div>
    )
}
