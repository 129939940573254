import React from 'react'
import { useTranslation } from 'i18n'

const Moneyback: React.FC<{ testVariant: string }> = ({ testVariant }) => {
    const { t } = useTranslation()

    if (testVariant === 'control') {
        return (
            <div className="mt-4 md:mt-8 p-5 bg-gray-100 rounded-xl">
                <div className="text-left text-base xs:text-lg md:text-xl font-bold text-gray-800 mb-2">
                    {t(`survey:program.${testVariant}.moneyback.title`)}
                </div>
                <div className="text-gray-600 text-sm md:text-base">
                    <p>{t(`survey:program.${testVariant}.moneyback.paragraph_1`)}</p>
                    <div className="mt-4 flex justify-between items-center">
                        <p>
                            {t(`survey:program.${testVariant}.moneyback.paragraph_2`)}
                            <a className="text-green-600 hover:underline" href="https://fastic.com/guarantee/">
                                {t(`survey:program.${testVariant}.moneyback.link`)}
                            </a>
                        </p>
                        <img className="ml-4 max-w-20 md:max-w-24 object-contain" src="/moneyback.png" />
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex flex-col my-16">
                <h3 className="font-bold text-dark1 text-center text-2xl">
                    {t(`survey:program.${testVariant}.moneyback.title`)}
                </h3>
                <div className="text-dark2 flex flex-col px-4 pt-4 pb-10 mt-4 bg-boost6 rounded-xl">
                    <p>{t(`survey:program.${testVariant}.moneyback.paragraph_1`)}</p>
                    <div className="mt-12 w-full flex justify-between items-center">
                        <p>
                            {t(`survey:program.${testVariant}.moneyback.paragraph_2`)}{' '}
                            <a className="text-boost2 hover:underline" href="https://fastic.com/guarantee/">
                                {t(`survey:program.${testVariant}.moneyback.link`)}
                            </a>
                        </p>
                        <img className="ml-4 max-w-20 md:max-w-24 object-contain" src="/moneyback.png" />
                    </div>
                </div>
            </div>
        )
    }
}

export default Moneyback
