import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@components/Button'
import { Modal } from '@components/Modal'
import { Title } from '@components/Title'
import { useQuestion } from '@contexts/survey'
import { convertKgToLbs } from '@utils/convert'
import { useTranslation } from 'i18n'
import * as events from '@services/client/events'
import { trackAmplitudeEvent } from '@services/client/amplitude'
import { useUser } from '@contexts/auth'
import {
    fallbackCurrency,
    getPaymentProduct,
    getProductTrackingParams,
    Store,
    supportedCurrencies,
} from '@services/payment_product'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { updateUser } from '@services/client/api'
import TrialCountdown from '@components/POS/TrialCountdown'
import Moneyback from '@components/POS/Moneyback'
import Reviews from '@components/POS/Reviews'
import PriceBreakdown from '@components/POS/PriceBreakdown/PriceBreakdown'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'
import CheckoutForm from '@components/POS/CheckoutForm'
import JourneyChart from '@components/POS/JourneyChart'
import TimerBanner from '@components/POS/TimerBanner'
import Checkmark from '@components/POS/Checkmark'
import { PromoProps } from '@pages/gift'

const paypalLocales = {
    en: 'en_US',
    de: 'de_DE',
    es: 'es_ES',
    fr: 'fr_FR',
    it: 'it_IT',
    pt: 'pt_PT',
}

export const PlanReadyControl: React.FC<{ subscriptionWithoutTrial: boolean; promo?: PromoProps }> = ({
    subscriptionWithoutTrial,
    promo,
}) => {
    const [, answers, dispatch] = useQuestion()
    const { t, i18n } = useTranslation()
    const user = useUser()
    const [showAreYouReadyModal, setShowAreYouReadyModal] = useState(false)

    const [countdownEnd, setCountdownEnd] = useState(
        Number(localStorage.getItem('countdown_end')) || Date.now() + 60000 * 15,
    )
    useEffect(() => {
        localStorage.setItem('countdown_end', countdownEnd.toString())
        if (countdownEnd < Date.now()) {
            setCountdownEnd(Date.now() + 60000 * 15)
        }
    }, [countdownEnd])

    const requestedCurrency = (answers['currency'] as string) ?? 'USD'
    const currency = supportedCurrencies.includes(requestedCurrency) ? requestedCurrency : fallbackCurrency

    const weightUnit = answers['measurement_system'] === 'metric' ? t('common:kg') : t('common:lbs')
    const weight =
        answers['measurement_system'] === 'metric'
            ? (answers['weight'] as number)
            : convertKgToLbs(answers['weight'] as number)
    const targetWeight =
        answers['measurement_system'] === 'metric'
            ? (answers['target_weight'] as number)
            : convertKgToLbs(answers['target_weight'] as number)
    const weightToLoose = weight - targetWeight

    const paymentProduct = getPaymentProduct({
        currency,
        trialPrice: subscriptionWithoutTrial ? null : (answers['trial_price'] as number) ?? 1,
        weightLoss: !promo && weightToLoose,
        isActive: true,
        interval: promo && promo.intervalCount,
    })

    if (promo) {
        paymentProduct.productPrice = promo.price
    }

    const fbCookies = getFBPCookies()
    const fbEventParams = {
        email: user.email,
        userID: user.id,
        userIPData: user.ipData,
        _fbc: fbCookies._fbc,
        _fbp: fbCookies._fbp,
        source: '/survey/your_program',
        price: subscriptionWithoutTrial
            ? paymentProduct.productPrice
            : paymentProduct.productPrice + paymentProduct.trialPrice,
    }

    const scrollRef = useRef(null)

    const onStartPlanClick = () => {
        events.trackAmplitudeAndGTAGEvent('start_plan_clicked')
        if (user.subscription.status !== 'active') {
            // setShowAreYouReadyModal(true)
            onReadyClick()
        } else {
            onReadyClick()
            //dispatch({ type: 'QUESTION_COMPLETED' })
        }
    }
    const onReadyClick = async () => {
        setShowAreYouReadyModal(false)
        await sendFacebookEvent({ ...fbEventParams, event: 'InitiateCheckout' })
        events.trackInitiateCheckout({ uid: user.id })
        trackAmplitudeEvent('checkout_opened')
        window.scrollTo({
            top: scrollRef.current.offsetTop,
            behavior: 'smooth',
        })
    }
    const onCheckoutCompleted = async (store: Store) => {
        const lastPlusPurchase = {
            store,
            currency: paymentProduct.currency,
            currencySymbol: t(`common:currency.${paymentProduct.currency}`),
            withTrial: !subscriptionWithoutTrial,
            trialPeriodDays: subscriptionWithoutTrial ? 0 : paymentProduct.trialPeriodDays,
            trialPrice: subscriptionWithoutTrial ? 0 : paymentProduct.trialPrice,
            productId: store === 'paypal' ? paymentProduct.paypalPlan : paymentProduct.stripeProduct,
            renewalPrice: paymentProduct.productPrice,
            renewalPeriodMonths: paymentProduct.productIntervalCount,
        }
        await updateUser({
            lastPlusPurchase,
        })
        await sendFacebookEvent({ ...fbEventParams, event: 'Payment' })
        !subscriptionWithoutTrial && events.trackStartTrial(user.id, getProductTrackingParams(store, paymentProduct))
        trackAmplitudeEvent('purchase_succeeded', getProductTrackingParams(store, paymentProduct))
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    const posTestVariant = user.amplitudeVariants['pos-ui-optimisation']?.value ?? 'control'

    return (
        <div>
            <PayPalScriptProvider
                options={{
                    'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
                    currency: currency,
                    vault: true,
                    intent: 'subscription',
                    locale: paypalLocales[i18n.language],
                }}
            >
                <div className="max-w-lg mx-auto px-4 mt-3">
                    {user.subscription.status !== 'active' && !subscriptionWithoutTrial && (
                        <TimerBanner countdownEnd={countdownEnd} product={paymentProduct} />
                    )}
                    <div className="mb-4 md:mb-7">
                        <h2 className="text-left text-base xs:text-lg md:text-2xl font-black text-gray-800 mb-2">
                            {t('survey:program.control.title', { name: user.name ?? '' })}
                        </h2>
                        <p
                            className="text-xs xs:text-sm md:text-base text-gray-500"
                            dangerouslySetInnerHTML={{ __html: t('survey:program.control.subtitle') }}
                        ></p>
                    </div>
                    {user.subscription.status !== 'active' && !subscriptionWithoutTrial && (
                        <TrialCountdown countdownEnd={countdownEnd} testVariant={posTestVariant} />
                    )}

                    <Button
                        primary
                        label={t('common:controls.start_my_plan')}
                        className="w-full mt-4 mb-8"
                        onClick={onStartPlanClick}
                    />

                    <div className="shadow-box rounded-xl mb-4 overflow-hidden bg-white">
                        <div className="text-center text-base xs:text-lg md:text-2xl font-black text-gray-800 mb-2 pt-4">
                            {t('survey:program.control.chart.title')}
                        </div>
                        <div
                            className="text-center text-xs xs:text-sm md:text-lg text-gray-500 px-14"
                            dangerouslySetInnerHTML={{
                                __html: t('survey:program.control.chart.subtitle', {
                                    weight: weightToLoose,
                                    metric: weightUnit,
                                }),
                            }}
                        ></div>
                        <div className="w-11/12 mx-auto my-4">
                            <JourneyChart weight={weight} targetWeight={targetWeight} weightUnit={weightUnit} />
                        </div>
                        <div className="grid grid-cols-2 gap-y-2 gap-x-1 md:gap-x-6 bg-gray-50 text-gray-600 px-3 md:px-6 py-4">
                            <Checkmark>
                                {t('survey:program.control.benefits.options.reach_target', {
                                    weight: targetWeight,
                                    metric: weightUnit,
                                })}
                            </Checkmark>
                            <Checkmark>{t('survey:program.control.benefits.options.support_reached_goal')}</Checkmark>
                            <Checkmark>
                                {t(`survey:program.control.chart.options.body.${answers['body_type'][0]}`)}
                            </Checkmark>
                            <Checkmark>
                                {t(`survey:program.control.chart.options.work.${answers['work'][0]}`)}
                            </Checkmark>
                            <Checkmark>
                                {t(`survey:program.control.chart.options.diet.${answers['diet'][0]}`)}
                            </Checkmark>
                            <Checkmark>
                                {t('survey:program.control.benefits.options.motivating_personal_insights')}
                            </Checkmark>
                        </div>
                    </div>

                    <div ref={scrollRef}>
                        <PriceBreakdown
                            recommendedPlan={paymentProduct}
                            subscriptionWithoutTrial={subscriptionWithoutTrial}
                            promo={promo}
                        />
                    </div>

                    <CheckoutForm
                        product={paymentProduct}
                        onSuccess={onCheckoutCompleted}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />

                    <Moneyback testVariant={posTestVariant} />
                    <Reviews testVariant={posTestVariant} />

                    <Button
                        primary
                        label={t('common:controls.start_my_plan')}
                        className="w-full mt-8 mb-12"
                        onClick={onStartPlanClick}
                    />

                    {showAreYouReadyModal && (
                        <Modal>
                            <Title>{t('survey:program.control.trial_modal.title')}</Title>
                            <div className="mt-3 mb-6 text-center text-gray-600">
                                <p className="mb-3">{t('survey:program.control.trial_modal.paragraph_1')}</p>
                                <p>{t('survey:program.control.trial_modal.paragraph_2')}</p>
                            </div>
                            <Button
                                primary
                                label={t('common:controls.im_in')}
                                onClick={onReadyClick}
                                className="w-full"
                            />
                        </Modal>
                    )}
                </div>
            </PayPalScriptProvider>
        </div>
    )
}
