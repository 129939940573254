import React from 'react'
import { useCountUp } from 'react-countup'

export const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
    return (
        <div className="h-6 xs:h-8 md:h-9 mt-4 rounded-36px bg-light3 relative overflow-hidden text-white">
            <div style={{ width: `${progress}%` }} className="bg-boost2 w-0 h-full flex items-center justify-center">
                {progress.toFixed(0)}%
            </div>
            <div className="absolute left-1/2 top-1/2 font-bold text-base md:text-lg transform -translate-x-1/2 -translate-y-1/2"></div>
        </div>
    )
}

export const FakeProgressBar: React.FC<{
    duration: number
    onEnd?: () => void
    delay?: number
    start?: number
    end?: number
}> = ({ duration = 5, onEnd, delay, start = 10, end = 100 }) => {
    const { countUp } = useCountUp({
        start,
        end,
        duration,
        onEnd,
        delay,
    })

    return <ProgressBar progress={Number(countUp.toString())} />
}
