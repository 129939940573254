import React, { ReactElement } from 'react'
import { useTranslation } from 'i18n'
import { calculateWeeklyPrice, PaymentProduct } from '@services/payment_product'
import { PromoProps } from '@pages/gift'

const PriceBreakdownControl: React.FC<{
    recommendedPlan: PaymentProduct
    subscriptionWithoutTrial: boolean
    promo?: PromoProps
}> = ({ recommendedPlan, subscriptionWithoutTrial, promo }): ReactElement => {
    const { t } = useTranslation()
    const currency = t(`common:currency.${recommendedPlan.currency}`)
    return (
        <div className="my-8">
            {!subscriptionWithoutTrial && (
                <div className="py-4 md:py-6 text-xs xs:text-sm md:text-base">
                    <div className="mb-2 text-base md:text-lg font-bold uppercase">
                        {t('survey:program.control.form.price_today')}
                    </div>
                    <div className="grid grid-cols-1 gap-4 md:gap-6 border-t border-b border-gray-300 py-4 md:py-6 mb-4">
                        <div className="flex justify-between">
                            <div>
                                {t('survey:program.control.form.day_trial', {
                                    trialPeriod: recommendedPlan.trialPeriodDays,
                                })}
                            </div>
                            <div className="text-right font-bold">
                                {t('common:price', { price: recommendedPlan.trialPrice.toFixed(2), currency })}
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-gray-500">
                        {t('survey:program.control.form.trial_results_text', {
                            trialPeriod: recommendedPlan.trialPeriodDays,
                        })}
                    </div>
                </div>
            )}
            <div className="py-4 md:py-6 text-xs xs:text-sm md:text-base">
                <div className="mb-2 text-base md:text-lg font-bold uppercase">
                    {t(
                        subscriptionWithoutTrial
                            ? 'survey:program.control.form.price_today'
                            : 'survey:program.control.form.price_after_trial.label',
                    )}
                </div>
                <div className="grid grid-cols-1 gap-4 md:gap-6 border-t border-b border-gray-300 py-4 md:py-6 mb-4">
                    <div className="flex justify-between">
                        <div>
                            <b className="block mb-2">
                                {t('survey:program.control.form.price_after_trial.month_plan', {
                                    interval_count: recommendedPlan.productIntervalCount,
                                })}
                            </b>
                            {!promo && (
                                <span className="bg-green-300 rounded-full text-center px-4 py-1 uppercase text-sm">
                                    {t('survey:program.control.form.price_after_trial.off_percentage', {
                                        discount: 37,
                                    })}
                                </span>
                            )}
                        </div>
                        <div className="text-right">
                            <b>
                                {t('survey:program.control.form.price_after_trial.week', {
                                    price: calculateWeeklyPrice(recommendedPlan).toFixed(2),
                                    currency,
                                })}
                            </b>
                            <span className="block text-sm text-gray-500 mt-2">
                                {t('survey:program.control.form.price_after_trial.billed_at', {
                                    price: recommendedPlan.productPrice.toFixed(2),
                                    currency,
                                })}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceBreakdownControl
