import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'i18n'
import { useQuestion } from '@contexts/survey'
import { Step } from '@components/Step'
import Badge from '@components/Badge'
import assets from './assets'
import { useUser } from '@contexts/auth'

const BreakdownSvgs = {
    flag: (
        <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.02634 4.41317C7.93334 4.29547 7.88274 4.14985 7.88274 3.99984C7.88274 3.84983 7.93334 3.7042 8.02634 3.5865L9.49301 1.7465C9.56928 1.65023 9.61747 1.53476 9.63226 1.41283C9.64705 1.2909 9.62788 1.16725 9.57685 1.05553C9.52581 0.943814 9.44491 0.848364 9.34306 0.779715C9.24121 0.711067 9.12238 0.671889 8.99967 0.666504H2.33301C1.80257 0.666504 1.29387 0.877218 0.918794 1.25229C0.543722 1.62736 0.333008 2.13607 0.333008 2.6665V11.3332C0.333008 11.51 0.403246 11.6796 0.52827 11.8046C0.653294 11.9296 0.822863 11.9998 0.999674 11.9998C1.17649 11.9998 1.34605 11.9296 1.47108 11.8046C1.5961 11.6796 1.66634 11.51 1.66634 11.3332V7.33317H8.99967C9.12465 7.3326 9.24695 7.2969 9.35262 7.23016C9.45828 7.16342 9.54306 7.06833 9.59728 6.95572C9.6515 6.84312 9.67297 6.71754 9.65925 6.59331C9.64553 6.46909 9.59716 6.35123 9.51968 6.25317L8.02634 4.41317Z"
                fill="white"
            />
        </svg>
    ),
    bell: (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.2599 8.68016L10.3999 7.5335C10.1403 7.1873 9.99992 6.76624 9.99992 6.3335V5.66683C9.99896 4.72225 9.66376 3.80848 9.05366 3.08735C8.44357 2.36622 7.59796 1.88426 6.66659 1.72683V1.00016C6.66659 0.823352 6.59635 0.653782 6.47133 0.528758C6.3463 0.403734 6.17673 0.333496 5.99992 0.333496C5.82311 0.333496 5.65354 0.403734 5.52852 0.528758C5.40349 0.653782 5.33325 0.823352 5.33325 1.00016V1.72683C4.40188 1.88426 3.55627 2.36622 2.94618 3.08735C2.33609 3.80848 2.00088 4.72225 1.99992 5.66683V6.3335C1.99992 6.76624 1.85957 7.1873 1.59992 7.5335L0.739921 8.68016C0.6456 8.80289 0.581263 8.94595 0.552042 9.09795C0.522821 9.24995 0.529525 9.40668 0.571619 9.55562C0.613713 9.70457 0.690033 9.84162 0.79449 9.95584C0.898946 10.0701 1.02865 10.1583 1.17325 10.2135C2.7218 10.7636 4.35683 11.0301 5.99992 11.0002C7.64301 11.0301 9.27805 10.7636 10.8266 10.2135C10.9712 10.1583 11.1009 10.0701 11.2054 9.95584C11.3098 9.84162 11.3861 9.70457 11.4282 9.55562C11.4703 9.40668 11.477 9.24995 11.4478 9.09795C11.4186 8.94595 11.3542 8.80289 11.2599 8.68016Z"
                fill="white"
            />
        </svg>
    ),
    star: (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.96661 1.25995L9.08661 3.50662C9.16554 3.6622 9.28055 3.79664 9.42205 3.8987C9.56354 4.00076 9.7274 4.06748 9.89994 4.09328L12.3866 4.44662C12.5871 4.47473 12.7756 4.55862 12.9307 4.68872C13.0858 4.81882 13.2012 4.9899 13.2638 5.18242C13.3263 5.37495 13.3335 5.58119 13.2845 5.7776C13.2355 5.97402 13.1323 6.15271 12.9866 6.29328L11.1999 8.00662C11.0687 8.13271 10.9713 8.28974 10.9165 8.4633C10.8618 8.63686 10.8515 8.82139 10.8866 8.99995L11.3133 11.4333C11.3482 11.6316 11.3271 11.8357 11.2521 12.0226C11.1772 12.2095 11.0516 12.3717 10.8893 12.491C10.7271 12.6103 10.5347 12.6818 10.334 12.6975C10.1332 12.7132 9.9321 12.6725 9.75328 12.58L7.49328 11.4066C7.3408 11.3277 7.17163 11.2865 6.99994 11.2865C6.82826 11.2865 6.65908 11.3277 6.50661 11.4066L4.24661 12.5466C4.06778 12.6392 3.86665 12.6799 3.6659 12.6642C3.46516 12.6484 3.27281 12.5769 3.11057 12.4577C2.94833 12.3384 2.82265 12.1762 2.74774 11.9893C2.67282 11.8024 2.65165 11.5982 2.68661 11.4L3.11328 8.99995C3.14265 8.82652 3.12948 8.64856 3.0749 8.48134C3.02032 8.31413 2.92597 8.16266 2.79994 8.03995L0.999944 6.29328C0.854277 6.15271 0.751053 5.97402 0.702046 5.7776C0.653039 5.58119 0.660223 5.37495 0.722778 5.18242C0.785333 4.9899 0.90074 4.81882 1.05583 4.68872C1.21093 4.55862 1.39947 4.47473 1.59994 4.44662L4.08661 4.09328C4.25915 4.06748 4.42301 4.00076 4.56451 3.8987C4.706 3.79664 4.82102 3.6622 4.89994 3.50662L6.01994 1.25995C6.10809 1.07663 6.24621 0.921935 6.4184 0.813663C6.5906 0.705392 6.78987 0.647949 6.99328 0.647949C7.19668 0.647949 7.39596 0.705392 7.56815 0.813663C7.74035 0.921935 7.87847 1.07663 7.96661 1.25995Z"
                fill="white"
            />
        </svg>
    ),
}

const TrialBreakdownStep: React.FC = (): ReactElement => {
    const [question] = useQuestion()
    const { t } = useTranslation()
    const user = useUser()

    return (
        <Step key={question.path} name={question.name}>
            <div className="flex justify-center">
                <img src={assets.trial_breakdown} height={164} width={164} className="my-10 hidden md:block" />
            </div>
            <div className="flex my-4 xs:mt-10 md:my-0">
                <div className="flex flex-col items-center">
                    <Badge icon={BreakdownSvgs.flag} />
                    <div className="w-3 h-20 bg-boost3 -mt-1" />
                    <Badge icon={BreakdownSvgs.bell} />
                    <div className="w-3 h-20 bg-boost3 -mt-1" />
                    <Badge icon={BreakdownSvgs.star} />
                    <div className="w-3 h-14 bg-gradient-to-b from-star to-star-light -mt-1 rounded-b-full" />
                </div>
                <div className="flex flex-col ml-3">
                    <div className="flex flex-col h-28">
                        <span className="font-bold text-dark1">{t('survey:trial_breakdown.today.label')}</span>
                        <p className="text-sm text-dark3 mt-0.5">{t('survey:trial_breakdown.today.description')}</p>
                    </div>
                    <div className="flex flex-col h-28">
                        <span className="font-bold text-dark1">{t('survey:trial_breakdown.12_days.label')}</span>
                        <p className="text-sm text-dark3 mt-0.5">{t('survey:trial_breakdown.12_days.description')}</p>
                    </div>
                    <div className="flex flex-col">
                        <span className="font-bold text-dark1">{t('survey:trial_breakdown.14_days.label')}</span>
                        <p className="text-sm text-dark3 mt-0.5">{t('survey:trial_breakdown.14_days.description')}</p>
                    </div>
                </div>
            </div>
        </Step>
    )
}

export default TrialBreakdownStep
