import { getTrialPrices } from '@services/payment_product'
import classNames from 'classnames'
import React, { CSSProperties, useEffect, useState } from 'react'
import { Button } from '../components/Button'
import { CheckIcon } from '../components/CheckIcon'
import { Step } from '../components/Step'
import { useUser } from '../contexts/auth'
import { useQuestion, useSurvey } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { updateUser } from '../services/client/api'

export const TrialPriceStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const [, surveyDispatch] = useSurvey()
    const { t } = useTranslation()
    const user = useUser()
    const [processing, setProcessing] = useState(false)

    const currency = (answers['currency'] as string) ?? 'USD'
    const currencySymbol = t(`common:currency.${currency.toLowerCase()}`)
    const trialPrices = getTrialPrices(currency)

    const priceOptions: number[] = trialPrices.map(price => price.amount)
    priceOptions.sort((a, b) => (a > b ? 1 : -1))
    const maxPrice = priceOptions[priceOptions.length - 1]
    const selected = (answers[question.name] as number) ?? maxPrice

    useEffect(() => {
        dispatch({
            type: 'QUESTION_ANSWER_CHANGED',
            name: question.name,
            value: (answers[question.name] as number) ?? maxPrice,
        })
    }, [])

    const handleOptionChange = async (price: number) => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: price })
    }

    const onContinue = async () => {
        setProcessing(true)
        await updateUser({
            email: user.email,
            name: user.name,
            survey: { answers: answers },
        })
        setProcessing(false)
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    const employeeCostPrice = t('common:price', { price: maxPrice, currency: currencySymbol })

    return (
        <Step key={question.path} name={question.name}>
            <div className="flex flex-col justify-center items-center w-full">
                <span className="text-warn text-center text-sm sm:text-base">
                    {t('survey:trial_price.employees_costs', {
                        price: employeeCostPrice,
                    })}
                </span>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 place-items-center place-content-center mt-6">
                    {priceOptions.map((price, i) => (
                        <PriceOption
                            key={`price_${price}`}
                            label={t('common:price', { price: price, currency: currencySymbol })}
                            checked={selected === price}
                            onClick={() => handleOptionChange(price)}
                            className="animate-fade-in"
                            popular={i === trialPrices.length - 1}
                            style={{ animationDelay: `${i * 75}ms` }}
                        />
                    ))}
                </div>
                <div className="w-80 mt-6 xs:mt-10 mb-3 px-4">
                    <Button
                        primary
                        label={t('common:controls.see_my_plan')}
                        className="w-full"
                        disabled={selected === 0}
                        processing={processing}
                        onClick={onContinue}
                    />
                </div>
            </div>
        </Step>
    )
}

interface PriceOptionProps {
    checked?: boolean
    label: string
    className?: string
    style?: CSSProperties
    popular?: boolean
    onClick?: () => void
}

const PriceOption: React.FC<PriceOptionProps> = ({ checked = false, popular = false, label, className, ...props }) => {
    const { t } = useTranslation()

    const styles = classNames(
        'w-28 flex flex-col align-top text-center py-4 px-1 md:px-4 rounded-xl cursor-pointer transition-colors duration-300 ease-in-out shadow-standard',
        checked && 'border-2 bg-boost6 border-boost2',
        className,
    )

    const checkClassName = classNames(
        'rounded-full flex justify-center items-center font-bold text-xs mx-auto mt-auto p-0.5 text-white transition-colors duration-300 w-5 md:w-7 h-5 md:h-7',
        checked ? 'bg-boost2' : 'border-2 border-checkboxborder',
    )

    return (
        <div className="flex flex-col items-center justify-around">
            <div className={styles} {...props}>
                <span className="text-dark2 text-sm text-center mb-2">{label}</span>
                <div className={checkClassName}>{checked && <CheckIcon className="w-5 md:w-7 h-5 md:h-7" />}</div>
            </div>
            <div className="mt-2 h-0 sm:h-8 w-full flex items-center justify-center">
                {popular && <span className=" text-dark3 text-sm">{t('survey:trial_price.popular_choice')}</span>}
            </div>
        </div>
    )
}
