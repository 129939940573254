import React from 'react'
import { useTranslation } from 'i18n'
import { AppleLogo } from '@components/AppleLogo'
import { StarIcon } from '@components/StarIcon'

const AppStoreRating: React.FC = () => {
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center mt-6">
            <div className="flex">
                <div className="flex flex-col justify-center items-center mx-3">
                    <img className="w-8 md:w-11" src="/android.svg" />
                    <span className="mt-1 md:mt-1.5 text-sm md:text-base text-gray-500">Google play</span>
                </div>
                <div className="flex flex-col justify-center items-center mx-3">
                    <AppleLogo className="h-8 md:h-12" />
                    <span className="mt-2 text-sm md:text-base  text-gray-500">Apple Store</span>
                </div>
            </div>
            <div className="flex items-center mt-2.5">
                <div className="flex text-yellow-400 mr-3">
                    <StarIcon className="h-4 md:h-7" />
                    <StarIcon className="h-4 md:h-7" />
                    <StarIcon className="h-4 md:h-7" />
                    <StarIcon className="h-4 md:h-7" />
                    <StarIcon className="h-4 md:h-7" />
                </div>
                <div className="bg-green-100 rounded-lg px-2.5 py-1">
                    <b className="text-green-600 text-xs md:text-base">4.8</b>
                    <span className="text-green-600 opacity-60 text-xs md:text-base">
                        {' '}
                        {t('survey:program.control.form.rate')}
                    </span>
                </div>
            </div>
            <div className="flex items-center mt-3">
                <div className="px-3 border-r-2 border-gray-200 text-center leading-tight">
                    <b className="font-black text-sm md:text-lg block md:inline">10M+</b>
                    <span className="text-gray-500 text-xs md:text-base">
                        {' '}
                        {t('survey:program.control.form.downloads')}
                    </span>
                </div>
                <div className="px-3 border-r-1 border-gray-500 text-center leading-tight">
                    <b className="font-black text-sm md:text-lg block md:inline">99K</b>
                    <span className="text-gray-500 text-xs md:text-base">
                        {' '}
                        {t('survey:program.control.form.5star')}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AppStoreRating
