import React from 'react'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'

export const TypicalLunchStepTitle: React.FC = () => {
    const [, answers] = useQuestion()
    const { t } = useTranslation()
    const decade = answers['age'][0].split('-')[0]

    return (
        <h3 className="text-center text-xs xs:text-sm md:text-lg xs:leading-tight text-gray-500 mt-2 px-2 xs:px-4">
            {decade === '18'
                ? t('survey:typical_lunch.subtitle_18')
                : t('survey:typical_lunch.subtitle', {
                      age: decade,
                  })}
        </h3>
    )
}
