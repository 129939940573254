import React, { useState } from 'react'
import { Button } from '../components/Button'
import EmailIcon from '../components/EmailIcon'
import FacebookIcon from '../components/FacebookIcon'
import GoogleIcon from '../components/GoogleIcon'
import { LockIcon } from '../components/LockIcon'
import { Modal } from '../components/Modal'
import { Step } from '../components/Step'
import { TextInput } from '../components/TextInput'
import { Title } from '../components/Title'
import { useUser } from '../contexts/auth'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { trackAmplitudeEvent } from '../services/client/amplitude'
import { updateUser } from '../services/client/api'
import { trackAmplitudeAndGTAGEvent, trackLead } from '@services/client/events'
import { firebase } from '../services/client/firebaseClient'
import { validateEmail } from './validation'
import * as Sentry from '@sentry/node'
import classNames from 'classnames'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'

type UserLoginPromt = {
    provider: string
    email: string
}

export const EmailStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()
    const user = useUser()

    const [processing, setProcessing] = useState(false)
    const [promptUserLogin, setPromptUserLogin] = useState<UserLoginPromt | null>(null)
    const [promptEmail, setPromptEmail] = useState<boolean>(false)
    const [updateError, setUpdateError] = useState<string | null>(null)

    const onLinkWithOAuthProvider = async (provider: firebase.auth.AuthProvider) => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: provider.providerId,
            source: 'link',
        })

        setProcessing(true)
        setUpdateError(null)

        try {
            const result = await firebase.auth().currentUser.linkWithPopup(provider)
            onUpdateUser(result.user.email, provider.providerId, 'link')
        } catch (linkError) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: provider.providerId,
                code: linkError.code,
                source: 'link',
            })
            Sentry.captureException(linkError)

            const email: string = linkError.email
            if (linkError.code === 'auth/provider-already-linked') {
                onUpdateUser(email, provider.providerId, 'link')
            } else if (linkError.code === 'auth/credential-already-in-use') {
                const linkCredentials: firebase.auth.OAuthCredential = linkError.credential
                try {
                    trackAmplitudeAndGTAGEvent('login_with_provider', {
                        provider: provider.providerId,
                        source: 'credential_in_use',
                    })
                    const signInResult = await firebase.auth().signInWithCredential(linkCredentials)
                    onUpdateUser(signInResult.user.email, provider.providerId, 'credential_in_use')
                } catch (signInError) {
                    trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                        provider: provider.providerId,
                        code: signInError.code,
                        source: 'credential_in_use',
                    })
                    Sentry.captureException(signInError)
                    setProcessing(false)
                }
            } else if (linkError.code === 'auth/email-already-in-use') {
                const methods = await firebase.auth().fetchSignInMethodsForEmail(email)
                if (methods.length === 0) {
                    // The email is just reserved, we continue with password provider
                    onUpdateUser(email, 'password', 'link')
                } else {
                    setProcessing(false)
                    setPromptUserLogin({
                        email: email,
                        provider: methods[0],
                    })
                }
            } else {
                setProcessing(false)
            }
        }
    }

    const onContinueWithFacebook = async () => {
        const provider = new firebase.auth.FacebookAuthProvider()
        provider.addScope('email')
        provider.addScope('public_profile')
        await onLinkWithOAuthProvider(provider)
    }

    const onContinueWithGoogle = async () => {
        const provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope('email')
        await onLinkWithOAuthProvider(provider)
    }

    const onContinueWithEmail = () => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: 'password',
            source: 'link',
        })

        setProcessing(false)
        setUpdateError(null)
        setPromptEmail(true)
    }

    const onSignIn = async (email: string, providerId: string, password?: string) => {
        switch (providerId) {
            case 'facebook.com': {
                const provider = new firebase.auth.FacebookAuthProvider()
                provider.addScope('email')
                provider.addScope('public_profile')
                await onSignInWithOAuthProvider(provider)
                break
            }
            case 'google.com': {
                const provider = new firebase.auth.GoogleAuthProvider()
                provider.addScope('email')
                await onSignInWithOAuthProvider(provider)
                break
            }
            default: {
                await signInWithEmailAndPassword(email, password)
                break
            }
        }
    }

    const signInWithEmailAndPassword = async (email: string, password: string) => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: 'password',
            source: 'sign_in',
        })

        setProcessing(true)
        setUpdateError(null)

        try {
            const result = await firebase.auth().signInWithEmailAndPassword(email, password)
            onUpdateUser(result.user.email, 'password', 'sign_in')
        } catch (error) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: 'password',
                code: error.code,
                source: 'sign_in',
            })

            if (error.code === 'auth/wrong-password') {
                setUpdateError(t('common:errors.password.wrong'))
            } else {
                Sentry.captureException(error)
                setUpdateError(t('common:errors.unknown'))
            }
            setProcessing(false)
        }
    }

    const onSignInWithOAuthProvider = async (provider: firebase.auth.AuthProvider) => {
        trackAmplitudeAndGTAGEvent('login_with_provider', {
            provider: provider.providerId,
            source: 'sign_in',
        })

        setProcessing(true)
        setUpdateError(null)

        try {
            const result = await firebase.auth().signInWithPopup(provider)
            onUpdateUser(result.user.email, provider.providerId, 'sign_in')
        } catch (error) {
            trackAmplitudeAndGTAGEvent('login_with_provider_failed', {
                provider: provider.providerId,
                code: error.code,
                source: 'sign_in',
            })
            if (error.code !== 'auth/popup-closed-by-user') {
                Sentry.captureException(error)
                setUpdateError(t('common:errors.unknown'))
            }
            setProcessing(false)
        }
    }

    const onUpdateUser = async (email: string, providerId: string, source: string) => {
        trackAmplitudeAndGTAGEvent('login_with_provider_succeed', {
            provider: providerId,
            source: source,
        })

        setProcessing(true)
        setUpdateError(null)
        const result = await updateUser({ email, name: user.name, survey: { answers: answers } })
        if (result.error) {
            trackAmplitudeAndGTAGEvent('update_user_failed', {
                provider: providerId,
                source: source,
                code: result.error.code,
                message: result.error.message,
            })
            if (result.error.code === 'auth/email-already-exists') {
                const methods = await firebase.auth().fetchSignInMethodsForEmail(email)
                setPromptEmail(false)
                setPromptUserLogin({
                    email: email,
                    provider: methods[0],
                })
            } else {
                setUpdateError(result.error.code === 'unknown' ? t('common:errors.unknown') : result.error.message)
            }
            setProcessing(false)
        } else {
            setPromptUserLogin(null)
            setPromptEmail(false)
            trackLead({ uid: user.id })
            const fbCookies = getFBPCookies()
            const fbEventParams = {
                email: user.email,
                userID: user.id,
                event: 'Lead',
                userIPData: user.ipData,
                _fbc: fbCookies._fbc,
                _fbp: fbCookies._fbp,
                source: '/survey/your_email',
            }
            await sendFacebookEvent(fbEventParams)
            trackAmplitudeEvent('lead_created', {
                provider: providerId,
                source: source,
            })
            dispatch({ type: 'QUESTION_COMPLETED' })
        }
    }

    return (
        <Step key={question.path} name={question.name}>
            {/* <MultiLoginProviderForm processing={processing} onContinueWithEmail={onContinueWithEmail} /> */}
            <SingleEMailProviderForm processing={processing} updateError={updateError} onUpdateUser={onUpdateUser} />

            {promptUserLogin && (
                <UserLoginPrompt
                    email={promptUserLogin.email}
                    provider={promptUserLogin.provider}
                    processing={processing}
                    updateError={updateError}
                    onClose={() => {
                        setProcessing(false)
                        setPromptUserLogin(null)
                    }}
                    onSignIn={onSignIn}
                />
            )}

            {promptEmail && (
                <EmailInputModal
                    processing={processing}
                    updateError={updateError}
                    onClose={() => {
                        setProcessing(false)
                        setPromptEmail(false)
                    }}
                    onUpdateUser={onUpdateUser}
                />
            )}
        </Step>
    )
}

const MultiLoginProviderForm: React.FC<{ processing: boolean; onContinueWithEmail: () => void }> = ({
    processing,
    onContinueWithEmail,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <div className="flex flex-col space-y-3 items-center mb-6">
                {/* <LoginButton
                    icon={<FacebookIcon color="#111827" className="w-4 h-4" />}
                    text={t('survey:signup.continue_with_facebook')}
                    processing={processing}
                    onClick={onContinueWithFacebook}
                />
                <LoginButton
                    icon={<GoogleIcon className="w-4 h-4" />}
                    text={t('survey:signup.continue_with_google')}
                    processing={processing}
                    onClick={onContinueWithGoogle}
                /> */}
                <LoginButton
                    icon={<EmailIcon color="#111827" className="w-4 h-4" />}
                    text={t('survey:signup.continue_with_email')}
                    processing={processing}
                    onClick={onContinueWithEmail}
                />
            </div>

            <div className="text-gray-500 text-xxs leading-snug md:text-xs w-full md:leading-normal text-center my-2 md:mt-4 px-4">
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('survey:signup.information_secure', {
                            tos_url: 'https://fastic.com/terms/',
                            privacy_url: 'https://fastic.com/privacy-policy/',
                        }),
                    }}
                ></p>
                <p className="mt-4">{t('survey:signup.receive_offers')}</p>
            </div>
        </>
    )
}

const SingleEMailProviderForm: React.FC<{
    processing: boolean
    updateError?: string
    onUpdateUser: (email: string, provider: string, source: string) => void
}> = ({ processing, updateError, onUpdateUser }) => {
    const { t } = useTranslation()
    const user = useUser()

    const [form, setForm] = useState<{
        email?: string
        emailError?: string
    }>({
        email: user.email,
        emailError: user.email && validateEmail(user.email),
    })

    const onContinue = async () => {
        const emailError = validateEmail(form.email)
        if (emailError) {
            setForm({
                ...form,
                emailError,
            })
        } else {
            trackAmplitudeAndGTAGEvent('login_with_provider', {
                provider: 'password',
                source: 'link',
            })

            onUpdateUser(form.email, 'password', 'email')
        }
    }

    const onEmailChange = (value: string) => {
        setForm({
            ...form,
            email: value,
            emailError: null,
        })
    }

    return (
        <>
            <TextInput
                type="email"
                label={t('survey:signup.your_email')}
                note={<LockIcon className="w-4 h-4" />}
                value={form.email}
                error={updateError || (form.emailError && t(form.emailError))}
                onChange={onEmailChange}
                disabled={processing}
            />
            <Button
                processing={processing}
                primary
                label={t('common:controls.continue')}
                className="w-full"
                onClick={onContinue}
            />
            <div className="text-gray-500 text-xxs leading-snug md:text-xs w-full md:leading-normal text-center my-2 md:mt-4 px-4">
                <p
                    dangerouslySetInnerHTML={{
                        __html: t('survey:signup.information_secure', {
                            tos_url: 'https://fastic.com/terms/',
                            privacy_url: 'https://fastic.com/privacy-policy/',
                        }),
                    }}
                ></p>
                <p className="mt-4">{t('survey:signup.receive_offers')}</p>
            </div>
        </>
    )
}

const UserLoginPrompt: React.FC<{
    email: string
    provider: string
    processing: boolean
    updateError?: string
    onClose: () => void
    onSignIn: (email: string, provider: string, password?: string) => void
}> = ({ email, provider, processing, updateError, onClose, onSignIn }) => {
    const { t } = useTranslation()

    const [form, setForm] = useState<{
        password?: string
        passwordError?: string
    }>({})

    const onPasswordChange = (value: string) => {
        setForm({
            ...form,
            password: value.trim(),
            passwordError: null,
        })
    }

    const onSignInWithFacebook = async () => {
        onSignIn(email, 'facebook.com')
    }

    const onSignInWithGoogle = async () => {
        onSignIn(email, 'google.com')
    }

    const onSignInWithEmailPassword = async () => {
        onSignIn(email, 'password', form.password)
    }

    return (
        <Modal onClose={onClose}>
            <Title>{t('survey:download.sign_in')}</Title>
            <div className="mt-2 mb-4 text-center text-gray-600">
                <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{
                        __html: t('survey:signup.account_with_email_already_exists', {
                            email,
                        }),
                    }}
                ></p>
            </div>
            {provider === 'password' && (
                <div>
                    <p className="text-center mb-2 text-gray-600">{t('survey:signup.input_password')}</p>
                    <TextInput
                        type="password"
                        label={t('survey:registration.your_password')}
                        value={form.password}
                        error={updateError || (form.passwordError && t(form.passwordError))}
                        onChange={onPasswordChange}
                        disabled={processing}
                    />
                    <Button
                        primary
                        label={t('common:controls.next')}
                        onClick={onSignInWithEmailPassword}
                        className="w-full"
                    />
                </div>
            )}
            {provider === 'facebook.com' && (
                <div className="flex flex-col items-center">
                    <LoginButton
                        icon={<FacebookIcon color="#111827" className="w-4 h-4" />}
                        text={t('survey:signup.continue_with_facebook')}
                        processing={processing}
                        onClick={onSignInWithFacebook}
                    />
                    {updateError && <div className="text-xs md:text-sm mt-2 text-red-500">{updateError}</div>}
                </div>
            )}
            {provider === 'google.com' && (
                <div className="flex flex-col items-center">
                    <LoginButton
                        icon={<GoogleIcon className="w-4 h-4" />}
                        text={t('survey:signup.continue_with_google')}
                        processing={processing}
                        onClick={onSignInWithGoogle}
                    />
                    {updateError && <div className="text-xs md:text-sm mt-2 text-red-500">{updateError}</div>}
                </div>
            )}
        </Modal>
    )
}

const EmailInputModal: React.FC<{
    processing: boolean
    updateError?: string
    onClose: () => void
    onUpdateUser: (email: string, provider: string, source: string) => void
}> = ({ processing, updateError, onClose, onUpdateUser }) => {
    const { t } = useTranslation()
    const user = useUser()

    const [form, setForm] = useState<{
        email?: string
        emailError?: string
    }>({
        email: user.email,
        emailError: user.email && validateEmail(user.email),
    })

    const onEmailChange = (value: string) => {
        setForm({
            ...form,
            email: value,
            emailError: null,
        })
    }

    const onRegister = async () => {
        const emailError = validateEmail(form.email)
        if (emailError) {
            setForm({
                ...form,
                emailError,
            })
        } else {
            onUpdateUser(form.email, 'password', 'email')
        }
    }

    return (
        <Modal onClose={onClose}>
            <Title>{t('survey:signup.input_email')}</Title>
            <div className="mt-4">
                <TextInput
                    type="email"
                    label={t('survey:signup.your_email')}
                    note={<LockIcon className="w-4 h-4" />}
                    value={form.email}
                    error={updateError || (form.emailError && t(form.emailError))}
                    onChange={onEmailChange}
                    disabled={processing}
                />
            </div>
            <Button
                processing={processing}
                primary
                label={t('common:controls.continue')}
                className="w-full"
                onClick={onRegister}
            />
        </Modal>
    )
}

const LoginButton: React.FC<{
    icon: React.ReactElement
    text: string
    processing: boolean
    onClick: () => void
}> = ({ icon, text, processing, onClick }) => {
    const buttonStyles = classNames(
        'flex w-9/12 flex-row justify-center items-center rounded-lg bg-gray-100 py-3 px-4 focus:outline-none focus:shadow-outline',
        !processing && 'text-gray-900 hover:bg-gray-200',
        processing && 'pointer-events-none text-gray-500',
    )

    return (
        <button className={buttonStyles} type="button" disabled={processing} onClick={onClick}>
            <span className="pr-2">{icon}</span>
            <span>{text}</span>
        </button>
    )
}
