import React from 'react'
import CheckCircle from '@components/CheckCircle'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { isOldSafari } from '@utils/isOldSafari'

const goals = [
    {
        label: 'lose_weight',
        imgSrc: `https://images.ctfassets.net/i6d954lr9wft/2nBP1vSumB43gVYRRi8uYG/39d8e6c3361317161ac624fd7505a31c/5_Things_That_Happen_to_Your_Body_When_You_Fast_1.png?h=168${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        label: 'boost_health',
        imgSrc:
            'https://images.ctfassets.net/i6d954lr9wft/4ebq8eVQwVEjEuYdsUs9It/b5f97abc5258c6df0fb72cab07f9a352/mind-changed.svg',
    },
]

export const GoalStep: React.FC = () => {
    const { t } = useTranslation()
    const [question, answers, dispatch] = useQuestion()
    const selected = (answers[question.name] as string[]) ?? []

    const handleOptionChange = (value: string) => {
        const index = selected.indexOf(value)
        let next: string[]
        if (index >= 0) {
            next = selected.filter(v => v !== value)
        } else {
            next = [value]
        }
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: next })
    }

    return (
        <Step key={question.path} name={question.name}>
            <div className="grid grid-cols-2 grid-flow-row gap-4 mt-6 xs:mt-10">
                {goals.map(option => (
                    <GoalOption
                        handleOptionChange={() => handleOptionChange(option.label)}
                        label={t(`survey:goal.options.${option.label}`)}
                        imgSrc={option.imgSrc}
                        checked={selected.includes(option.label)}
                    />
                ))}
            </div>
        </Step>
    )
}

const GoalOption: React.FC<{
    handleOptionChange: () => void
    label: string
    imgSrc: string
    checked: boolean
}> = ({ handleOptionChange, label, imgSrc, checked }) => {
    return (
        <div
            className="flex flex-col items-center rounded-2xl shadow-standard pt-8 md:pt-14 pb-6 px-10 cursor-pointer"
            onClick={handleOptionChange}
        >
            <div className="w-28 xs:w-32 h-32 md:h-full md:w-full flex items-center justify-center">
                <img className="rounded-full" src={imgSrc} alt="" />
            </div>
            <span className="font-bold text-dark2 text-center mt-6 mb-4 text-sm md:text-base">{label}</span>
            <div className="w-8 h-8">
                <CheckCircle checked={checked} />
            </div>
        </div>
    )
}
