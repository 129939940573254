import React, { ReactElement } from 'react'
import { useUser } from '@contexts/auth'
import { PlanReadyControl } from './control'
import { PlanReadyVariantA } from './variantA'

const PlanReadyStep: React.FC = (): ReactElement => {
    const user = useUser()
    const posTestVariant = user.amplitudeVariants['pos-ui-optimisation']?.value ?? 'control'
    let promo
    if (typeof window !== 'undefined') {
        promo = JSON.parse(window.sessionStorage.getItem('promo'))
    }
    const subscriptionWithoutTrial = !!promo || user.amplitudeVariants['no-trial-sub']?.value === 'no-trial-sub'
    return posTestVariant === 'control' ? (
        <PlanReadyControl subscriptionWithoutTrial={subscriptionWithoutTrial} promo={promo} />
    ) : (
        <PlanReadyVariantA isPromoCodeEnabled={false} subscriptionWithoutTrial={subscriptionWithoutTrial} />
    )
}

export default PlanReadyStep
