import { PaymentProduct } from '@services/payment_product'
import { useTranslation } from 'i18n'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useTransition, animated } from 'react-spring'

const TimerBanner: React.FC<{ product: PaymentProduct; countdownEnd: number }> = ({ product, countdownEnd }) => {
    const [show, setShow] = useState(false)
    const { t } = useTranslation()

    const onScroll = () => {
        if (countdownEnd < Date.now()) {
            setShow(false)
        } else {
            const { pageYOffset, innerHeight } = window
            if (pageYOffset / innerHeight >= 0.4) {
                setShow(true)
            } else {
                setShow(false)
            }
        }
    }

    const onCountdownCompleted = () => {
        window.removeEventListener('scroll', onScroll)
        setShow(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    const transitions = useTransition(show, null, {
        from: { opacity: 0 },
        enter: { opacity: 0.6 },
        leave: { opacity: 0 },
    })

    return (
        <div>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <animated.div
                            key={key}
                            style={props}
                            className="fixed top-0 left-0 w-full flex justify-center items-center shadow-lg py-3 md:py-4 font-bold bg-red-600 z-50 text-white"
                        >
                            <div className="px-4 text-center">
                                <span className="text-xs md:text-base">
                                    {t('survey:program.variant-a.alert', { trialPeriod: product.trialPeriodDays })}{' '}
                                </span>
                                <span>
                                    <Countdown
                                        date={countdownEnd}
                                        renderer={countdownRenderer}
                                        onComplete={onCountdownCompleted}
                                    />
                                </span>
                            </div>
                        </animated.div>
                    ),
            )}
        </div>
    )
}

const countdownRenderer = ({ formatted }) => {
    return (
        <span>
            {formatted.minutes}:{formatted.seconds}
        </span>
    )
}

export default TimerBanner
