export const convertKgToLbs = (kg: number): number => Math.floor(kg * 2.20462262185)

export const convertLbsToKg = (lbs: number): number => Math.floor(lbs * 0.45359237)

export type FeetInches = { feet: number; inches: number }

export const convertCmToFeetAndInches = (cm: number): FeetInches => {
    const length = cm / 2.54
    const feet = Math.floor(length / 12)
    const inches = Math.round(length - 12 * feet)

    return {
        feet: feet > 0 ? feet : null,
        inches: inches > 0 ? Math.min(11, inches) : null,
    }
}

export const convertFeetInchesToCm = ({ feet, inches }: FeetInches): number => {
    const cm = Math.floor((feet ?? 0) * 30.48 + (inches ?? 0) * 2.54)
    return cm > 0 ? cm : null
}

export const formatNumber = (value: string): number | null => {
    const cleaned = (value ?? '').replace(/[^0-9]/g, '')
    const num = cleaned.length > 0 ? Number(cleaned) : NaN
    return num && num > 0 ? num : null
}

export const formatInches = (value: string): number | null => {
    const num = formatNumber(value) ?? 0
    return num && num > 11 ? 11 : num
}

export const calculateWeightGroup = (kg: number): { start: number; end: number } => {
    const weightTens = Math.trunc(kg / 10)
    const weightFraction = Math.round((kg / 10 - weightTens) * 10)
    const start = Math.max(0, weightTens * 10 - (weightFraction < 5 ? 10 : 0))
    const end = start + 20

    return { start, end }
}
