import React, { ReactElement } from 'react'
import { CheckIcon } from '@components/CheckIcon'
import Tooltip from '@components/Tooltip'
import { calculateWeeklyPrice, PaymentProduct } from '@services/payment_product'
import classNames from 'classnames'
import { useTranslation } from 'i18n'

const PriceBox: React.FC<{
    priceTier: 'recommended' | 'below' | 'above'
    savePercentage?: number
    product: PaymentProduct
    currency: string
    selectedPlan: number
    onChangeSelectedPlan: () => void
    priceVariant: string
    subscriptionWithoutTrial: boolean
}> = ({
    priceTier,
    savePercentage,
    product,
    currency,
    selectedPlan,
    onChangeSelectedPlan,
    priceVariant,
    subscriptionWithoutTrial,
}): ReactElement => {
    const { t } = useTranslation()
    const containerStyles = classNames(
        'border-2 rounded-xl',
        selectedPlan === product.productIntervalCount ? 'border-boost2' : 'border-dark5',
    )
    const matchesGoalStyles = classNames(
        'absolute -bottom-4 text-sm rounded-lg p-2 font-extrabold  text-white',
        selectedPlan === product.productIntervalCount ? 'bg-boost2' : 'bg-dark4',
    )
    const checkboxStyles = classNames(
        'w-6 h-6 rounded-full  flex justify-center items-center text-white font-bold',
        selectedPlan === product.productIntervalCount ? 'bg-boost2' : 'bg-white border-2 border-checkboxborder',
    )

    return (
        <div className={containerStyles}>
            {priceTier === 'recommended' && (
                <div className="relative flex w-full items-center justify-center">
                    <span className={matchesGoalStyles}>{t('survey:program.variant-a.plan.matches_goal')}</span>
                </div>
            )}
            {selectedPlan === product.productIntervalCount && !subscriptionWithoutTrial && (
                <>
                    {priceVariant !== 'variant-b' && (
                        <>
                            <div className="flex items-center justify-between p-6">
                                <span className="text-dark1 text-lg font-bold">
                                    {t('survey:program.variant-a.plan.trial', { days: product.trialPeriodDays })}
                                </span>
                                <div className="flex  items-center justify-between">
                                    <div className="flex flex-col mr-4 items-end">
                                        <span className="text-dark1 text-lg font-bold">
                                            {currency}
                                            {product.trialPrice.toFixed(2)}
                                        </span>
                                        <span className="text-dark3">
                                            {t('survey:program.variant-a.plan.total_today')}
                                        </span>
                                    </div>
                                    <Tooltip text={t('survey:program.variant-a.plan.trial_info')}>
                                        <div className="w-6 h-6 rounded-full flex justify-center items-center text-white font-bold bg-dark5">
                                            {selectedPlan === product.productIntervalCount && (
                                                <CheckIcon className="w-5 h-5" />
                                            )}
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>

                            <div className="relative flex w-full items-center justify-center">
                                <div className="h-0.5 w-10/12 bg-dark5 mx-auto" />
                                <span className="absolute -bottom-2 bg-white px-3 text-dark4 font-semibold text-xs uppercase">
                                    {t('survey:program.variant-a.plan.price_after_trial')}
                                </span>
                            </div>
                        </>
                    )}
                </>
            )}
            <div className="flex items-center justify-between p-6" onClick={onChangeSelectedPlan}>
                <div className="flex flex-col">
                    <span className="text-dark1 text-lg font-bold">
                        {t('survey:program.variant-a.plan.subscription', { months: product.productIntervalCount })}
                    </span>
                    {priceTier === 'above' && (
                        <span className="font-bold text-lg text-transparent bg-clip-text bg-gradient-to-tr from-frenchrose to-darkorange">
                            Save {savePercentage}%
                        </span>
                    )}
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col mr-4 items-end">
                        <span className="text-dark1 text-lg font-bold">
                            {t('survey:program.variant-a.plan.price_per_week', {
                                price: calculateWeeklyPrice(product).toFixed(2),
                                currency,
                            })}
                        </span>
                        <span className="text-dark3">
                            {t('survey:program.variant-a.plan.billed_at', {
                                price: product.productPrice,
                                currency,
                            })}
                        </span>
                    </div>
                    <div className={checkboxStyles}>
                        {selectedPlan === product.productIntervalCount && <CheckIcon className="w-5 h-5" />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PriceBox
