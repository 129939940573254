import React, { useState } from 'react'
import { useTranslation } from 'i18n'
import { GlobeIcon } from '@components/GlobeIcon'
import { isOldSafari } from '@utils/isOldSafari'

interface ReviewProps {
    key: string
    imageUrl: string
}

console.log(isOldSafari())

const reviews: ReviewProps[] = [
    {
        key: 'patricia_jones',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/79VNdsqloWdpbgvKRirJW4/e575726fe2f462e8d92cc23e1bf2d865/Patricia_Jones.jpg.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        key: 'maximilian_stritt',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/ue9osGcgvIjXzEcBS0Q3s/b7068d97f6b2e47ba79b007348b551b8/Maximilian_Stritt.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        key: 'virginia_smith',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/3Cxt2ZWBkZZYSi0Az2HG5R/ccad1ab3ced5ab793c844fa568644654/Virginia_Smith.jpg.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        key: 'edward_miller',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/4q6rbuQzRk32KSi9vQJ2cq/74f1ca93b3d94edaf4669444eb87de9d/Edward_Miller.jpg.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        key: 'holly_robinson',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/36DHlSf57MXwlgfoCjRFCG/8badad7341bcd899ff193a0c04e21dd6/Holly_Robinson.jpg.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
    {
        key: 'jasmine_razee',
        imageUrl: `https://images.ctfassets.net/eejvltlfzbsz/36syjWobgGBSBmrMX6aFky/761a1a5edaf37e72ff662549c5b2a388/Jasmine_Razee.jpg.png?w=64${
            !isOldSafari() ? '&fm=webp' : ''
        }`,
    },
]

const Reviews: React.FC<{ testVariant: string }> = ({ testVariant }) => {
    const [expanded, setExpanded] = useState(false)
    const { t } = useTranslation()

    const reviewsToShow = expanded ? reviews : reviews.slice(0, 3)

    if (testVariant === 'control') {
        return (
            <div className="border-t border-gray-300 mt-4 md:mt-8 text-base mb-2 md:mb-4">
                <div className="bg-gray-100 text-sm md:text-lg text-gray-500 font-bold py-2.5 px-3 mb-2">
                    {t(`survey:program.${testVariant}.reviews.title`)}
                </div>
                <div className="grid grid-cols-1 gap-y-2">
                    {reviewsToShow.map(rev => (
                        <Review
                            key={`rev_${rev.key}`}
                            imageUrl={rev.imageUrl}
                            name={t(`survey:program.${testVariant}.reviews.${rev.key}.author`)}
                            text={t(`survey:program.${testVariant}.reviews.${rev.key}.text`)}
                            testVariant={testVariant}
                        />
                    ))}
                </div>
                {!expanded && (
                    <div
                        onClick={() => setExpanded(true)}
                        className="text-green-700 text-sm md:text-base font-bold border-b border-gray-300 flex justify-end py-4"
                    >
                        <span>{t(`survey:program.${testVariant}.reviews.view_more`)}</span>
                    </div>
                )}
            </div>
        )
    } else {
        return (
            <div className="flex flex-col mb-20">
                <h3 className="text-2xl font-bold text-center text-dark1">
                    {t(`survey:program.${testVariant}.reviews.title`)}
                </h3>
                <div className="mt-4 mb-8 grid grid-cols-1 gap-y-4">
                    {reviewsToShow.map(rev => (
                        <Review
                            key={`rev_${rev.key}`}
                            imageUrl={rev.imageUrl}
                            name={t(`survey:program.${testVariant}.reviews.${rev.key}.author`)}
                            text={t(`survey:program.${testVariant}.reviews.${rev.key}.text`)}
                            testVariant={testVariant}
                        />
                    ))}
                </div>
                {!expanded && (
                    <div className="flex items-center justify-center">
                        <button
                            className="text-boost2 font-extrabold flex items-center"
                            onClick={() => setExpanded(true)}
                        >
                            <svg
                                width="13"
                                height="9"
                                viewBox="0 0 13 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.2102 1.28994C12.1172 1.19621 12.0066 1.12182 11.8848 1.07105C11.7629 1.02028 11.6322 0.994141 11.5002 0.994141C11.3682 0.994141 11.2375 1.02028 11.1156 1.07105C10.9937 1.12182 10.8831 1.19621 10.7902 1.28994L6.50019 5.58994L2.21019 1.28994C2.02188 1.10164 1.76649 0.995848 1.50019 0.995848C1.23388 0.995848 0.978489 1.10164 0.790185 1.28994C0.601882 1.47824 0.496094 1.73364 0.496094 1.99994C0.496094 2.26624 0.601882 2.52164 0.790185 2.70994L5.79019 7.70994C5.88315 7.80367 5.99375 7.87806 6.11561 7.92883C6.23747 7.9796 6.36817 8.00574 6.50019 8.00574C6.6322 8.00574 6.7629 7.9796 6.88476 7.92883C7.00662 7.87806 7.11722 7.80367 7.21019 7.70994L12.2102 2.70994C12.3039 2.61698 12.3783 2.50638 12.4291 2.38452C12.4798 2.26266 12.506 2.13195 12.506 1.99994C12.506 1.86793 12.4798 1.73722 12.4291 1.61536C12.3783 1.4935 12.3039 1.3829 12.2102 1.28994Z"
                                    fill="#FF9933"
                                />
                            </svg>
                            <span className="ml-4">{t(`survey:program.${testVariant}.reviews.view_more`)}</span>
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

const Review: React.FC<{ imageUrl: string; name: string; text: string; testVariant: string }> = ({
    imageUrl,
    name,
    text,
    testVariant,
}) => {
    const { t } = useTranslation()

    if (testVariant === 'control') {
        return (
            <div className="border border-gray-200 rounded py-4 px-3">
                <div className="flex items-center">
                    <img className="w-10 rounded-full" src={imageUrl} />
                    <div className="pl-3">
                        <div className="text-green-700 font-bold text-sm md:text-base leading-none md:leading-none">
                            <span>{name}</span>
                            <img className="w-3.5 ml-1 inline" src="/comment-star.png" />
                        </div>
                        <div className="text-xs md:text-sm mt-1 flex items-center text-gray-400">
                            <GlobeIcon className="w-4 h-4 mr-1" />
                            <span
                                dangerouslySetInnerHTML={{ __html: t('survey:program.variant-a.reviews.recommends') }}
                            ></span>
                        </div>
                    </div>
                </div>
                <div className="mt-1.5 text-xs md:text-base text-gray-900">{text}</div>
            </div>
        )
    } else {
        return (
            <div className="bg-boost6 rounded-xl px-2 pb-2 pt-3">
                <div className="flex justify-between items-center">
                    <div className="flex items-center justify-center">
                        <img src={imageUrl} alt={name} />
                        <span className="ml-3 text-black font-bold">{name}</span>
                    </div>
                    <svg width="86" height="14" viewBox="0 0 86 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.6697 4.79658L9.13503 4.37831L7.33373 0.218457C7.20762 -0.0728189 6.79235 -0.0728189 6.66624 0.218457L4.86497 4.37833L0.33032 4.79658C0.0128046 4.82587 -0.115519 5.21863 0.124039 5.42792L3.54545 8.41715L2.54418 12.8355C2.47407 13.1449 2.81001 13.3876 3.08419 13.2257L7 10.9133L10.9158 13.2257C11.19 13.3876 11.526 13.1449 11.4559 12.8355L10.4545 8.41715L13.876 5.42792C14.1155 5.21863 13.9872 4.82587 13.6697 4.79658Z"
                            fill="#FFCC33"
                        />
                        <path
                            d="M7.32923 0.218457C7.2032 -0.0728189 6.78819 -0.0728189 6.66216 0.218457L4.86199 4.37833L0.330117 4.79658C0.0127967 4.82587 -0.115449 5.21863 0.123963 5.42792L3.54328 8.41715L2.54262 12.8355C2.47255 13.1449 2.80829 13.3876 3.0823 13.2257L3.95575 12.7096C4.07664 7.75753 6.38878 4.27024 8.21935 2.27531"
                            fill="#FDC766"
                        />
                        <path
                            d="M31.6697 4.79658L27.135 4.37831L25.3337 0.218457C25.2076 -0.0728189 24.7924 -0.0728189 24.6662 0.218457L22.865 4.37833L18.3303 4.79658C18.0128 4.82587 17.8845 5.21863 18.124 5.42792L21.5454 8.41715L20.5442 12.8355C20.4741 13.1449 20.81 13.3876 21.0842 13.2257L25 10.9133L28.9158 13.2257C29.19 13.3876 29.526 13.1449 29.4559 12.8355L28.4545 8.41715L31.876 5.42792C32.1155 5.21863 31.9872 4.82587 31.6697 4.79658Z"
                            fill="#FFCC33"
                        />
                        <path
                            d="M25.3292 0.218457C25.2032 -0.0728189 24.7882 -0.0728189 24.6622 0.218457L22.862 4.37833L18.3301 4.79658C18.0128 4.82587 17.8846 5.21863 18.124 5.42792L21.5433 8.41715L20.5426 12.8355C20.4726 13.1449 20.8083 13.3876 21.0823 13.2257L21.9557 12.7096C22.0766 7.75753 24.3888 4.27024 26.2194 2.27531"
                            fill="#FDC766"
                        />
                        <path
                            d="M49.6697 4.79658L45.135 4.37831L43.3337 0.218457C43.2076 -0.0728189 42.7924 -0.0728189 42.6662 0.218457L40.865 4.37833L36.3303 4.79658C36.0128 4.82587 35.8845 5.21863 36.124 5.42792L39.5454 8.41715L38.5442 12.8355C38.4741 13.1449 38.81 13.3876 39.0842 13.2257L43 10.9133L46.9158 13.2257C47.19 13.3876 47.526 13.1449 47.4559 12.8355L46.4545 8.41715L49.876 5.42792C50.1155 5.21863 49.9872 4.82587 49.6697 4.79658Z"
                            fill="#FFCC33"
                        />
                        <path
                            d="M43.3292 0.218457C43.2032 -0.0728189 42.7882 -0.0728189 42.6622 0.218457L40.862 4.37833L36.3301 4.79658C36.0128 4.82587 35.8846 5.21863 36.124 5.42792L39.5433 8.41715L38.5426 12.8355C38.4726 13.1449 38.8083 13.3876 39.0823 13.2257L39.9557 12.7096C40.0766 7.75753 42.3888 4.27024 44.2194 2.27531"
                            fill="#FDC766"
                        />
                        <path
                            d="M67.6697 4.79658L63.135 4.37831L61.3337 0.218457C61.2076 -0.0728189 60.7924 -0.0728189 60.6662 0.218457L58.865 4.37833L54.3303 4.79658C54.0128 4.82587 53.8845 5.21863 54.124 5.42792L57.5454 8.41715L56.5442 12.8355C56.4741 13.1449 56.81 13.3876 57.0842 13.2257L61 10.9133L64.9158 13.2257C65.19 13.3876 65.526 13.1449 65.4559 12.8355L64.4545 8.41715L67.876 5.42792C68.1155 5.21863 67.9872 4.82587 67.6697 4.79658Z"
                            fill="#FFCC33"
                        />
                        <path
                            d="M61.3292 0.218457C61.2032 -0.0728189 60.7882 -0.0728189 60.6622 0.218457L58.862 4.37833L54.3301 4.79658C54.0128 4.82587 53.8846 5.21863 54.124 5.42792L57.5433 8.41715L56.5426 12.8355C56.4726 13.1449 56.8083 13.3876 57.0823 13.2257L57.9557 12.7096C58.0766 7.75753 60.3888 4.27024 62.2194 2.27531"
                            fill="#FDC766"
                        />
                        <path
                            d="M85.6697 4.79658L81.135 4.37831L79.3337 0.218457C79.2076 -0.0728189 78.7924 -0.0728189 78.6662 0.218457L76.865 4.37833L72.3303 4.79658C72.0128 4.82587 71.8845 5.21863 72.124 5.42792L75.5454 8.41715L74.5442 12.8355C74.4741 13.1449 74.81 13.3876 75.0842 13.2257L79 10.9133L82.9158 13.2257C83.19 13.3876 83.526 13.1449 83.4559 12.8355L82.4545 8.41715L85.876 5.42792C86.1155 5.21863 85.9872 4.82587 85.6697 4.79658Z"
                            fill="#FFCC33"
                        />
                        <path
                            d="M79.3292 0.218457C79.2032 -0.0728189 78.7882 -0.0728189 78.6622 0.218457L76.862 4.37833L72.3301 4.79658C72.0128 4.82587 71.8846 5.21863 72.124 5.42792L75.5433 8.41715L74.5426 12.8355C74.4726 13.1449 74.8083 13.3876 75.0823 13.2257L75.9557 12.7096C76.0766 7.75753 78.3888 4.27024 80.2194 2.27531"
                            fill="#FDC766"
                        />
                    </svg>
                </div>
                <div className="rounded-lg bg-white p-2 mt-3">
                    <p className="text-black text-sm">"{text}"</p>
                </div>
            </div>
        )
    }
}

export default Reviews
