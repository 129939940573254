import { Choices } from '@components/Choices'
import classNames from 'classnames'
import React from 'react'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'

export const GenderStep: React.FC = () => {
    const { t } = useTranslation()
    const [question, answers, dispatch] = useQuestion()

    const onChoiceChange = (values: string[]) => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: values })
    }

    return (
        <Step key={question.path} name={question.name}>
            <Choices
                name={question.name}
                selected={answers[question.name] as string[]}
                options={['female', 'male']}
                onChange={onChoiceChange}
            />

            <div
                className={classNames(
                    'px-6 py-8 shadow-standard rounded-xl mt-6 xs:mt-10 duration-300 ease-in-out animate-fade-in',
                    answers[question.name] && answers[question.name][0] === 'female' ? 'flex' : 'hidden',
                )}
            >
                <img src={assets.gender_step} width={80} height={80} className="mr-6" />
                <div className="flex flex-col space-y-2">
                    <span className="font-extrabold text-dark1 text-base">
                        {t('survey:gender.female_notice_title')}
                    </span>
                    <p className="text-dark2 text-sm">{t('survey:gender.female_notice_desc')}</p>
                </div>
            </div>
        </Step>
    )
}
