import React, { useEffect, useState } from 'react'
import { RadioTabs } from '../components/RadioTabs'
import { Step } from '../components/Step'
import { TextInput } from '../components/TextInput'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import {
    convertCmToFeetAndInches,
    convertFeetInchesToCm,
    convertKgToLbs,
    convertLbsToKg,
    FeetInches,
    formatInches,
    formatNumber,
} from '../utils/convert'
import { validateHeight, validateWeight } from './validation'

export const YourCurrentStateStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()

    const [form, setForm] = useState<{
        measurementSystem: string
        cm?: number
        feetInches?: FeetInches
        heightError?: string
        kg?: number
        lbs?: number
        weightError?: string
    }>({
        measurementSystem: (answers['measurement_system'] as string) ?? 'metric',
        cm: answers['height'] as number,
        feetInches: answers['height'] && convertCmToFeetAndInches(answers['height'] as number),
        heightError: answers['height'] && validateHeight(answers['height'] as number),
        kg: answers['weight'] as number,
        lbs: answers['weight'] && convertKgToLbs(answers['weight'] as number),
        weightError: answers['weight'] && validateWeight(answers['weight'] as number),
    })

    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'measurement_system', value: form.measurementSystem })
    }, [form.measurementSystem])
    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'height', value: form.cm })
    }, [form.cm])
    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'weight', value: form.kg })
    }, [form.kg])

    const onHeightBlur = () => {
        setForm({ ...form, heightError: validateHeight(form.cm) })
    }

    const onHeightCmChange = (value: string) => {
        const cm = formatNumber(value)
        setForm({
            ...form,
            heightError: null,
            cm,
            feetInches: convertCmToFeetAndInches(cm),
        })
    }

    const onHeightFeetChange = (value: string) => {
        const feet = formatNumber(value)
        const inches = feet ? form.feetInches?.inches : null
        setForm({
            ...form,
            heightError: null,
            feetInches: {
                feet,
                inches,
            },
            cm: convertFeetInchesToCm({ feet, inches }),
        })
    }

    const onHeightInchesChange = (value: string) => {
        const inches = formatInches(value)
        setForm({
            ...form,
            heightError: null,
            feetInches: {
                feet: form.feetInches?.feet,
                inches,
            },
            cm: convertFeetInchesToCm({ feet: form.feetInches?.feet, inches }),
        })
    }

    const onWeightKgChange = (value: string) => {
        const kg = formatNumber(value)
        setForm({
            ...form,
            weightError: null,
            kg,
            lbs: convertKgToLbs(kg),
        })
    }

    const onWeightLbsChange = (value: string) => {
        const lbs = formatNumber(value)
        setForm({
            ...form,
            weightError: null,
            lbs: lbs,
            kg: convertLbsToKg(lbs),
        })
    }

    return (
        <Step key={question.path} name={question.name}>
            <RadioTabs
                name="type"
                selected={form.measurementSystem}
                options={[
                    { value: 'imperial', label: `${t('common:lbs')} ${t('common:and')} ${t('common:ft')}` },
                    { value: 'metric', label: `${t('common:kg')} ${t('common:and')} ${t('common:cm')}` },
                ]}
                onChange={value => setForm({ ...form, measurementSystem: value })}
            />
            {form.measurementSystem === 'metric' && (
                <TextInput
                    type="tel"
                    label={t('survey:current_state.your_height')}
                    note={form.measurementSystem === 'metric' ? t('common:cm') : t('common:ft')}
                    value={form.cm && form.cm.toString()}
                    error={form.heightError && t(form.heightError)}
                    onBlur={onHeightBlur}
                    onChange={onHeightCmChange}
                />
            )}
            {form.measurementSystem === 'imperial' && (
                <>
                    <div className="grid grid-cols-2 gap-4">
                        <TextInput
                            type="tel"
                            label={t('survey:current_state.your_height')}
                            note={t('common:ft')}
                            value={form.feetInches?.feet && form.feetInches.feet.toString()}
                            error={form.heightError}
                            displayError={false}
                            onBlur={onHeightBlur}
                            onChange={onHeightFeetChange}
                        />
                        <TextInput
                            type="tel"
                            label={t('survey:current_state.your_height')}
                            note={t('common:in')}
                            value={form.feetInches?.inches?.toString()}
                            error={form.heightError}
                            displayError={false}
                            onBlur={onHeightBlur}
                            onChange={onHeightInchesChange}
                        />
                    </div>
                    {form.heightError && (
                        <div className="block w-full text-sm mb-4 -mt-2.5 text-red-500">{t(form.heightError)}</div>
                    )}
                </>
            )}
            <TextInput
                type="tel"
                label={t('survey:current_state.current_weight')}
                note={form.measurementSystem === 'metric' ? t('common:kg') : t('common:lbs')}
                value={
                    form.measurementSystem === 'metric'
                        ? form.kg && form.kg.toString()
                        : form.lbs && form.lbs.toString()
                }
                error={form.weightError && t(form.weightError)}
                onBlur={() => setForm({ ...form, weightError: validateWeight(form.kg) })}
                onChange={form.measurementSystem === 'metric' ? onWeightKgChange : onWeightLbsChange}
            />
        </Step>
    )
}
