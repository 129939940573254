import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@components/Button'
import { Modal } from '@components/Modal'
import { Title } from '@components/Title'
import { useQuestion } from '@contexts/survey'
import { convertKgToLbs } from '@utils/convert'
import { useTranslation } from 'i18n'
import * as events from '@services/client/events'
import { trackAmplitudeEvent } from '@services/client/amplitude'
import { useUser } from '@contexts/auth'
import { format as formatDate } from 'date-fns'
import { enUS, de } from 'date-fns/locale'
import {
    fallbackCurrency,
    getPaymentProduct,
    getProductTrackingParams,
    Store,
    supportedCurrencies,
} from '@services/payment_product'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { updateUser } from '@services/client/api'
import TrialCountdown from '@components/POS/TrialCountdown'
import Moneyback from '@components/POS/Moneyback'
import Reviews from '@components/POS/Reviews'
import PriceBreakdown from '@components/POS/PriceBreakdown/PriceBreakdown'
import { getFBPCookies, sendFacebookEvent } from '@services/client/tracking'
import CheckoutForm from '@components/POS/CheckoutForm'
import JourneyChart from '@components/POS/JourneyChart'
import TimerBanner from '@components/POS/TimerBanner'

const paypalLocales = {
    en: 'en_US',
    de: 'de_DE',
    es: 'es_ES',
    fr: 'fr_FR',
    it: 'it_IT',
    pt: 'pt_PT',
}

export const PlanReadyVariantA: React.FC<{ isPromoCodeEnabled: boolean; subscriptionWithoutTrial: boolean }> = ({
    isPromoCodeEnabled,
    subscriptionWithoutTrial,
}) => {
    const [, answers, dispatch] = useQuestion()
    const { t, i18n } = useTranslation()
    const locales = { en: enUS, de }
    const user = useUser()
    const [showAreYouReadyModal, setShowAreYouReadyModal] = useState(false)

    const [countdownEnd, setCountdownEnd] = useState(
        Number(localStorage.getItem('countdown_end')) || Date.now() + 60000 * 15,
    )
    useEffect(() => {
        localStorage.setItem('countdown_end', countdownEnd.toString())
        if (countdownEnd < Date.now()) {
            setCountdownEnd(Date.now() + 60000 * 15)
        }
    }, [countdownEnd])

    const requestedCurrency = (answers['currency'] as string) ?? 'USD'
    const currency = supportedCurrencies.includes(requestedCurrency) ? requestedCurrency : fallbackCurrency

    const weightUnit = answers['measurement_system'] === 'metric' ? t('common:kg') : t('common:lbs')
    const weight =
        answers['measurement_system'] === 'metric'
            ? (answers['weight'] as number)
            : convertKgToLbs(answers['weight'] as number)
    const targetWeight =
        answers['measurement_system'] === 'metric'
            ? (answers['target_weight'] as number)
            : convertKgToLbs(answers['target_weight'] as number)
    const weightToLoose = weight - targetWeight
    const timeToLoseWeight = Math.floor(weightToLoose / 0.5)
    const dateToLoseWeightBy = new Date()
    dateToLoseWeightBy.setDate(dateToLoseWeightBy.getDate() + timeToLoseWeight * 7)
    const formattedDateToLoseWeightBy = formatDate(dateToLoseWeightBy, 'PP', {
        locale: locales[i18n.language],
    })

    const posOneMonthSubTest = user.amplitudeVariants['pos-1-month-sub']?.value ?? 'control'
    const paymentProduct = getPaymentProduct({
        currency,
        trialPrice: subscriptionWithoutTrial ? null : (answers['trial_price'] as number) ?? 1,
        weightLoss: posOneMonthSubTest === 'one_month' ? 1 : weightToLoose,
        isActive: true,
    })

    const [selectedPlan, setSelectedPlan] = useState(paymentProduct.productIntervalCount)
    const [selectedPaymentProduct, setSelectedPaymentProduct] = useState(paymentProduct)

    useEffect(() => {
        setSelectedPaymentProduct(
            getPaymentProduct({
                currency,
                trialPrice: subscriptionWithoutTrial ? null : (answers['trial_price'] as number) ?? 1,
                interval: selectedPlan,
                isActive: true,
            }),
        )
    }, [selectedPlan])

    const onChangeSelectedPlan = (plan: number) => setSelectedPlan(plan)

    const fbCookies = getFBPCookies()
    const fbEventParams = {
        email: user.email,
        userID: user.id,
        userIPData: user.ipData,
        _fbc: fbCookies._fbc,
        _fbp: fbCookies._fbp,
        source: '/survey/your_program',
        price: subscriptionWithoutTrial
            ? paymentProduct.productPrice
            : paymentProduct.productPrice + paymentProduct.trialPrice,
    }

    const scrollRef = useRef(null)

    const onStartPlanClick = () => {
        events.trackAmplitudeAndGTAGEvent('start_plan_clicked')
        if (user.subscription.status !== 'active') {
            // setShowAreYouReadyModal(true)
            onReadyClick()
        } else {
            onReadyClick()
            //dispatch({ type: 'QUESTION_COMPLETED' })
        }
    }
    const onReadyClick = async () => {
        setShowAreYouReadyModal(false)
        await sendFacebookEvent({ ...fbEventParams, event: 'InitiateCheckout' })
        events.trackInitiateCheckout({ uid: user.id })
        trackAmplitudeEvent('checkout_opened')
        window.scrollTo({
            top: scrollRef.current.offsetTop,
            behavior: 'smooth',
        })
    }
    const onCheckoutCompleted = async (store: Store) => {
        const lastPlusPurchase = {
            store,
            currency: selectedPaymentProduct.currency,
            currencySymbol: t(`common:currency.${selectedPaymentProduct.currency}`),
            withTrial: !subscriptionWithoutTrial,
            trialPeriodDays: subscriptionWithoutTrial ? 0 : paymentProduct.trialPeriodDays,
            trialPrice: subscriptionWithoutTrial ? 0 : paymentProduct.trialPrice,
            productId: store === 'paypal' ? selectedPaymentProduct.paypalPlan : selectedPaymentProduct.stripeProduct,
            renewalPrice: selectedPaymentProduct.productPrice,
            renewalPeriodMonths: selectedPaymentProduct.productIntervalCount,
        }
        await updateUser({
            lastPlusPurchase,
        })
        await sendFacebookEvent({ ...fbEventParams, event: 'Payment' })
        !subscriptionWithoutTrial &&
            events.trackStartTrial(user.id, getProductTrackingParams(store, selectedPaymentProduct))
        trackAmplitudeEvent('purchase_succeeded', getProductTrackingParams(store, selectedPaymentProduct))
        dispatch({ type: 'QUESTION_COMPLETED' })
    }

    const posTestVariant = user.amplitudeVariants['pos-ui-optimisation']?.value
    const posPriceTestVariant = user.amplitudeVariants['pos-price-selection']?.value

    return (
        <div>
            <PayPalScriptProvider
                options={{
                    'client-id': process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
                    currency: currency,
                    vault: true,
                    intent: 'subscription',
                    locale: paypalLocales[i18n.language],
                }}
            >
                <div className="max-w-lg mx-auto px-4 mt-3">
                    {user.subscription.status !== 'active' && !subscriptionWithoutTrial && (
                        <TimerBanner countdownEnd={countdownEnd} product={selectedPaymentProduct} />
                    )}
                    {user.subscription.status !== 'active' && !subscriptionWithoutTrial && (
                        <TrialCountdown countdownEnd={countdownEnd} testVariant={posTestVariant} />
                    )}

                    <div className="flex flex-col items-center justify-center space-y-1.5">
                        <h2 className="font-bold text-2xl text-center text-dark1">
                            {t('survey:program.variant-a.title')}
                        </h2>
                        <span
                            className="text-base text-dark3 text-center"
                            dangerouslySetInnerHTML={{
                                __html: t('survey:program.variant-a.subtitle', {
                                    weightToLose: weightToLoose,
                                    weightUnit: weightUnit,
                                    timeToLoseIt: timeToLoseWeight,
                                }),
                            }}
                        />
                    </div>

                    <Button
                        primary
                        label={t('common:controls.start_my_plan')}
                        className="w-full mt-8 mb-12"
                        onClick={onStartPlanClick}
                    />

                    <div className="shadow-standard rounded-2xl p-6 bg-white mt-6 mb-16 flex flex-col items-center justify-center">
                        <JourneyChart weight={weight} targetWeight={targetWeight} weightUnit={weightUnit} />
                        <span
                            className="text-base text-center text-dark3 mt-4"
                            dangerouslySetInnerHTML={{
                                __html: t('survey:program.variant-a.plan.achieve_by', {
                                    weight: targetWeight,
                                    weightUnit: weightUnit,
                                    dateToAchieve: formattedDateToLoseWeightBy,
                                }),
                            }}
                        />
                    </div>

                    {posPriceTestVariant !== 'variant-b' && (
                        <div ref={scrollRef} className="flex flex-col items-center justify-center space-y-1.5">
                            <h2 className="font-bold text-2xl text-center text-dark1">
                                {t('survey:program.variant-a.plan.title')}
                            </h2>
                            <span className="text-base text-dark3 text-center">
                                {t('survey:program.variant-a.plan.subtitle', { timeToLoseIt: timeToLoseWeight })}
                            </span>
                        </div>
                    )}

                    <PriceBreakdown
                        recommendedPlan={paymentProduct}
                        isPromoCodeEnabled={isPromoCodeEnabled}
                        weightToLoose={weightToLoose}
                        selectedPlan={selectedPlan}
                        onChangeSelectedPlan={onChangeSelectedPlan}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />

                    <CheckoutForm
                        product={selectedPaymentProduct}
                        onSuccess={onCheckoutCompleted}
                        subscriptionWithoutTrial={subscriptionWithoutTrial}
                    />

                    <div className="flex flex-col items-center justify-center mb-10">
                        <Moneyback testVariant={posTestVariant} />
                        <Reviews testVariant={posTestVariant} />

                        <Button
                            primary
                            label={t('common:controls.start_my_plan')}
                            className="w-full mb-12"
                            onClick={onStartPlanClick}
                        />

                        <span className="text-xs text-center text-dark2">
                            {t('survey:program.variant-a.support')}{' '}
                            <a
                                href="mailto:support@fastic.com"
                                className="text-xs font-bold text-boost2 cursor-pointer"
                            >
                                {t('survey:program.variant-a.here')}.
                            </a>
                        </span>
                    </div>

                    {showAreYouReadyModal && (
                        <Modal>
                            <Title>{t('survey:program.variant-a.trial_modal.title')}</Title>
                            <div className="mt-3 mb-6 text-center text-gray-600">
                                <p className="mb-3">{t('survey:program.variant-a.trial_modal.paragraph_1')}</p>
                                <p>{t('survey:program.variant-a.trial_modal.paragraph_2')}</p>
                            </div>
                            <Button
                                primary
                                label={t('common:controls.im_in')}
                                onClick={onReadyClick}
                                className="w-full"
                            />
                        </Modal>
                    )}
                </div>
            </PayPalScriptProvider>
        </div>
    )
}
