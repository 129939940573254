import React from 'react'

const SecurePaymentNote: React.FC = () => {
    return (
        <div className="mt-4 md:mt-6 flex justify-center">
            <img className="w-28 md:w-40 object-contain" src="/secure.jpg" />
        </div>
    )
}

export default SecurePaymentNote
