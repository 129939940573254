import { User } from '../contexts/auth'
import { Answers, Question, SurveyConfig } from '../contexts/survey'
import { BodyTypeStep } from './BodyTypeStep'
import { DevelopedByStep } from './DevelopedByStep'
import { DietVsFasticStep } from './DietVsFasticStep'
import { DownloadStep } from './DownloadStep'
import { EmailStep } from './EmailStep'
import { GenderStep } from './GenderStep'
import { GoalStep } from './GoalStep'
import { HealthNoticeStep } from './HealthNoticeStep'
import { HealthyHabitsStepTitle } from './HealthyHabitsStepTitle'
import PlanReadyStep from './PlanReadyStep/PlanReadyStep'
import { PreparePlanStep } from './PreparePlanStep'
import { ProcessingStep } from './ProcessingStep'
import { RegistrationStep } from './RegistrationStep'
import { StatsStep } from './StatsStep'
import { StickingToPlanStep } from './StickingToPlanStep'
import { StorytellingStep } from './StorytellingStep'
import TrialBreakdownStep from './TrialBreakdownStep'
import { TrialPriceStep } from './TrialPriceStep'
import { TrustStep } from './TrustStep'
import { TypicalLunchStepTitle } from './TypicalLunchStepTitle'
import { validateEmail, validateHeight, validateTargetWeight, validateWeight } from './validation'
import { WaterStep } from './WaterStep'
import { YourCurrentStateStep } from './YourCurrentStateStep'
import YourPlanStep from './YourPlan'
import { YourTargetStateStep } from './YourTargetStateStep'

const isNumeric = (val: string): boolean => {
    return !isNaN(Number(val))
}

const regProgressLabels = ['registration.create_password', 'trial_breakdown.about_trial', 'download.download_app_step']

const config: SurveyConfig = {
    milestones: [1, 5, 9, 18],
    questions: [
        {
            name: 'goal',
            type: 'custom',
            path: 'your_goal',
            isRequired: true,
            component: <GoalStep />,
            hideStepper: true,
            hideBackHeader: true,
            buttonNextTitle: 'common:controls.continue',
            validate: (question: Question, answers: Answers): boolean => {
                return (answers['goal'] as string[])?.length > 0 && true
            },
        },
        {
            name: 'meals',
            type: 'select',
            path: 'number_of_meals',
            isRequired: true,
            hideBackHeader: true,
            choices: ['1', '2', '3', '4+'],
        },
        {
            name: 'home_cooked_meals',
            type: 'select',
            path: 'how_much_home_cooked_meals',
            isRequired: true,
            choices: ['1-2', '3-4', '5-6', '6+', 'not_at_all'],
        },
        {
            name: 'diet',
            type: 'select',
            path: 'any_specific_diet',
            isRequired: true,
            multiple: true,
            choices: ['no_diets', 'keto', 'vegan', 'vegetarian', 'pescatarian', 'other'],
            choiceGroups: [['no_diets'], ['keto', 'vegan', 'vegetarian', 'pescatarian', 'other']],
        },
        {
            name: 'about_diet',
            type: 'custom',
            path: 'regular_diet_vs_fastic',
            buttonNextTitle: 'common:controls.got_it',
            component: <DietVsFasticStep />,
        },
        {
            name: 'sleep',
            type: 'select',
            path: 'how_do_you_sleep',
            showNextHeader: true,
            isRequired: true,
            multiple: true,
            choices: ['long_time', 'after_11pm', '7_hours', '10_hours', 'restless_sleep', '8_hours'],
            choiceGroups: [
                ['long_time', 'after_11pm', 'restless_sleep', '7_hours'],
                ['long_time', 'after_11pm', 'restless_sleep', '10_hours'],
                ['long_time', 'after_11pm', 'restless_sleep', '8_hours'],
            ],
        },
        {
            name: 'water',
            type: 'custom',
            path: 'how_much_water_you_drink',
            isRequired: true,
            component: <WaterStep />,
            validate: (question: Question, answers: Answers) =>
                isNumeric(answers[question.name] as string) && answers[question.name] > 0,
        },
        {
            name: 'work',
            type: 'select',
            path: 'whats_your_job_like',
            isRequired: true,
            choices: ['not_active', 'mid_active', 'very_active'],
        },
        {
            name: 'gender',
            type: 'custom',
            path: 'gender',
            isRequired: true,
            showNextHeader: true,
            component: <GenderStep />,
        },
        {
            name: 'trust_review',
            type: 'custom',
            path: 'sticking_to_plan',
            hideButtonBar: true,
            hideBackHeader: true,
            component: <StickingToPlanStep />,
        },
        {
            name: 'signup',
            type: 'custom',
            path: 'your_email',
            isRequired: true,
            hideStepper: true,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <EmailStep />,
            validate: (question: Question, answers: Answers, user: User): boolean => {
                return user.email && validateEmail(user.email) === null
            },
        },
        {
            name: 'age',
            type: 'select',
            path: 'age',
            isRequired: true,
            choices: ['18-29', '30-39', '40-49', '50-59', '60-69', '70+'],
        },
        {
            name: 'current_state',
            type: 'custom',
            path: 'your_current_state',
            isRequired: true,
            component: <YourCurrentStateStep />,
            validate: (question: Question, answers: Answers): boolean => {
                return (
                    answers['height'] &&
                    !validateHeight(answers['height'] as number) &&
                    answers['weight'] &&
                    !validateWeight(answers['weight'] as number)
                )
            },
        },
        {
            name: 'target_state',
            type: 'custom',
            path: 'your_target_state',
            isRequired: true,
            component: <YourTargetStateStep />,
            validate: (question: Question, answers: Answers): boolean => {
                return (
                    answers['target_weight'] &&
                    !validateTargetWeight(answers['target_weight'] as number, answers['weight'] as number)
                )
            },
        },
        {
            name: 'healthy_habits',
            type: 'select',
            path: 'additional-question-1',
            isRequired: true,
            hideButtonBar: true,
            subtitle: <HealthyHabitsStepTitle />,
            labelClassName: 'w-full font-normal text-center',
            completeOnSelect: true,
            choices: ['need_tuning', 'healthy_habits', 'stay_active'],
        },
        {
            name: 'typical_lunch',
            type: 'select',
            path: 'additional-question-2',
            isRequired: true,
            hideButtonBar: true,
            subtitle: <TypicalLunchStepTitle />,
            labelClassName: 'w-full font-normal text-center',
            completeOnSelect: true,
            choices: ['processed', 'sandwiches_wraps', 'soups_salads', 'home_cooked', 'no_lunch', 'other'],
        },
        {
            name: 'meal_frequency',
            type: 'select',
            path: 'additional-question-3',
            isRequired: true,
            hideButtonBar: true,
            labelClassName: 'w-full font-normal text-center',
            completeOnSelect: true,
            choices: ['structured', 'grab', 'snacker', 'variety', 'other'],
        },
        {
            name: 'body_type',
            type: 'custom',
            path: 'your_body_type',
            isRequired: true,
            component: <BodyTypeStep />,
            validate: (question: Question, answers: Answers): boolean => {
                return (answers['body_type'] as string[])?.length > 0 && true
            },
        },
        {
            name: 'processing',
            type: 'custom',
            path: 'processing',
            hideButtonBar: true,
            hideBackHeader: true,
            hideStepper: true,
            component: <ProcessingStep />,
        },
        {
            name: 'diseases',
            type: 'select',
            path: 'any_medical_conditions',
            isRequired: true,
            hideStepper: true,
            multiple: true,
            showNextHeader: true,
            hideBackHeader: true,
            choices: [
                'no',
                'anorexia',
                'bulimia',
                'diabetes',
                'high_blood_pressure',
                'high_cholesterol',
                'stress_anxiety_depression',
                'other',
            ],
            choiceGroups: [
                ['no'],
                [
                    'anorexia',
                    'bulimia',
                    'diabetes',
                    'high_blood_pressure',
                    'high_cholesterol',
                    'stress_anxiety_depression',
                    'other',
                ],
            ],
        },
        {
            name: 'drugs',
            type: 'select',
            path: 'do_you_take_any_medication',
            hideStepper: true,
            isRequired: true,
            buttonNextTitle: 'common:controls.done',
            multiple: true,
            showNextHeader: true,
            choices: [
                'not_taking_anything',
                'vitamins',
                'hormones',
                'antibiotics',
                'antidepressants',
                'painkillers',
                'other',
            ],
            choiceGroups: [
                ['not_taking_anything'],
                ['vitamins', 'hormones', 'antibiotics', 'antidepressants', 'painkillers', 'other'],
            ],
        },
        {
            name: 'storytelling',
            type: 'custom',
            path: 'storytelling',
            hideButtonBar: true,
            hideBackHeader: true,
            hideStepper: true,
            component: <StorytellingStep />,
        },
        {
            name: 'stats',
            type: 'custom',
            path: 'stats',
            hideStepper: true,
            hideBackHeader: true,
            component: <StatsStep />,
        },
        {
            name: 'developed_by',
            type: 'custom',
            path: 'developed_by',
            isRequired: false,
            hideStepper: true,
            buttonNextTitle: 'common:controls.got_it',
            component: <DevelopedByStep />,
        },
        {
            name: 'trust',
            type: 'custom',
            path: 'trust',
            isRequired: false,
            hideStepper: true,
            showNextHeader: true,
            component: <TrustStep />,
        },
        {
            name: 'prepare_plan',
            type: 'custom',
            path: 'prepare_plan',
            isRequired: false,
            hideStepper: true,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <PreparePlanStep />,
        },
        {
            name: 'health_notice',
            type: 'custom',
            path: 'health_notice',
            isRequired: false,
            hideStepper: true,
            hideButtonBar: true,
            showNextHeader: true,
            hideBackHeader: true,
            component: <HealthNoticeStep />,
        },
        {
            name: 'your_plan',
            type: 'custom',
            path: 'your_plan',
            isRequired: false,
            hideStepper: true,
            showNextHeader: true,
            component: <YourPlanStep />,
        },
        {
            name: 'trial_price',
            type: 'custom',
            path: 'trial_price',
            isRequired: false,
            hideStepper: true,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <TrialPriceStep />,
            validate: (question: Question, answers: Answers, user: User): boolean => {
                return answers['trial_price'] && true
            },
        },
        {
            name: 'program',
            type: 'custom',
            path: 'your_program',
            isRequired: true,
            hideStepper: true,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <PlanReadyStep />,
            validate: (question: Question, answers: Answers, user: User): boolean => {
                // is user premium?
                return false
            },
        },
        {
            name: 'registration',
            type: 'custom',
            path: 'registration',
            isRequired: true,
            progressStepNumber: 1,
            progressTotalSteps: 3,
            stepProgress: 0,
            progressLabels: regProgressLabels,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <RegistrationStep />,
            validate: (question: Question, answers: Answers, user: User): boolean => {
                return (
                    user.email && validateEmail(user.email) === null && user.name && validateEmail(user.name) === null
                )
            },
        },
        {
            name: 'trial_breakdown',
            type: 'custom',
            path: 'trial_breakdown',
            isRequired: false,
            progressStepNumber: 2,
            progressTotalSteps: 3,
            stepProgress: 0,
            progressLabels: regProgressLabels,
            hideBackHeader: true,
            buttonNextTitle: 'common:controls.continue',
            component: <TrialBreakdownStep />,
        },
        {
            name: 'download',
            type: 'custom',
            path: 'download',
            isRequired: false,
            progressStepNumber: 3,
            progressTotalSteps: 3,
            stepProgress: 0,
            progressLabels: regProgressLabels,
            hideButtonBar: true,
            hideBackHeader: true,
            component: <DownloadStep />,
        },
    ],
}

export default config
