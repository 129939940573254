import React from 'react'
import { useTranslation } from 'i18n'
import { PaymentProduct } from '@services/payment_product'
import { format as formatDate } from 'date-fns'
import { enUS, de } from 'date-fns/locale'

const PaymentNote: React.FC<{
    product: PaymentProduct
}> = ({ product }) => {
    const { t, i18n } = useTranslation()

    const locales = { en: enUS, de }
    const trialEnd = new Date(new Date().getTime() + product.trialPeriodDays * 86400 * 1000)
    const currency = t(`common:currency.${product.currency}`)

    return (
        <div className="mt-6 text-gray-500 leading-tight text-xs md:text-base">
            <p
                dangerouslySetInnerHTML={{
                    __html: t('survey:program.control.form.trial', {
                        priceAfterTrial: product.productPrice.toFixed(2),
                        renewalPeriod: t(`common:period.${product.productIntervalCount}${product.productInterval}`),
                        trialPeriod: product.trialPeriodDays,
                        trialEnd: formatDate(trialEnd, 'Pp', { locale: locales[i18n.language] }),
                        currency,
                    }),
                }}
            ></p>
        </div>
    )
}

export default PaymentNote
