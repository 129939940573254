import React, { useEffect, useState } from 'react'
import { RadioTabs } from '../components/RadioTabs'
import { Step } from '../components/Step'
import { TextInput } from '../components/TextInput'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import { convertKgToLbs, convertLbsToKg, formatNumber } from '../utils/convert'
import { validateTargetWeight } from './validation'

export const YourTargetStateStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()

    const [form, setForm] = useState<{
        measurementSystem: string
        kg?: number
        lbs?: number
        weightError?: string
    }>({
        measurementSystem: (answers['measurement_system'] as string) ?? 'metric',
        kg: answers['target_weight'] as number,
        lbs: answers['target_weight'] && convertKgToLbs(answers['target_weight'] as number),
        weightError:
            answers['weight'] && validateTargetWeight(answers['target_weight'] as number, answers['weight'] as number),
    })

    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'measurement_system', value: form.measurementSystem })
    }, [form.measurementSystem])
    useEffect(() => {
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: 'target_weight', value: form.kg })
    }, [form.kg])

    const onWeightKgChange = (value: string) => {
        const kg = formatNumber(value)
        setForm({
            ...form,
            weightError: null,
            kg,
            lbs: convertKgToLbs(kg),
        })
    }

    const onWeightLbsChange = (value: string) => {
        const lbs = formatNumber(value)
        setForm({
            ...form,
            weightError: null,
            lbs: lbs,
            kg: convertLbsToKg(lbs),
        })
    }

    const subtitle = `survey:target_state.subtitle.${answers['goal'] ?? 'boost_health'}`

    return (
        <Step
            key={question.path}
            name={question.name}
            subtitle={
                <span className="text-center text-xs xs:text-sm md:text-lg xs:leading-tight text-dark3 mt-2 px-2 xs:px-4 flex justify-center">
                    {t(subtitle)}
                </span>
            }
        >
            <RadioTabs
                name="type"
                selected={form.measurementSystem}
                options={[
                    { value: 'imperial', label: t('common:lbs') },
                    { value: 'metric', label: t('common:kg') },
                ]}
                onChange={value => setForm({ ...form, measurementSystem: value })}
            />
            <TextInput
                type="tel"
                label={t('survey:target_state.input_label')}
                note={form.measurementSystem === 'metric' ? t('common:kg') : t('common:lbs')}
                value={
                    form.measurementSystem === 'metric'
                        ? form.kg && form.kg.toString()
                        : form.lbs && form.lbs.toString()
                }
                error={form.weightError && t(form.weightError)}
                onBlur={() =>
                    setForm({ ...form, weightError: validateTargetWeight(form.kg, answers['weight'] as number) })
                }
                onChange={form.measurementSystem === 'metric' ? onWeightKgChange : onWeightLbsChange}
            />
        </Step>
    )
}
