import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { CheckIcon } from '../components/CheckIcon'
import { Step } from '../components/Step'
import { useQuestion } from '../contexts/survey'
import { useTranslation } from '../i18n'
import assets from './assets'

type BodyType = {
    value: string
    imageUrl: string
}

const bodyTypes: { female: BodyType[]; male: BodyType[] } = {
    female: [
        {
            value: 'rounded',
            imageUrl: assets.body_woman_rounded,
        },
        {
            value: 'triangle',
            imageUrl: assets.body_woman_triangle,
        },
        {
            value: 'rectangle',
            imageUrl: assets.body_woman_rectangle,
        },
        {
            value: 'inverted_triangle',
            imageUrl: assets.body_woman_inverted_triangle,
        },
        {
            value: 'hourglass',
            imageUrl: assets.body_woman_hourglass,
        },
    ],
    male: [
        {
            value: 'rounded',
            imageUrl: assets.body_man_rounded,
        },
        {
            value: 'triangle',
            imageUrl: assets.body_man_triangle,
        },
        {
            value: 'rectangle',
            imageUrl: assets.body_man_rectangle,
        },
        {
            value: 'inverted_triangle',
            imageUrl: assets.body_man_inverted_triangle,
        },
        {
            value: 'hourglass',
            imageUrl: assets.body_man_hourglass,
        },
    ],
}

export const BodyTypeStep: React.FC = () => {
    const [question, answers, dispatch] = useQuestion()
    const { t } = useTranslation()

    const selected = (answers[question.name] as string[]) ?? []
    const handleOptionChange = (value: string) => {
        const index = selected.indexOf(value)
        let next: string[]
        if (index >= 0) {
            next = selected.filter(v => v !== value)
        } else {
            next = [value]
        }
        dispatch({ type: 'QUESTION_ANSWER_CHANGED', name: question.name, value: next })
    }

    return (
        <Step key={question.path} name={question.name}>
            <div className="grid grid-cols-2 xs:grid-cols-3 grid-flow-row gap-2 mt-4">
                {(bodyTypes[answers['gender'][0] ?? 'female'] as BodyType[]).map((option, i) => (
                    <BodyTypeOption
                        key={option.value}
                        label={t(`survey:body_type.options.${option.value}`)}
                        imageUrl={option.imageUrl}
                        checked={selected.includes(option.value)}
                        onClick={() => handleOptionChange(option.value)}
                        className="animate-fade-in"
                        style={{ animationDelay: `${i * 75}ms` }}
                    />
                ))}
            </div>
        </Step>
    )
}

interface BodyTypeOptionProps {
    checked?: boolean
    label: string
    imageUrl: string
    className?: string
    style?: CSSProperties
    onClick?: () => void
}

const BodyTypeOption: React.FC<BodyTypeOptionProps> = ({ checked = false, label, imageUrl, className, ...props }) => {
    const styles = classNames(
        'md:w-38 md:h-64 flex flex-col justify-between text-center py-2 md:py-4 px-1 md:px-4 rounded-xl cursor-pointer transition-colors duration-300 ease-in-out border-2 shadow-standard',
        checked && 'bg-boost6 border-boost2',
        !checked && 'bg-white hover:bg-light4 ',
        className,
    )

    const checkClassName = classNames(
        'rounded-full flex justify-center items-center font-bold text-xs mx-auto mt-auto p-0.5 text-white transition-colors duration-300 w-5 md:w-7 h-5 md:h-7',
        checked ? 'bg-boost2' : 'border-2 border-dark4',
    )

    return (
        <div className={styles} {...props}>
            <img className="w-full h-36" src={imageUrl} />
            <div className="flex flex-col">
                <div className="text-xs xs:text-sm md:text-base font-bold my-2 text-gray-900">{label}</div>
                <div className={checkClassName}>{checked && <CheckIcon className="w-5 md:w-7 h-5 md:h-7" />}</div>
            </div>
        </div>
    )
}
